import type { FC } from 'react'

import { useMemo } from 'react'
import { Timeline } from 'antd'
import AppButton from 'shared/components/common/AppButton'
import clsx from 'clsx'
import { MoreOutlined, PhoneFilled } from '@ant-design/icons'
import moment from 'moment'
import ClipIcon from 'shared/assets/icons/Clip'
import NotesIcon from 'shared/assets/icons/Notes'

import { IHistory, ITimelineProps } from '../@types/History'

import { Anchor, StyledTimeLine } from './styles/History.styled'

export const Filters: FC = () => {
  const filters = useMemo(
    () => [
      'Todas 1',
      'Notas 0',
      'Actividad 1',
      'Email 0',
      'Archivos 1',
      'Documentos 0',
      'Changelog',
    ],
    [],
  )

  return (
    <div className="flex gap-10 px-20 mb-10">
      {filters.map((filter, i) => (
        <button
          key={`filter-history-${filter}`}
          className={clsx(
            'font-semibold text-gray-500 font-xl mx-3 hover:text-blue-500 transition-colors duration-200',
            {
              'text-blue-500': !i,
            },
          )}
        >
          {filter}
        </button>
      ))}
    </div>
  )
}

export const TimelineItem: FC<ITimelineProps> = ({ data }) => {
  return (
    <Timeline.Item
      dot={
        <div className="w-12 h-12 rounded-full border bg-white flex items-center justify-center">
          {data.type === 'file' && <ClipIcon className="w-6 h-6 fill-gray-400" />}
          {data.type === 'meeting' && <PhoneFilled className="text-gray-400" />}
          {data.type === 'note' && <NotesIcon className="w-6 h-6 fill-gray-400" />}
        </div>
      }
    >
      <div
        className={clsx(
          'relative border rounded-tl-[2px] border-gray-200 rounded-md px-3 py-2 ml-8',
          {
            'bg-yellow-100': data.type === 'note',
            'bg-white': data.type !== 'note',
          },
        )}
      >
        <Anchor
          className={clsx({
            'bg-yellow-100': data.type === 'note',
            'bg-white': data.type !== 'note',
          })}
        />
        <div className="flex justify-between items-center">
          <div className="flex gap-6 items-center ml-6 text-md">
            <p className="font-semibold">{data.data?.title ?? data.data?.text}</p>
            <p className="capitalize ml-6">
              {moment(data.data.created_at).add(1, 'months').format('MMMM MM [de] YYYY')}
            </p>
            {!!data.data?.by_user && <p className="capitalize ml-6">-</p>}
            <p className="capitalize ml-6">{data.data?.by_user}</p>
            {!!data.data?.size && <p className="capitalize ml-6">-</p>}
            <p className="capitalize ml-6">{data.data?.size}</p>
          </div>
          <div>
            <AppButton icon={<MoreOutlined />} type="text" />
          </div>
        </div>
      </div>
      {data.type === 'note' && (
        <div className="mt-3 ml-3">
          <AppButton className="font-semibold text-sm" type="text">
            + Añade un comentario
          </AppButton>
        </div>
      )}
    </Timeline.Item>
  )
}

export const History: FC = () => {
  const histories = useMemo<IHistory[]>(
    () => [
      {
        type: 'file',
        data: {
          file_path: 'path-to-file.png',
          title: 'Imagen',
          created_at: new Date().toISOString(),
          by_user: 'Kimberly Sandoval',
          size: '445 KB',
        },
      },
      {
        type: 'meeting',
        data: {
          icon: 'phone',
          title: 'Reunión virtual con cliente',
          created_at: new Date().toISOString(),
        },
      },
      {
        type: 'note',
        data: {
          text: 'Lorem ipsum dolor is amet',
          created_at: new Date().toISOString(),
          by_user: 'Kimberly Sandoval',
        },
      },
      {
        type: 'project_created',
        data: {
          text: 'Proyecto creado',
          by_user: 'Kimberly Sandoval',
          created_at: new Date().toISOString(),
        },
      },
    ],
    [],
  )

  return (
    <div>
      <Filters />
      <div className="px-6">
        <StyledTimeLine>
          {histories.map((history, i) => (
            <TimelineItem key={`timeline-item-${i}`} data={history} />
          ))}
        </StyledTimeLine>
      </div>
    </div>
  )
}
