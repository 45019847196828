import type { ChangeEventHandler } from 'react'

import { useState } from 'react'

export const useSearchInput = () => {
  const [value, setValue] = useState<string>('')

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value)
  }

  return {
    value,
    onChange,
  }
}
