import type {DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier} from "@dnd-kit/core";

import {arrayMove} from "@dnd-kit/sortable";
import {useState} from "react";

import {useKanbanContext} from "./context/Kanban.context";
import {findContainer} from "./utils";

const useColumnsDirective = (containerKeys: string[]) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [clonedItems, setClonedItems] = useState<Record<UniqueIdentifier, any[]> | null>(null);

  const {
    dataSource,
    idField = "id",
    cardSettings: {render},
    setDataSource,
    onDrop,
  } = useKanbanContext();

  const onDragStart = ({active}: DragStartEvent) => {
    setActiveId(active.id);
    setClonedItems(dataSource);
  };

  const onDragCancel = () => {
    if (clonedItems) setDataSource(clonedItems);

    setActiveId(null);
    setClonedItems(null);
  };

  const onDragOver = ({active, over}: DragOverEvent) => {
    const overId = over?.id;

    if (!overId) return;

    const overContainer = findContainer(overId, idField, dataSource, containerKeys);
    const activeContainer = findContainer(active.id, idField, dataSource, containerKeys);

    if (!overContainer || !activeContainer) return;

    if (activeContainer !== overContainer) {
      setDataSource((items: Record<UniqueIdentifier, any[]>) => {
        const activeItems = items[activeContainer] ?? [];
        const overItems = items[overContainer] ?? [];
        const overIndex = overItems.findIndex((item) => item[idField] === overId);
        const activeIndex = activeItems.findIndex((item) => item[idField] === active.id);

        if (activeIndex < 0) return items;

        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter(
            (item: Record<string, any>) => item[idField] !== active.id,
          ),
          [overContainer]: [
            ...(items[overContainer] ?? []).slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...(items[overContainer] ?? []).slice(newIndex, (items[overContainer] ?? []).length),
          ],
        };
      });
    }
  };

  const onDragEnd = ({active, over}: DragEndEvent) => {
    const overId = over?.id;

    if (!overId) {
      setActiveId(null);

      return;
    }
    const overContainer = findContainer(overId, idField, dataSource, containerKeys);
    const activeContainer = findContainer(active.id, idField, dataSource, containerKeys);

    if (!overContainer || !activeContainer) {
      setActiveId(null);

      return;
    }

    const activeIndex = dataSource[activeContainer].findIndex(
      (item) => item[idField] === active.id,
    );
    const overIndex = dataSource[overContainer].findIndex((item) => item[idField] === overId);

    if (onDrop) {
      onDrop(dataSource[activeContainer][activeIndex], overContainer);
    }

    if (activeIndex !== overIndex) {
      setDataSource((items: Record<UniqueIdentifier, any[]>) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      }));
    }

    setActiveId(null);
  };

  return {
    activeId,
    dataSource,
    idField,
    renderItem: render,
    onDragStart,
    onDragCancel,
    onDragOver,
    onDragEnd,
  };
};

export default useColumnsDirective;
