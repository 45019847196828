import { Typography } from 'antd'
import { UserOutlined as UserIcon } from '@ant-design/icons'
import styled from 'styled-components'
import CustomerIcon from 'shared/assets/icons/customer'
import FlagIcon from 'shared/assets/icons/flag'
import { PersonNameBadgeGrouped } from 'shared/components/common/PersonNameBadge'
import { ProjectStatusBar } from 'shared/components/common/ProjectStatusBar'
import { Link } from 'react-router-dom'

const CardContent = styled.div`
  padding: 12px;
  /* z-index: 2; */
`

const CardText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`

function Card({ data }: { data: any }): JSX.Element {
  return (
    <CardContent className="shadow-md border rounded-md bg-white">
      <Link to="/projects/nueva-scp">
        <Typography.Title level={5}>{data.Title}</Typography.Title>
      </Link>
      <div>
        <CardText>
          <CustomerIcon color="#00000073" size={15} />
          <Typography.Text>SCP</Typography.Text>
        </CardText>
      </div>
      <div>
        <CardText>
          <FlagIcon color="#00000073" size={15} />
          <Typography.Text>15 de Enero</Typography.Text>
        </CardText>
      </div>
      <div>
        <CardText>
          <UserIcon />
          <Typography.Text>{data.Assignee}</Typography.Text>
        </CardText>
      </div>
      <div style={{ marginTop: 10 }}>
        <CardText>
          <Typography.Text type="secondary">En board:</Typography.Text>
          <Typography.Text>4 días</Typography.Text>
        </CardText>
      </div>
      <div>
        <CardText>
          <Typography.Text type="secondary">En columna:</Typography.Text>
          <Typography.Text>4 días</Typography.Text>
        </CardText>
      </div>
      <div>
        <CardText>
          <Typography.Text type="secondary">Encargados:</Typography.Text>
          <PersonNameBadgeGrouped
            persons={[
              { name: 'Kin Sandoval', color: '#B142EB' },
              { name: 'Hugo Gutierrez', color: '#07C300' },
              { name: 'John Doe' },
              { name: 'Albert Einstein' },
            ]}
          />
        </CardText>
      </div>
      <div style={{ marginTop: 10 }}>
        <ProjectStatusBar />
      </div>
    </CardContent>
  )
}

export default Card
