import {useController, useFieldArray} from "react-hook-form";

import {IUseExtraInputsProps} from "../@types/useExtraInputs";

const useExtraInputs = ({control, name, extraArrayFieldName}: IUseExtraInputsProps) => {
  const {
    formState: {errors},
  } = useController({
    control,
    name,
    shouldUnregister: true,
  });

  const {
    fields: extraFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: extraArrayFieldName,
    keyName: "fieldId",
    shouldUnregister: true,
  });

  const handleAddNewExtra = () => {
    append({
      type: "work",
    });
  };

  const handleRemoveExtra = (index: number) => {
    remove(index);
  };

  return {
    error: errors[name],
    errors: errors[extraArrayFieldName] as unknown as any[],
    extraFields,
    handleAddNewExtra,
    handleRemoveExtra,
  };
};

export default useExtraInputs;
