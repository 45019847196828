export enum ViewsKeys {
  DATE = 'date',
  KANBAN = 'kanban',
  DIAG_GANT = 'diag-gant',
}

export enum TaskInterfaceKeys {
  ID = 'id',
  TITLE = 'title',
  PROJECT_NAME = 'projectName',
  DATE_END = 'dateEnd',
  TIME_TRACKED = 'timeTracked',
  COMPLETED_SUBTASKS = 'completedSubtasks',
  TOTAL_SUBTASKS = 'totalSubtasks',
  STATUS = 'status',
  ASSIGNED = 'assigned',
  PATH_COVER = 'pathCover',
  PARENT_ID = 'parentId',
  PARENT = 'parent',
}
