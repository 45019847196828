import type { EntityStatus, IBase } from './IBase'
import type { ILabelRelation } from './ILabelRelation'

import { IOrganizationPerson } from './IOrganizationPerson'

export enum PersonVisibilityTypes {
  OWNER = 'owner',
  OWNER_AND_FOLLOWERS = 'owner_and_followers',
  ALL = 'all',
}

export const PersonVisibilities = {
  [PersonVisibilityTypes.OWNER]: 'Solo yo',
  [PersonVisibilityTypes.OWNER_AND_FOLLOWERS]: 'Integrantes',
  [PersonVisibilityTypes.ALL]: 'Todos',
}

export interface IPerson extends IBase {
  company_id: number
  first_name: string
  last_name: string
  owner_user_id: number
  meta_owner_user_first_name: string
  meta_owner_user_last_name: string
  meta_owner_user_avatar_path: string
  visible_to: PersonVisibilityTypes
  status: EntityStatus
  primary_email: string
  primary_phone_country_code: string
  primary_phone: string
}
export interface IPersonExtended extends IPerson {
  labels: ILabelRelation[]
  organizations: IOrganizationPerson[]
}
