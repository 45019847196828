import type {ITaskList} from "types/dto/ITask";

import {Typography} from "antd";
import styled from "styled-components";
import FlagIcon from "shared/assets/icons/flag";
import {PersonNameBadgeGrouped} from "shared/components/common/PersonNameBadge";
import moment from "moment";
import AlarmClock from "shared/assets/icons/alarmClock";
import AppCheckBox from "shared/components/common/AppCheckbox";
import {useLocation, useNavigate} from "react-router-dom";

const CardContent = styled.div`
  background: white;
  z-index: 0;
  border-radius: 8px;
`;

const CardText = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.grey};
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

const getTrackedTime = (ms: number) => {
  return moment.utc(ms).format("HH[h] mm[m] ss[s]");
};

export function TaskCard({data: task}: {data: ITaskList}): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <CardContent
      className="shadow-md overflow-hidden group"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`task/${task.id}${location.search}`);
      }}
    >
      {!!task.featured_image_path && (
        <div>
          <img alt="" className="w-full h-[140px] object-cover" src={task.featured_image_path} />
        </div>
      )}
      <div className="py-3 px-4">
        <Typography.Title
          className="mb-2 max-h-[75px] overflow-hidden text-ellipsis group-hover:underline"
          level={5}
        >
          {task.name}
        </Typography.Title>
        <Typography.Text>{task?.project?.name}</Typography.Text>
        <div>
          {task?.due_date && (
            <CardText>
              <FlagIcon color="#00000073" size={15} />
              <Typography.Text>{moment(task.due_date).format("D [de] MMM")}</Typography.Text>
            </CardText>
          )}
          {task?.time_estimated_seconds && (
            <CardText>
              <AlarmClock color="#00000073" size={15} />
              <Typography.Text>{getTrackedTime(task.time_estimated_seconds)}</Typography.Text>
            </CardText>
          )}
        </div>

        <div className="flex justify-between items-center">
          {!!task?.total_subtasks && (
            <CardText>
              <AppCheckBox
                disabled
                checked={task.completed_subtasks === task.total_subtasks}
                type="success"
              />

              {task.completed_subtasks}
              {"/"}
              {task.total_subtasks}
            </CardText>
          )}
          {!!task?.owner_user_id && (
            <PersonNameBadgeGrouped
              persons={[
                {
                  name: `${task.meta_owner_user_first_name} ${task.meta_owner_user_last_name}`,
                  color: "#B142EB",
                },
              ]}
            />
          )}
        </div>
      </div>
    </CardContent>
  );
}
