import {createGlobalStyle} from "styled-components";

export const StyledGlobal = createGlobalStyle`
  /* 
    Font styles
  */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
  
  html, body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px !important;
  }

  /* 
   * App Custom Styles
  */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Position */

  /* Backgrounds */
  
  .bg-white {
    background: white;
  }

  .beautifull-scrollbar {

    ::-webkit-scrollbar {
      width: 16px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 16px;
      border: 5px solid #fff;
    }
    :hover{
      ::-webkit-scrollbar-thumb {
        background-color: #D7D7D7;
      }
    }
  }
`;
