import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const AddTemplateIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 131.547 172.398"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 48" transform="translate(-1072 -354.102)">
        <g transform="translate(1047.953 354.102)">
          <path
            d="M127.031,80h-77.9A5.134,5.134,0,0,0,44,85.128V188.654a5.134,5.134,0,0,0,5.128,5.128h77.9a5.133,5.133,0,0,0,5.126-5.128V85.128A5.133,5.133,0,0,0,127.031,80Zm-17.387,72.208H68.069a4.376,4.376,0,0,1,0-8.753h41.574a4.376,4.376,0,1,1,0,8.753Zm0-17.5H68.069a4.376,4.376,0,1,1,0-8.752h41.574a4.376,4.376,0,1,1,0,8.752Zm0-17.5H68.069a4.376,4.376,0,1,1,0-8.752h41.574a4.376,4.376,0,1,1,0,8.752Z"
            data-name="Path 14"
            fill={color}
            transform="translate(0 -58.119)"
          />
          <path
            d="M194.6,13.129v96.277c0,6.608-5.317,13.129-14.685,13.129V27.01a13.894,13.894,0,0,0-13.878-13.881H97.69C97.69,5.889,103.929,0,111.6,0h69.1C188.36,0,194.6,5.889,194.6,13.129Z"
            data-name="Path 15"
            fill={color}
            transform="translate(-39.005)"
          />
        </g>
        <rect
          data-name="Rectangle 127"
          fill="#fff"
          height="42"
          transform="translate(1081 466)"
          width="21"
        />
        <rect
          data-name="Rectangle 129"
          fill="#fff"
          height="34.75"
          transform="translate(1113.25 479.265) rotate(90)"
          width="21"
        />
        <text
          data-name="+"
          fill={color}
          fontFamily="Montserrat-Bold, Montserrat"
          fontSize="65"
          fontWeight="700"
          transform="translate(1072 510.5)"
        >
          <tspan x="0" y="0">
            +
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default AddTemplateIcon
