import type { FC, ReactNode } from 'react'

import styled from 'styled-components'
import { Typography } from 'antd'

interface ITextIconWrapper {
  align: 'start' | 'center' | 'end'
}

interface ITextWithIcon {
  icon: ReactNode
  text: string
  align?: ITextIconWrapper['align']
}

const TextIconWrapper = styled.div<ITextIconWrapper>`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 5px 0;
  justify-content: ${({ align }) => `${align}`};
`

const TextWithIcon: FC<ITextWithIcon> = ({ icon, text, align }) => {
  return (
    <TextIconWrapper align={align || 'start'}>
      {icon}
      <Typography.Text>{text}</Typography.Text>
    </TextIconWrapper>
  )
}

export default TextWithIcon
