import type {FC} from "react";

import {useTableGroupContext} from "../lib/context/TableGroup.context";

import TableRow from "./TableRow";

const TableBody: FC = () => {
  const {projects, type} = useTableGroupContext();

  return (
    <>
      {projects.map((project) => (
        <TableRow key={`project-${type}-${project.id}`} data={project} />
      ))}
    </>
  );
};

export default TableBody;
