import type { FC } from 'react'

import { MoreOutlined, PhoneFilled } from '@ant-design/icons'
import AppButton from 'shared/components/common/AppButton'
import moment from 'moment'

export const ScheduleItem: FC = () => {
  return (
    <div className="border border-gray-200 rounded-md px-3 py-2 bg-white mb-10">
      <div className="flex justify-between items-center">
        <div className="flex gap-6 items-center text-md">
          <PhoneFilled />
          <p className="font-semibold">Reunión Virtual con el cliente</p>
          <p className="capitalize ml-6">{moment().add(1, 'months').format('MMMM MM [de] YYYY')}</p>
        </div>
        <div>
          <AppButton icon={<MoreOutlined />} type="text" />
        </div>
      </div>
    </div>
  )
}

export const Schedule: FC = () => {
  return (
    <div>
      <ScheduleItem />
      <ScheduleItem />
    </div>
  )
}
