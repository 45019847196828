import type {FC} from "react";

import {
  VideoCameraOutlined,
  ClockCircleOutlined,
  PhoneFilled,
  UsergroupAddOutlined,
  UserOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import AppSelect, {AppSelectC} from "shared/components/common/AppSelect";
import {AppInput} from "shared/components/common/Input/ui/Input";
import {Badge, Select, Tag} from "antd";
import {AppTextArea} from "shared/components/common/Input";
import ListIcon from "shared/assets/icons/list";
import CustomerIcon from "shared/assets/icons/customer";
import KanbanIcon from "shared/assets/icons/kanban";
import UserGroupIcon from "shared/assets/icons/userGroup";
import EmailIcon from "shared/assets/icons/Email";
import {FlagEmpty} from "shared/assets/icons/flag";
import AppButton from "shared/components/common/AppButton";
import DatePicker from "shared/components/common/DatePicker";
import clsx from "clsx";
import AppCheckBox from "shared/components/common/AppCheckbox";
import {useTheme} from "styled-components";

import useForm from "../lib/useForm";

import {Collapse} from "./styles/styled";

const activityTypOptions = {
  phone: {
    label: "Phone",
    icon: <PhoneFilled />,
    value: "phone",
  },
  meeting: {
    label: "Reunión",
    icon: <UserGroupIcon className="stroke-black" size={16} />,
    value: "meeting",
  },
  email: {
    label: "Email",
    icon: <EmailIcon className="stroke-black" size={16} />,
    value: "email",
  },
  deadline: {
    label: "Deadline",
    icon: <FlagEmpty className="stroke-black" size={16} />,
    value: "deadline",
  },
};

export const Form: FC<{onCancel: () => void}> = ({onCancel}) => {
  const {icon, control, extraCollapsed, handleSubmit, toggleCollapsed} = useForm();
  const theme = useTheme();

  return (
    <form
      className="flex-1 h-full flex flex-col overflow-auto beautifull-scrollbar"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col flex-1">
        <div className="py-4 px-6 flex flex-col gap-2 w-full flex-1">
          <div className="flex gap-1 w-full">
            <span className="inline-block w-6" />
            <div className="flex gap-3 w-full">
              <AppSelectC
                control={control}
                name="icon"
                options={Object.values(activityTypOptions)}
                separator={false}
                width={140}
              />
              <AppInput
                control={control}
                name="title"
                placeholder={activityTypOptions[icon as keyof typeof activityTypOptions]?.label}
              />
            </div>
          </div>

          <div className="flex gap-3 w-full">
            <ClockCircleOutlined className="text-gray-400" />
            <div className="flex gap-3 flex-1 items-center">
              <DatePicker
                showTime
                use12Hours
                control={control}
                format={(date) => date.format("YYYY-MM-DD hh:mm A")}
                minuteStep={15}
                name="start_at"
                showSecond={false}
              />

              <span className="inline-block w-6 h-1 bg-gray-400 rounded" />
              <DatePicker
                showTime
                use12Hours
                control={control}
                format={(date) => date.format("YYYY-MM-DD hh:mm A")}
                minuteStep={15}
                name="end_at"
                showSecond={false}
              />
            </div>
          </div>

          <div>
            <Collapse
              ghost
              bordered={false}
              expandIcon={() => ""}
              onChange={(activeKeys) => {
                if ((Array.isArray(activeKeys) ? activeKeys : [activeKeys]).includes("1")) {
                  toggleCollapsed(false);
                } else toggleCollapsed(true);
              }}
            >
              <Collapse.Panel
                key="1"
                header={
                  <div
                    className={clsx("flex gap-1 w-full", {
                      hidden: !extraCollapsed,
                    })}
                  >
                    <span className="inline-block w-6" />
                    <p className="flex-1 font-bold text-blue-400 text-sm">
                      Añade Invitados, Ubicación, Video llamada, Descripción
                    </p>
                  </div>
                }
              >
                <div className="flex flex-col gap-2">
                  <div className="flex gap-3 w-full">
                    <UsergroupAddOutlined className="text-gray-400" />
                    <div className="flex-1">
                      <Select className="w-full" placeholder="Invitados" />
                    </div>
                  </div>

                  <div className="flex gap-3 w-full items-center">
                    <i className="icon-location-pin text-gray-400" />
                    <div className="flex-1">
                      <Select className="w-full" placeholder="Ubicación" />
                    </div>
                  </div>

                  <div className="flex gap-3 w-full items-center">
                    <VideoCameraOutlined className="text-gray-400" />
                    <div className="flex-1">
                      <Select className="w-full" placeholder="Integración de video llamada" />
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-3 w-full">
                      <ListIcon className="mt-10 fill-gray-400" size={14} />
                      <div className="flex-1">
                        <AppTextArea
                          control={control}
                          label="Descripción"
                          labelClassName="text-gray-400"
                          name="description"
                          rows={3}
                        />
                      </div>
                    </div>
                    <div className="flex gap-1 w-full mt-2">
                      <span className="inline-block w-6" />
                      <p className="flex-1 text-xs">
                        La descripción está sincronizada con su calendario y visible para todos los
                        invitados
                      </p>
                    </div>
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>

          <div>
            <div className="flex gap-3 w-full">
              <i className="icon-note text-gray-400" />
              <div className="flex-1">
                <AppTextArea
                  className="bg-yellow-100"
                  control={control}
                  labelClassName="text-gray-400"
                  name="notes"
                  placeholder="Nota:"
                  rows={3}
                />
              </div>
            </div>
            <div className="flex gap-1 w-full mt-2">
              <span className="inline-block w-6" />
              <p className="flex-1 text-xs">
                Las notas son visibles dentro de Experience System, pero no para os invitados al
                evento
              </p>
            </div>
          </div>

          <div>
            <div className="flex gap-3 w-full items-center">
              <UserOutlined className="text-gray-400" />
              <div className="flex-1">
                <Select className="w-full" placeholder="Responsable del proyecto" />
              </div>
            </div>

            <div className="flex gap-1 w-full mt-2">
              <span className="inline-block w-6" />
              <p className="flex-1 text-xs">
                Cambiar el propietario del proyecto más tarde no afectará al responsable del la
                actividad
              </p>
            </div>
          </div>

          <div className="flex gap-3 w-full items-center">
            <LinkOutlined className="text-gray-400" />
            <div className="flex-1">
              <AppSelect
                allowClear
                className="w-full"
                mode="tags"
                name="_per_rel_pro"
                options={[
                  {
                    label: "Hugo Gutierrez",
                    value: 1,
                    data: {
                      name: "Hugo Gutierrez",
                      email: "hugogutierrez@e-me.co",
                    },
                  },
                ]}
                placeholder="Persona relacionada al proyecto"
                renderOption={(option) => `${option.data.name} (${option.data.email})`}
                separator={false}
                tagRender={(props) => {
                  return (
                    <Tag closable onClose={props.onClose}>
                      {props.value !== props.label
                        ? props.label
                            ?.toString()
                            .substring(0, props.label?.toString()?.indexOf(" ("))
                        : props.label}
                      {props.value === props.label && (
                        <Badge
                          className="ml-2"
                          color={theme.colors.primary}
                          count="New"
                          size="small"
                        />
                      )}
                    </Tag>
                  );
                }}
              />
            </div>
          </div>
          <div className="flex gap-3 w-full items-center">
            <CustomerIcon className="stroke-gray-400 fill-transparent" size={15} />
            <div className="flex-1">
              <AppInput
                control={control}
                name="_org_rel_pro"
                placeholder="Organización relacionada al proyecto"
              />
            </div>
          </div>

          <div className="flex gap-3 w-full items-center">
            <KanbanIcon className="stroke-gray-400 fill-transparent" size={15} />
            <div className="flex-1">
              <Select className="w-full" placeholder="Fase asignada" />
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 py-4 px-8 flex justify-end bg-[#F4F7F9] items-center gap-3 border-t">
          <AppCheckBox>Mark as done</AppCheckBox>
          <AppButton $white type="primary" onClick={onCancel}>
            Cancelar
          </AppButton>
          <AppButton $success htmlType="submit" type="primary">
            Guardar
          </AppButton>
        </div>
      </div>
    </form>
  );
};
