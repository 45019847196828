import type { FC } from 'react'

import { Typography } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import AppButton from 'shared/components/common/AppButton'

export const FinanceMeetingItem: FC = () => {
  return (
    <div className="rounded-lg shadow-md bg-white p-10">
      <div className="flex justify-between items-center">
        <div>
          <Typography.Title level={5}>Acta - Reunión #1</Typography.Title>
          <span>Diciembre 15 2022</span>
        </div>
        <div>
          <AppButton className="rotate-90" icon={<EllipsisOutlined />} type="ghost" />
        </div>
      </div>
    </div>
  )
}
