import type { FC } from 'react'

import clsx from 'clsx'
import { CaretDownFilled as CaretDownIcon } from '@ant-design/icons'

import { IGridCardBase } from '../@types/GridCard.base'
import useGridCardBase from '../lib/useGridCardBase'

import { GridProgress } from './GridProgress'

export const GridCardBase: FC<IGridCardBase> = ({ content, actions, title, progress }) => {
  const { collapsed, toggleCollapse } = useGridCardBase()

  return (
    <div className="border border-gray-300 rounded-md shadow-sm flex flex-col divide-y divide-gray-100 w-[500px]">
      <div className="flex justify-between py-3 px-4 items-center" onClick={toggleCollapse}>
        <div className="flex gap-5 items-center cursor-pointer">
          <CaretDownIcon
            className={clsx('transition-all duration-200', {
              'rotate-180': !collapsed,
            })}
          />
          <h6 className="font-bold m-0">{title}</h6>
        </div>
        {!!progress && (
          <div>
            <GridProgress max={4} progress={progress.progress} />
          </div>
        )}
      </div>
      <div
        className={clsx('transition-[max-height] duration-200 beautifull-scrollbar', {
          'max-h-0 overflow-hidden': collapsed,
          'max-h-[150px] overflow-auto': !collapsed,
        })}
      >
        {content}
      </div>
      <div className="py-2 px-4">{actions}</div>
    </div>
  )
}
