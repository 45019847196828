import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const MoreSquareFilledIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 319" id="Group_319" transform="translate(-229.195 -4.502)">
        <rect
          data-name="Rectangle 938"
          fill={color}
          height="16"
          id="Rectangle_938"
          rx="3"
          transform="translate(229.195 4.502)"
          width="16"
        />
        <path
          d="M19477.7,18723.5v-2h-2a1.5,1.5,0,0,1,0-3h2v-2a1.5,1.5,0,0,1,3,0v2h2a1.5,1.5,0,0,1,0,3h-2v2a1.5,1.5,0,1,1-3,0Z"
          data-name="Union 12"
          fill="#fff"
          id="Union_12"
          transform="translate(-19242 -18706.996)"
        />
      </g>
    </svg>
  )
}

export default MoreSquareFilledIcon
