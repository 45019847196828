import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const CheckRoundedIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 22 22"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath>
          <rect
            data-name="Rectangle 1270"
            fill="#fff"
            height="22"
            id="Rectangle_1270"
            rx="11"
            transform="translate(-20873 -23005)"
            width="22"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-path)"
        data-name="Mask Group 4"
        id="Mask_Group_4"
        transform="translate(20873 23005)"
      >
        <g data-name="Group 319" id="Group_319" transform="translate(-21992.5 -23456.4)">
          <rect
            data-name="Rectangle 340"
            fill={color}
            height="21"
            rx="10.5"
            transform="translate(1119.5 452)"
            width="22"
          />
          <path
            d="M1124.531,491.5l5.092,4.865,5.058-5.058,12.906-12.906"
            data-name="Path 46"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            transform="translate(0.5 -29)"
          />
        </g>
      </g>
    </svg>
  )
}

export default CheckRoundedIcon
