import { yupResolver } from '@hookform/resolvers/yup'
import { message } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from 'shared/contexts/Auth'
import { labelsService } from 'shared/services'
import { ICreateLabel, ILabel } from 'types/dto/ILabel'
import * as yup from 'yup'

const useLabelForm = ({ onCreate }: { onCreate: (label: ILabel) => void }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { selectedCompany } = useAuth()

  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    reset,
  } = useForm<ICreateLabel>({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Campo requerido.'),
        entity: yup.string().required('Campo requerido.'),
        color_background: yup.string(),
        label_color_text: yup.string(),
      }),
    ),
  })

  const handleSubmit = onSubmit(async (data) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    await labelsService
      .create({
        ...data,
        company_id: selectedCompany!.id,
      })
      .then((res) => {
        message.success('Etiqueta creada')
        reset({})
        onCreate(res)
      })
      .catch((err) => {
        message.error(err?.message)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  })

  return {
    errors,
    control,
    isSubmitting,
    handleSubmit,
  }
}

export default useLabelForm
