import styled from "styled-components";

export const HeadResume = styled.div`
  padding: 8px 12px;
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.colors.light};
  box-shadow: 1px 1px 4px ${({theme}) => theme.colors.light};
`;

export const ProjectInfo = styled.div`
  border-right: 1px solid ${({theme}) => theme.colors.light};
  width: 30%;
`;

export const ProjectStatusWrapper = styled.div`
  flex: 1;
  padding: 10px 12px;
`;
