import { Timeline } from 'antd'
import styled from 'styled-components'

export const Anchor = styled.div`
  position: absolute;
  top: 2px;
  left: -9px;
  transform: rotate(45deg);
  width: 18px;
  height: 18px;
  border-left: 1px solid rgb(229 231 235);
  border-bottom: 1px solid rgb(229 231 235);
  border-radius: 4px 0px 0 3px;
`

export const StyledTimeLine = styled(Timeline)`
  & .ant-timeline-item-content {
    /* inset-block-start: -18px; */
  }
`
