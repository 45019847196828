import React from "react";

import {ModalTaskContextInterface} from "./@types";
import useTask from "./lib/useTask";

export const ModalTaskContext = React.createContext<ModalTaskContextInterface>({
  task: null,
});

export const useModalTaskContext = () => React.useContext(ModalTaskContext);

export function ModalTaskProvider({children}: {children: React.ReactNode}) {
  const {task} = useTask();

  return <ModalTaskContext.Provider value={{task}}>{children}</ModalTaskContext.Provider>;
}
