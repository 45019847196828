import CardContactIcon from 'shared/assets/icons/CardContact'
import UserIcon from 'shared/assets/icons/User'
import CheckListIcon from 'shared/assets/icons/checkList'
import CustomerIcon from 'shared/assets/icons/customer'
import TemplatesIcon from 'shared/assets/icons/templates'
import TextIcon from 'shared/assets/icons/text'
import WorkloadIcon from 'shared/assets/icons/workload'

export enum MenuIconKeys {
  PROJECTS = 'projects',
  CONTACTS = 'contacts',
}

export enum SubmenuIconKeys {
  PROJECTS = 'projects',
  PROJECTS_TASK_AND_ACTIVITIES = 'project-task-and-activities',
  PROJECTS_TEMPLATES = 'projects-templates',
  PROJECTS_WORKLOAD = 'projects-workload',
  CONTACTS_COMPANIES = 'contacts-companies',
  CONTACTS_PERSONS = 'contacts-persons',
}

export default {
  main: {
    [MenuIconKeys.PROJECTS]: <TextIcon />,
    [MenuIconKeys.CONTACTS]: <CardContactIcon className="w-7 h-7 fill-white" />,
  },
  sub: {
    [SubmenuIconKeys.PROJECTS]: <TextIcon color="inherit" />,
    [SubmenuIconKeys.PROJECTS_TASK_AND_ACTIVITIES]: <CheckListIcon color="inherit" size={27} />,
    [SubmenuIconKeys.PROJECTS_TEMPLATES]: <TemplatesIcon color="inherit" size={27} />,
    [SubmenuIconKeys.PROJECTS_WORKLOAD]: <WorkloadIcon color="inherit" size={27} />,
    [SubmenuIconKeys.CONTACTS_COMPANIES]: <CustomerIcon color="inherit" size={22} />,
    [SubmenuIconKeys.CONTACTS_PERSONS]: <UserIcon color="inherit" size={24} />,
  },
}
