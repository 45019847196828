import type {IProjectBoard} from "types/dto/IProjectBoard";

import {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "shared/contexts/Auth";

import api from "../api";

const useBoardFilter = () => {
  const [boards, setBoards] = useState<IProjectBoard[]>([]);

  const {selectedCompany} = useAuth();

  const loading = useRef(false);

  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const value = useMemo(() => {
    const boardId = search.get("boardId");

    if (boardId) return +boardId;

    return null;
  }, [search]);

  const loadData = async () => {
    if (loading.current) return;
    loading.current = true;

    await api
      .getBoards(selectedCompany!.id)
      .then((res) => {
        setBoards(res);
      })
      .finally(() => {
        loading.current = false;
      });
  };

  const handleChange = (selectedValue: number) => {
    if (selectedValue) {
      search.set("boardId", selectedValue + "");
    } else {
      search.delete("boardId");
    }

    navigate({
      search: search.toString(),
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: loading.current,
    boards,
    value,
    handleChange,
  };
};

export default useBoardFilter;
