import type {TreeData} from "../@types";
import type {CSSProperties} from "react";

import {AnimateLayoutChanges, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import {useTreeTasksListContext} from "../context";
import {SortableTreeItemProps} from "../@types/SortableTreeItem";

import TreeItem from "./TreeItem";

const animateLayoutChanges: AnimateLayoutChanges = ({isSorting, wasDragging}) =>
  isSorting || wasDragging ? false : true;

export default function SortableTreeItem<TTreeData extends TreeData = TreeData>({
  depth,
  data,
  columns,
  indentationWidth,
  onCollapse,
  ...props
}: SortableTreeItemProps<TTreeData>) {
  const {idField = "id"} = useTreeTasksListContext();

  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    transform,
    transition,
    setDraggableNodeRef,
    setDroppableNodeRef,
  } = useSortable({
    id: data[idField],
    animateLayoutChanges,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      collapsed={data.collapsed}
      columns={columns}
      data={data}
      depth={depth}
      disableInteraction={isSorting}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      indentationWidth={indentationWidth}
      style={style}
      wrapperRef={setDroppableNodeRef}
      onCollapse={onCollapse}
      {...props}
    />
  );
}
