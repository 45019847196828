import type { FC } from 'react'
import type { IBreadCrumb } from './types'

import { Breadcrumb as AndBreadCrumb } from 'antd'

import { BreadCrumbText } from './styled'

const BreadCrumb: FC<IBreadCrumb> = ({ crumbs }) => {
  return (
    <AndBreadCrumb separator={<BreadCrumbText type="secondary">/</BreadCrumbText>}>
      {crumbs.map((crumb, index) => (
        <AndBreadCrumb.Item key={`crumb-${index}`}>
          {index !== crumbs.length - 1 ? (
            <BreadCrumbText strong type="secondary">
              {crumb}
            </BreadCrumbText>
          ) : (
            <BreadCrumbText strong>{crumb}</BreadCrumbText>
          )}
        </AndBreadCrumb.Item>
      ))}
    </AndBreadCrumb>
  )
}

export default BreadCrumb
