import type {ICreateProject} from "../@types/useProjectForm";

import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {projectsService} from "shared/services";
import {message} from "antd";
import {useAuth} from "shared/contexts/Auth";
import moment from "moment";

import createProjectValidation from "./utils/createProject.validation";

const useProjectForm = ({onCancel}: {onCancel?: () => void}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {selectedCompany} = useAuth();

  const {
    control,
    formState: {errors},
    handleSubmit: onSubmit,
    watch,
  } = useForm<ICreateProject>({
    mode: "onChange",
    shouldUnregister: true,
    resolver: yupResolver(createProjectValidation),
  });

  const handleSubmit = onSubmit(async (data) => {
    setIsSubmitting(true);
    projectsService
      .create<ICreateProject>({
        ...data,
        company_id: selectedCompany!.id,
        start_date: moment(data.start_date).format("YYYY/MM/DD"),
        end_date: moment(data.end_date).format("YYYY/MM/DD"),
      })
      .then(() => {
        message.success("Proyecto creado.");
        onCancel && onCancel();
      })
      .catch((err) => {
        message.error(err?.message || "No ha sido posible crear el proyecto.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return {
    control,
    errors,
    selectedBoardId: watch("project_board_id"),
    personId: watch("person_id"),
    isSubmitting,
    handleSubmit,
  };
};

export default useProjectForm;
