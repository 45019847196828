import type { FC } from 'react'
import type { IActivityModalProps } from '../@types/AtivityModal'

import { ActivityForm } from 'modules/Activities'

import { CustomDrawer } from './styles/styled'

export const ActivityModal: FC<IActivityModalProps> = ({ open, onClose }) => {
  return (
    <CustomDrawer
      closable={false}
      contentWrapperStyle={{ marginTop: 66, borderTopLeftRadius: 8 }}
      maskStyle={{
        opacity: 0.3,
      }}
      open={open}
      placement="right"
      width="60%"
      zIndex={99}
      onClose={() => onClose && onClose()}
    >
      <ActivityForm onClose={onClose} />
    </CustomDrawer>
  )
}
