import type { FC } from 'react'
import type { IProgressCircle } from '../@types/ProgressCircle'

import { useTheme } from 'styled-components'

import { CustomProgress } from './styles/progressCircle.styled'

export const ProgressCircle: FC<IProgressCircle> = ({ max, progress, width, className }) => {
  const theme = useTheme()

  return (
    <CustomProgress
      className={className}
      format={(percent) => {
        return (
          <span className="text-xs font-bold">({`${((percent || 0) * max) / 100}/${max}`})</span>
        )
      }}
      percent={(progress / max) * 100}
      strokeColor={theme.colors['green-light']}
      type="circle"
      width={width}
    />
  )
}
