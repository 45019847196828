import type {FC} from "react";
import type {IStepBarProps} from "../@types/StepBar";

import clsx from "clsx";

import {StepItem} from "./StepItem";

export const StepBar: FC<IStepBarProps> = ({className, size = "small", disabled}) => {
  return (
    <div
      className={clsx("flex rounded-lg overflow-hidden divide-white", className, {
        "divide-x-4": size === "small",
        "divide-x-8": size === "large",
      })}
    >
      <StepItem completed disabled={disabled} label="Kick Off" size={size} />
      <StepItem disabled={disabled} label="Planning" size={size} />
      <StepItem disabled={disabled} label="Implementation" size={size} />
      <StepItem disabled={disabled} label="Review" size={size} />
      <StepItem disabled={disabled} label="Closing" size={size} />
    </div>
  );
};
