import styled from "styled-components";

export const ListGroup = styled.div`
  display: block;
  margin: 0 0 30px 0;
  padding: 0 15px 0 39px;
  border-radius: 12px;
  border: 1px solid #f0f1f3;
  background: #fafbfc;
  width: max-content;
  min-width: 100%;
`;

export const ListGroupHeader = styled.div`
  position: sticky;
  left: 20px;
  margin-left: -16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ListGroupHeaderInner = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 115px 8px 0;
  position: relative;
`;

export const ListDetailsMenu = styled.div`
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  margin: 2px -5px -4px -16px;
  padding: 4px 5px 4px 2px;
  display: inline-flex;
  align-items: center;
`;

export const ListGroupToggleIcon = styled.div`
  transition: background 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 16px;
  height: 16px;
  color: #656f7d;
  border-radius: 50%;
  border: 1px solid currentColor;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  :hover {
    background: currentColor;

    svg {
      fill: white !important;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #656f7d;
    display: block;
    margin-right: 1px;
  }
`;

export const ListGroupName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #34313a;
  margin-left: 5px;
  user-select: none;
`;

export const TaskRowNew = styled.div`
  display: block;
  min-width: 100%;
  z-index: 15;
  position: relative;
`;

export const TaskRowNewContainer = styled.div`
  position: sticky;
  left: 0;
  background: #fff;
  border: 1px solid transparent;
  border-block-end-color: #e8eaed;
  width: 100%;
`;

export const TaskRowNewContainerBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;
`;

export const TaskRowNewLeft = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  padding-left: 5px;
  flex-grow: 1;
`;

export const TaskRowNewStatus = styled.div`
  order: 1;
  display: flex;
  align-items: center;
  height: 36px;
`;

export const TaskRowNewMain = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  order: 3;
`;

export const TaskRowNewInput = styled.input`
  appearance: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  cursor: text;
  color: #343434;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  height: 36px;

  :focus {
    outline: none;
  }
`;

export const TaskList = styled.div`
  display: contents;
`;

export const TaskListHeader = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  height: auto;
  background-color: #fafbfc;
  color: transparent;

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: currentColor;
  }
`;

export const TaskListHeaderActions = styled.div`
  transition: opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  position: absolute;
  width: 20px;
  left: -20px;
  bottom: 6px;
  background: #fafbfc;
  color: transparent;
`;

export const TaskListHeaderActionIconArrow = styled.div`
  transition: background 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 16px;
  height: 16px;
  color: #656f7d;
  border-radius: 50px;
  border: 1px solid currentColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;

  :hover {
    background: currentColor;

    svg {
      fill: white !important;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #656f7d;
    display: block;
  }
`;

export const TaskListHeaderItemMain = styled.div`
  flex-grow: 1;
  display: inline-flex;
  min-width: 350px;
  background-color: #fafbfc;
  color: transparent;
  padding-top: 24px;
  position: sticky;
  left: 0;
  z-index: 20;

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: currentColor;
    top: 0;
  }
`;

export const TaskListHeaderItemLabel = styled.div`
  position: relative;
  min-width: 5px;
  display: flex;
  align-items: center;
  min-height: 24px;
`;

export const TaskListItemLabelText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #292d34;
  margin-right: 10px;
  display: flex;
  align-items: center;

  &.overdue {
    color: #fb926a;
  }
`;

export const TaskListHeaderField = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #858585;
  transition: background 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
`;

export const TaskListHeaderFieldItem = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 20px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  margin-top: 2px;
  width: 100%;
  justify-content: center;
`;

export const TaskListHeaderItems = styled.div`
  display: inline-flex;
  align-items: center;
  padding-top: 24px;
`;

export const TaskRow = styled.div`
  cursor: pointer;
  user-select: none;
  padding-inline: 40px 20px;
`;

export const TaskRowToggle = styled.div`
  display: block;
  position: absolute;
  left: -31px;
  top: 0;
  width: 22px;
  height: 100%;
  cursor: pointer;
`;

export const TaskRowHandle = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  position: absolute;
  flex-shrink: 0;
  width: 14px;
  padding: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: move;
  overflow: hidden;

  span {
    width: 100%;
    height: 100%;

    svg {
      fill: #bcc0c7;
    }
  }
`;

export const TaskRowContainer = styled.div`
  position: sticky;
  top: 45px;
  display: flex;
  width: 100%;
  min-height: 38px;
  height: 38px;
  z-index: 10;
  transform: translateY(0);
  background-color: white;
  border: 1px solid white;
  border-block-end-color: #f0f1f3;

  :hover {
    background-color: #fafbfc;
    .task-main {
      background: #fafbfc;
    }
    ${TaskRowHandle} {
      opacity: 1;
    }
  }
`;

export const TaskRowMain = styled.div`
  display: flex;
  align-items: center;
  min-width: 349px;
  flex-shrink: 1;
  flex-grow: 1;
  /* z-index: 20; */
  padding: 1px 5px;
  border-block-start: 1px solid transparent;
  margin-block-start: calc(-1 * 1px);
  position: sticky;
  left: 0;
  background: white;
`;

export const TaskListRows = styled.div`
  position: relative;
  margin-right: -20px;
  margin-left: -40px;
  content: layout style;
  min-height: 36px;
`;

export const TaskRowArrow = styled.div`
  width: 16px;
  height: 25px;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 10;
`;

export const TaskRowArrowIcon = styled.div`
  transition: transform 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transform: rotate(90deg);

  display: flex;
  align-items: center;
  justify-content: center;

  &.collapsed {
    transform: rotate(0);
  }

  svg {
    fill: #656f7d;
  }
`;

export const TaskRowStatus = styled.div`
  align-self: auto;
`;

export const TaskRowCrumbs = styled.div`
  padding-top: 0;
  width: 100%;
  height: 100%;
  min-width: 0;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;

export const TaskRowEditable = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  overflow-wrap: break-word;
`;

export const TaskListRowField = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #858585;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
`;

export const TaskRowField = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 20px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  margin-top: 2px;
  width: 100%;
  justify-content: center;
`;
