import type {ItemTreeProps, TreeData} from "../@types";

import clsx from "clsx";
import React from "react";
import {CaretRightFilled, HolderOutlined} from "@ant-design/icons";

import {TaskStatusPicker} from "../../TaskStatusPicker";
import {renderColValue} from "../lib/utilities";

import {
  TaskListRowField,
  TaskRowArrow,
  TaskRowArrowIcon,
  TaskRowContainer,
  TaskRowCrumbs,
  TaskRowEditable,
  TaskRowField,
  TaskRowHandle,
  TaskRowMain,
  TaskRowStatus,
  TaskRowToggle,
} from "./styled";

const TreeItem = React.forwardRef<HTMLDivElement, ItemTreeProps>(function item<
  TTreeData extends TreeData = TreeData,
>(
  {
    depth,
    data,
    columns,
    ghost,
    handleProps,
    indentationWidth,
    collapsed,
    style,
    onCollapse,
    wrapperRef,
    ...props
  }: ItemTreeProps<TTreeData>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={wrapperRef}
      className={clsx({
        "opacity-50": ghost,
      })}
      {...props}
    >
      {ghost ? (
        <TaskRowMain
          style={{
            paddingLeft: `${indentationWidth * depth}px`,
            height: 20,
          }}
        >
          <>
            <div
              ref={ref}
              className="relative w-full h-1 bg-blue-500 before:absolute before:content[''] before:w-4 before:h-4 before:rounded-full before:bg-blue-500 before:left-0 before:top-1/2 before:-translate-y-1/2 before:z-50"
              style={{
                ...style,
                zIndex: 30,
              }}
            />
          </>
        </TaskRowMain>
      ) : (
        <TaskRowContainer ref={ref} style={style}>
          <TaskRowToggle>
            <TaskRowHandle {...handleProps}>
              <HolderOutlined />
            </TaskRowHandle>
          </TaskRowToggle>

          <TaskRowMain
            className="task-main z-20"
            style={{
              paddingLeft: `${indentationWidth * depth}px`,
            }}
          >
            {!!data?.children?.length && (
              <TaskRowArrow>
                <TaskRowArrowIcon
                  className={clsx({
                    collapsed: collapsed,
                  })}
                  onClick={onCollapse}
                >
                  <CaretRightFilled />
                </TaskRowArrowIcon>
              </TaskRowArrow>
            )}
            <TaskRowStatus>
              <TaskStatusPicker bordered={false} type="compact" value={data.task_status_id} />
            </TaskRowStatus>
            <TaskRowCrumbs>
              <div
                className="bg-white flex items-center task-main w-full overflow-hidden"
                style={{
                  height: "100%",
                }}
              >
                <div className="w-full">
                  <TaskRowEditable>
                    <div>{renderColValue(columns[0], data)}</div>
                  </TaskRowEditable>
                </div>
              </div>
            </TaskRowCrumbs>
          </TaskRowMain>

          {columns.length > 1
            ? columns.slice(1).map((column, colIndex) => (
                <TaskListRowField
                  key={`row-group-col-${colIndex}`}
                  style={{
                    width: column?.width || 160,
                  }}
                >
                  <TaskRowField className={column?.rowColClassName} style={column?.rowColStyles}>
                    {renderColValue(column, data)}
                  </TaskRowField>
                </TaskListRowField>
              ))
            : null}
        </TaskRowContainer>
      )}
    </div>
  );
});

export default TreeItem;
