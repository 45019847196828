import type {SelectProps} from "antd";
import type {IUseOrganizationSelectProps} from "../@types";
import type {IOrganization} from "types/dto/IOrganization";
import type {FieldValues} from "react-hook-form";

import {useController} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {organizationsService} from "shared/services";
import _, {debounce} from "lodash";
import {useAuth} from "shared/contexts/Auth";

const DEBOUNCE_TIMEOUT = 800;

function useOrganizationFetcher<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  byPersonId,
}: IUseOrganizationSelectProps<TFieldValues>) {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchRef = useRef(0);
  const {selectedCompany} = useAuth();

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const fetchOrganizations = useCallback(
    (searchValue?: string) => {
      return organizationsService
        .find({
          query: _.merge(
            {
              $client: {},
            },
            searchValue
              ? {
                  $client: {
                    searchValue,
                  },
                }
              : {},
            byPersonId
              ? {
                  $client: {
                    byPersonId,
                  },
                }
              : {},
            {
              company_id: selectedCompany?.id,
            },
          ),
        })
        .then(({data, ...rest}) => ({
          data: data,
          ...rest,
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [byPersonId],
  );

  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setOrganizations([]);
      setIsFetching(true);

      if (fetchId !== fetchRef.current) return;
      fetchOrganizations(searchValue).then(({data}) => {
        setOptions(
          data.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        );
        setOrganizations(data);
        setIsFetching(false);
      });
    };

    return debounce(loadOptions, DEBOUNCE_TIMEOUT);
  }, [fetchOrganizations]);

  const handleChange = (value: number) => {
    onChange(value);
  };

  const fetchInitialOrganizations = async () => {
    setIsFetching(true);
    await fetchOrganizations()
      .then(({data}) => {
        setOrganizations(data);
        setOptions(
          data.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchInitialOrganizations();
    onChange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byPersonId]);

  return {
    value,
    options,
    isFetching,
    handleChange,
    debounceFetcher,
  };
}

export default useOrganizationFetcher;
