import type { FC } from 'react'

import { AppSelectC } from 'shared/components/common/AppSelect'
import { AppInput } from 'shared/components/common/Input'
import { ModalForm } from 'shared/components/common/ModalForm'
import { PersonVisibilities } from 'types/dto/IPerson'
import { OrganizationSelect } from 'shared/components/common/OrganizationSelect'
import { LabelsInput } from 'shared/components/common/LabelsInput'
import { LabelEntityTypes } from 'types/dto/ILabel'
import { OwnerSelect } from 'shared/components/common/OwnerSelect'

import { IModalPersonProps } from '../../@types/ModalPerson'
import useModalPerson from '../../lib/useModalPerson'

import { ExtraInputs } from './ExtraInputs'

export const ModalPerson: FC<IModalPersonProps> = ({ open, onCancel }) => {
  const { errors, control, handleSubmit } = useModalPerson({ onCancel })

  return (
    <ModalForm
      centered
      footerProps={{
        submitText: 'Crear',
        okButtonProps: {
          width: 120,
        },
      }}
      open={open}
      title="Añadir Personas"
      width={420}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-2">
        <AppInput
          control={control}
          error={errors?.first_name?.message}
          label="Nombres"
          name="first_name"
        />
        <AppInput
          control={control}
          error={errors?.last_name?.message}
          label="Apellidos"
          name="last_name"
        />
        <OrganizationSelect
          control={control}
          error={errors?.organization_id?.message}
          label="Organización"
          name="organization_id"
        />
        <LabelsInput
          control={control}
          entities={[LabelEntityTypes.ALL, LabelEntityTypes.PERSONS]}
          error={errors?.person_labels?.message}
          name="person_labels"
        />
        <ExtraInputs
          phoneInput
          addTextButton="+ Añade un número de teléfono"
          control={control}
          extraArrayInputName="person_extra_phones"
          label="Teléfono"
          primaryInputName="primary_phone"
        />
        <ExtraInputs
          addTextButton="+ Añade un correo electrónico"
          control={control}
          extraArrayInputName="person_extra_emails"
          label="Correo Electrónico"
          primaryInputName="primary_email"
        />

        <OwnerSelect
          control={control}
          error={errors?.owner_user_id?.message}
          label="Propietario"
          name="owner_user_id"
        />
        <AppSelectC
          className="w-full"
          control={control}
          error={errors?.visible_to?.message}
          label="Visible para"
          name="visible_to"
          options={Object.entries(PersonVisibilities).map(([value, label]) => ({ label, value }))}
          separator={false}
        />
      </div>
    </ModalForm>
  )
}
