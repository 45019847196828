import type {UniqueIdentifier} from "@dnd-kit/core";

export const getId = (data: Record<string, any>, idField: string) => {
  return data[idField];
};

export const findContainer = (
  itemId: UniqueIdentifier,
  idField: string,
  data: Record<UniqueIdentifier, any[]>,
  columnsKeys: string[],
) => {
  if (
    (itemId + "").startsWith("col-") &&
    columnsKeys.includes((itemId + "").replaceAll("col-", ""))
  )
    return (itemId + "").replaceAll("col-", "");

  return Object.keys(data).find((key) => !!data[key].find((item) => item[idField] === itemId));
};

export const getDataItem = (
  itemId: UniqueIdentifier,
  idField: string,
  data: Record<UniqueIdentifier, any[]>,
) => {
  return Object.values(data)
    .reduce((acc, it) => [...acc, ...it], [])
    .find((item) => item[idField] === itemId);
};
