/* eslint-disable prettier/prettier */
import { Button } from 'antd'
import styled, { css } from 'styled-components'


export interface IAppButton {
  width?: number
  $success?: boolean
  $info?: boolean
  $hovered?: boolean
  $underline?: boolean
  $white?: boolean
}

// eslint-disable-next-line prettier/prettier
const AppButton = styled(Button) <IAppButton>`
  border-radius: 5px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({$underline}) => {
    if ($underline) return css`
      & > span {
        text-decoration: underline;;
      }
    `
  }}

  ${({ $success: success, $info: info, $white: white, danger, type, theme }) => {
    if (type !== 'primary') return ''
    if (success) return css`
        color: white !important;
        font-weight: bold;
        background: ${theme.colors.success} !important;
      `
    if (white) return css`
        color: black !important;
        border-color: ${theme.colors.grey} !important;
        font-weight: 500;
        background: white !important;
      `
    if (info) return css`
      color: ${theme.colors.info} !important;
      font-weight: bold;
      background: ${theme.colors['info-light']} !important;
      & svg {
        fill: ${theme.colors['info']};
      }
    `
    if (danger) return css`
        color: white !important;
        font-weight: bold;
        background: ${theme.colors.error} !important;
      `

    return css`
        color: white !important;
        background: ${theme.colors.primary} !important;
      `
  }}

  &:hover {
    ${({$hovered = true}) => {
      if ($hovered) return css`
        background: transparent !important;
      `
    }}
    ${({ $success: success, $white: white, danger, type, theme, $hovered = true }) => {
      if (type === 'ghost') return css`
        background: ${theme.colors.light} !important;
      `
      if (type !== 'primary' || !$hovered) return ''
      if (success) return css`
          color: ${theme.colors.success} !important;
          border-color: ${theme.colors.success} !important;
        `
      if (white) return css`
          background: white !important;
          color: black !important;
          border-color: ${theme.colors.grey} !important;
        `
    
      if (danger) return css`
          color: ${theme.colors.error} !important;
          border-color: ${theme.colors.error} !important;
        `

      return css`
          color: ${theme.colors.primary} !important;
          border-color: ${theme.colors.primary} !important;
        `
    }}
    
  }
`

export default AppButton
