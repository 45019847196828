import type {FC} from "react";
import type {InputProps} from "../@types/Input";

import {Control, Controller} from "react-hook-form";
import {Input} from "antd";

export const AppInput: FC<
  InputProps & {
    control?: Control<any>;
    error?: string;
  }
> = ({label, id, control, name, error, ...props}) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name!}
      render={({field: {value, onChange}}) => (
        <div className="w-full">
          {label && (
            <label className="text-gray-400 font-bold" htmlFor={id}>
              {label}
            </label>
          )}
          <Input
            id={id}
            status={error ? "error" : undefined}
            value={value}
            onChange={onChange}
            {...props}
          />
          {Boolean(error) && (
            <span className="block text-red-500 text-sm animate-fade-in">{error}</span>
          )}
        </div>
      )}
    />
  );
};
