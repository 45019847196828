import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ExtraTabsViewKeys } from '../@types/useExtraTabs'

const useExtraTabs = () => {
  const [currentTab, setCurrentTab] = useState<ExtraTabsViewKeys>(ExtraTabsViewKeys.NOTES)

  const location = useLocation()
  const navigate = useNavigate()

  const handleChangeView = (view: ExtraTabsViewKeys) => {
    const search = new URLSearchParams(location.search)

    search.set('extra-view', view)
    navigate({
      search: search.toString(),
    })
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)

    const view = <ExtraTabsViewKeys | undefined>search.get('extra-view')

    if (!view) {
      search.set('extra-view', ExtraTabsViewKeys.NOTES)
      navigate({
        search: search.toString(),
      })
    } else {
      setCurrentTab(view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return {
    currentTab,
    handleChangeView,
  }
}

export default useExtraTabs
