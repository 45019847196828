import type { FC } from 'react'
import type { ITask } from 'types/dto/ITask'

import GantIcon from 'shared/assets/icons/gant'
import KanbanIcon from 'shared/assets/icons/kanban'
import ListIcon from 'shared/assets/icons/list'
import AppSelect from 'shared/components/common/AppSelect'
import { CalendarOutlined as CalendarIcon } from '@ant-design/icons'
import { Divider } from 'antd'
import clsx from 'clsx'
import AppButton from 'shared/components/common/AppButton'
import { MinusOutlined as MinusIcon } from '@ant-design/icons'

import { GridCardDeliverables } from '../../GridCard/ui/GriCardDeliverables'
import { GridCardActivities } from '../../GridCard/ui/GridCardActivities'
import { PlanViewKeys } from '../@types/PlanView.keys'
import usePlan from '../lib/usePlan'
import { BacklogGrids } from '../../BacklogGrids/ui/BacklogGrids'

import { Kanban } from './components/Kanban'
import { Date } from './components/Date'

export const PLAN_VIEWS = {
  [PlanViewKeys.DATE]: (props: any) => <Date {...props} />,
  [PlanViewKeys.HITOS]: (_props: any) => <div>Hitos</div>,
  [PlanViewKeys.KANBAN]: (props: any) => <Kanban {...props} />,
  [PlanViewKeys.GANT]: (_props: any) => <div>GANT</div>,
}

export const Plan: FC = () => {
  const tasks: ITask[] = []
  const { currentView, collapsedPane, hiddenContent, togglePane, handleChangeView } = usePlan()

  return (
    <div className="flex divide-x divide-slate-200 px-4 py-3">
      <div
        className={clsx('transition-[max-width] duration-200', {
          'max-w-[30px] overflow-hidden': collapsedPane,
          'max-w-[40%]': !collapsedPane,
        })}
      >
        <div
          className={clsx('flex pr-4 justify-end', {
            'justify-end': !collapsedPane,
            'items-end h-[250px]': collapsedPane,
          })}
        >
          <AppButton
            className={clsx('font-bold', {
              '-rotate-90 whitespace-nowrap': collapsedPane,
            })}
            icon={collapsedPane ? 'Entregables/Activities/Backlog' : <MinusIcon />}
            size="small"
            type="ghost"
            onClick={togglePane}
          />
        </div>
        <div
          className={clsx({
            hidden: hiddenContent,
          })}
        >
          <div className="flex flex-col gap-4 p-4 w-full transition-all duration-700">
            <GridCardDeliverables />
            <GridCardActivities />

            <Divider style={{ margin: '0' }} type="horizontal">
              <h4 className="font-bold m-0 text-md">Backlog</h4>
            </Divider>

            <BacklogGrids />
          </div>
        </div>
      </div>
      <div className="p-4 overflow-x-auto beautifull-scrollbar flex-1">
        <div className="mb-4">
          <AppSelect
            defaultValue={PlanViewKeys.KANBAN}
            options={[
              {
                label: 'Hitos',
                value: PlanViewKeys.HITOS,
                icon: <ListIcon color="inherit" size={15} />,
              },
              { label: 'Fecha', value: PlanViewKeys.DATE, icon: <CalendarIcon /> },
              {
                label: 'Kanban',
                value: PlanViewKeys.KANBAN,
                icon: <KanbanIcon color="inherit" size={15} />,
              },
              {
                label: 'Diag. Gant',
                value: PlanViewKeys.GANT,
                icon: <GantIcon color="inherit" size={15} />,
              },
            ]}
            value={currentView}
            width={145}
            onChange={(key: PlanViewKeys) => handleChangeView(key)}
          />
        </div>
        <div>{!!currentView && PLAN_VIEWS[currentView]({ tasks })}</div>
      </div>
    </div>
  )
}
