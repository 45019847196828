import type {SelectProps} from "antd";
import type {IUseOwnerSelectProps} from "../@types/useOwnerSelect";
import type {ICompanyUserExtended} from "types/dto/ICompanyUser";
import type {FieldValues} from "react-hook-form";

import {useController} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {companyUsersService} from "shared/services";
import _, {debounce} from "lodash";
import {useAuth} from "shared/contexts/Auth";

const DEBOUNCE_TIMEOUT = 800;

function useUserFetcher<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  defaultAuthOwner = true,
  onChange: handleCustomChange,
}: IUseOwnerSelectProps<TFieldValues>) {
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<Omit<ICompanyUserExtended, "company">[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchRef = useRef(0);
  const {user, selectedCompany} = useAuth();

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const fetchUsers = useCallback(
    (searchValue?: string) => {
      return companyUsersService
        .find<Omit<ICompanyUserExtended, "company">>({
          query: _.merge(
            {
              $client: {},
            },
            searchValue
              ? {
                  $client: {
                    searchValue,
                  },
                }
              : {},
            {
              company_id: selectedCompany?.id,
              $client: {
                withUserData: "true",
              },
            },
          ),
        })
        .then(({data, ...rest}) => ({
          data: data,
          ...rest,
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const getOptionLabel = useCallback(
    (companyUser: Omit<ICompanyUserExtended, "company">) => {
      if (companyUser.user_id === user!.id)
        return `${companyUser!.user.first_name} ${companyUser!.user.last_name} (Yo)`;

      return `${companyUser!.user.first_name} ${companyUser!.user.last_name}`;
    },
    [user],
  );

  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setUsers([]);
      setIsFetching(true);

      if (fetchId !== fetchRef.current) return;
      fetchUsers(searchValue).then(({data}) => {
        setOptions(
          data.map((u) => ({
            label: getOptionLabel(u),
            value: u.user_id,
          })),
        );
        setUsers(data);
        setIsFetching(false);
      });
    };

    return debounce(loadOptions, DEBOUNCE_TIMEOUT);
  }, [fetchUsers, getOptionLabel]);

  const handleChange = (value: number) => {
    onChange(value);
    if (handleCustomChange) {
      handleCustomChange(value);
    }
  };

  const getInitialUsers = async () => {
    setIsFetching(true);
    await fetchUsers()
      .then(({data}) => {
        setUsers(data);
        setOptions(
          data.map((u) => ({
            label: getOptionLabel(u),
            value: u.user_id,
          })),
        );
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    getInitialUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value && user && defaultAuthOwner) onChange(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, value, defaultAuthOwner]);

  return {
    value,
    options,
    isFetching,
    handleChange,
    debounceFetcher,
  };
}

export default useUserFetcher;
