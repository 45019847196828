/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  IFlattenedItem,
  ISortableTreeItemProps,
  ITreeItem,
  ITreeItemProps,
} from "../@types/TreeFiles";

import {CSSProperties, FC, forwardRef, useMemo, useState} from "react";
import {
  CaretDownOutlined,
  DownOutlined,
  EllipsisOutlined,
  RightOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {Button} from "antd";
import MoreSquareFilledIcon from "shared/assets/icons/MoreSquareFilled";
import DocumentIcon from "shared/assets/icons/Document";
import DragHandlerIcon from "shared/assets/icons/DragHandler";
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringStrategy,
  UniqueIdentifier,
  closestCenter,
} from "@dnd-kit/core";
import {
  AnimateLayoutChanges,
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {createPortal} from "react-dom";
import {CSS} from "@dnd-kit/utilities";
import clsx from "clsx";
import _ from "lodash";

import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  setProperty,
} from "../lib/utilities";

const defaultItems: ITreeItem[] = [
  {
    id: 1,
    name: "Carpeta 1",
    type: "folder",
    children: [
      {
        id: 2,
        name: "Página 1",
        type: "doc",
        children: [],
      },
      {
        id: 3,
        name: "Página 2",
        type: "doc",
        children: [],
      },
    ],
  },
  {
    id: 4,
    name: "Carpeta 2",
    type: "folder",
    children: [
      {
        id: 5,
        name: "Página 1",
        type: "doc",
        children: [],
      },
    ],
  },
  {
    id: 6,
    name: "Carpeta 3",
    type: "folder",
    children: [],
  },
];

const TreeItem = forwardRef<HTMLDivElement, ITreeItemProps>(function Item(
  {
    depth,
    disableSelection,
    // disableInteraction,
    ghost,
    handleProps,
    indentationWith,
    indicator,
    collapsed,
    style,
    id,
    name,
    type,
    onCollapse,
    wrapperRef,
    ...props
  },
  ref,
) {
  return (
    <div
      ref={wrapperRef}
      className={clsx("hover:bg-[#DEEAF9] transition-all duration-200 rounded-md p-3", {
        "opacity-50": ghost && !indicator,
      })}
      style={
        {
          paddingLeft: `${indentationWith * depth}px`,
        } as CSSProperties
      }
      {...props}
    >
      {ghost && indicator ? (
        <div
          ref={ref}
          className="relative w-full h-1 bg-blue-500 before:absolute before:content[''] before:w-4 before:h-4 before:rounded-full before:bg-blue-500 before:left-0 before:top-1/2 before:-translate-y-1/2"
          style={{
            ...style,
            zIndex: 100,
          }}
        />
      ) : (
        <div ref={ref} className="relative flex items-center justify-between" style={style}>
          <div className="flex gap-1 items-center">
            <Button
              {...handleProps}
              className="flex items-center justify-center cursor-move"
              icon={<DragHandlerIcon className="w-10 h-10" />}
              size="small"
              type="ghost"
            />
            <Button
              className="flex items-center justify-center"
              icon={
                <DocumentIcon
                  className="w-6 h-6"
                  color={type === "folder" ? "#367ddf" : "#707070"}
                />
              }
              size="small"
              type="text"
            />
            <h3 className="cursor-pointer flex-1 flex-nowrap">{name}</h3>
          </div>
          <div className="flex gap-1 items-center justify-between">
            <Button
              className="flex items-center justify-center"
              icon={<EllipsisOutlined />}
              size="small"
              type="text"
            />
            {type === "folder" && (
              <Button
                className={clsx(
                  "transition-transform duration-200 ease-linear flex items-center justify-center",
                  {
                    "rotate-90": !collapsed,
                  },
                )}
                icon={<RightOutlined size={12} />}
                size="small"
                type="text"
                onClick={onCollapse}
              />
            )}
            {type === "folder" && (
              <Button
                className="flex items-center justify-center"
                icon={<MoreSquareFilledIcon className="w-6 h-6" color="#367ddf" />}
                size="small"
                type="text"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

const animateLayoutChanges: AnimateLayoutChanges = ({isSorting, wasDragging}) =>
  isSorting || wasDragging ? false : true;

export const SortableTreeItem: FC<ISortableTreeItemProps> = ({id, depth, ...props}) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      depth={depth}
      disableInteraction={isSorting}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      id={id}
      style={style}
      wrapperRef={setDroppableNodeRef}
      {...props}
    />
  );
};

export const TreeFiles: FC<{
  indicator?: boolean;
  indentationWidth?: number;
  collapsible?: boolean;
}> = ({indicator = false, indentationWidth = 20, collapsible = true}) => {
  const [items, setItems] = useState(() => defaultItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<number[]>(
      (acc: number[], {children, collapsed, id}) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, activeId]);

  const sortedIds = useMemo(() => {
    return flattenedItems.map(({id}) => id);
  }, [flattenedItems]);

  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null;

  const activeItem = activeId ? flattenedItems.find(({id}) => id === activeId) : null;

  const handleCollapse = (id: UniqueIdentifier) => {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      }),
    );
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  };

  const handleDragStart = ({active: {id: activeId}}: DragStartEvent) => {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({id}) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  };

  const handleDragMove = ({delta}: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({over}: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    resetState();

    if (projected && over) {
      const {depth, parentId} = projected;
      const clonedItems: IFlattenedItem[] = _.clone(flattenTree(items));
      const overIndex = clonedItems.findIndex(({id}) => id === over.id);
      const activeIndex = clonedItems.findIndex(({id}) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = {...activeTreeItem, depth, parentId};

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setItems(newItems);
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h4 className="text-3xl font-bold">Documentos</h4>
        <Button
          className="flex items-center justify-center"
          icon={<SearchOutlined />}
          type="text"
        />
      </div>
      <DndContext
        collisionDetection={closestCenter}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-1 flex-nowrap">
            {flattenedItems.map(({id, depth, collapsed, children, ...props}) => (
              <SortableTreeItem
                key={`tree-item-${id}`}
                collapsed={Boolean(collapsed && children.length)}
                depth={id === activeId && projected ? projected.depth : depth}
                id={id}
                indentationWith={indentationWidth}
                indicator={indicator}
                onCollapse={collapsible && children.length ? () => handleCollapse(id) : undefined}
                {...props}
              />
            ))}
          </div>
          {createPortal(
            <DragOverlay>
              {activeId && activeItem ? (
                <SortableTreeItem
                  depth={activeItem.depth}
                  id={Number(activeId)}
                  indentationWith={0}
                  name={activeItem.name}
                  type={activeItem.type}
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )}
        </SortableContext>
      </DndContext>
    </div>
  );
};
