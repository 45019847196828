import type { FC } from 'react'

import { ILoginStrategyProps } from '../@types/LoginStrategy'

export const LoginStrategy: FC<ILoginStrategyProps> = ({ icon, text, onCLick }) => {
  return (
    <div className="border rounded-md px-4 flex items-center hover:bg-gray-300 transition-all duration-200 cursor-pointer">
      <div className="mr-4 text-2xl">{icon}</div>
      <button className="text-lg tracking-tighter p-4  flex-1 text-start" onClick={onCLick}>
        {text}
      </button>
    </div>
  )
}
