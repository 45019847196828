import type { FC } from 'react'
import type { IPersonNameBadgeGrouped } from './types'

import { useId } from 'react'
import { Typography } from 'antd'

import getInitials from '../lib/utils/getInitials'

import { GroupWrapper, PersonNameBadgeBase } from './styled'

const PersonNameBadgeGrouped: FC<IPersonNameBadgeGrouped> = ({
  persons,
  show = 2,
  className,
  align,
}) => {
  const componentId = useId()

  return (
    <GroupWrapper align={align || 'start'} className={className}>
      {persons
        ?.filter((_, index) => index < show)
        ?.map((person, key) => (
          <PersonNameBadgeBase
            key={person?.key || `person-name-${componentId}-${key}`}
            $grouped
            bg={person?.color}
          >
            {getInitials(person.name)}
          </PersonNameBadgeBase>
        ))}
      {persons?.length - Math.min(persons?.length, show) > 0 && (
        <Typography.Text strong style={{ fontSize: 11 }}>
          +{persons.length - Math.min(persons.length, show)}
        </Typography.Text>
      )}
    </GroupWrapper>
  )
}

export default PersonNameBadgeGrouped
