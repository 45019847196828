import type {FC} from "react";
import type {IStepItem} from "../@types/StepItem";

import {Tooltip, Typography} from "antd";
import clsx from "clsx";

export const StepItem: FC<IStepItem> = ({label, size, completed, disabled}) => {
  return (
    <Tooltip
      color="white"
      overlay={<Typography.Text className="text-blue-300 text-md">{label}</Typography.Text>}
      overlayInnerStyle={{
        padding: "5px",
      }}
      showArrow={false}
      title="Kick Off"
      trigger={["hover"]}
    >
      <div
        className={clsx("inline-block flex-1", {
          "bg-green-700": completed,
          "bg-gray-100": !completed,
          "h-6": size === "small",
          "h-8": size === "large",
          "hover:bg-green-700 transition-all duration-200 cursor-pointer": !disabled,
        })}
      />
    </Tooltip>
  );
};
