import type {FC} from "react";
import type {ITableGroup} from "../@types";

import {ITask} from "modules/tasks/@types/tasks";
import {IGroupTaskExtend} from "types/dto/IGroupTask";
import {TaskInterfaceKeys} from "modules/tasks/lib/tasks.keys";
import {PersonNameBadgeGrouped} from "shared/components/common/PersonNameBadge";
import TaskDates from "shared/components/common/TaskDates";
import {TaskTime} from "shared/components/common/TaskTime";
import ProjectStatus from "shared/components/common/ProjectStatus";
import {Table, Typography} from "antd";
import {CaretDownFilled as CaretDownIcon, CaretUpFilled as CaretUpIcon} from "@ant-design/icons";
import {useMemo} from "react";

import useTableGroup from "../lib/useTableGroup";
import {TableGroupProvider} from "../lib/context/TableGroup.context";
import {TableGroupType} from "../lib/TableGroup.keys";

import {TableHeadWrapper} from "./styled";

const HEAD_TYPE_CLASSES = {
  [TableGroupType.OVERDUE]: "text-red-500",
  [TableGroupType.TODAY]: "text-green-500",
  [TableGroupType.TOMORROW]: "text-blue-400",
  [TableGroupType.FUTURE]: "text-gray-400",
};

const GROUP_NAMES = {
  [TableGroupType.OVERDUE]: "Overdue",
  [TableGroupType.TODAY]: "Hoy",
  [TableGroupType.TOMORROW]: "Mañana",
  [TableGroupType.FUTURE]: "Futuro",
};

const TableGroup: FC<ITableGroup> = ({type}) => {
  const values = useTableGroup({type});

  const onCellGroup = (record: IGroupTaskExtend | ITask) => ({
    colSpan: "children" in record ? 7 : 1,
  });

  const sharedOnCell = (record: IGroupTaskExtend | ITask) => {
    if ("children" in record)
      return {
        colSpan: 0,
      };

    return {};
  };

  return (
    <TableGroupProvider values={values}>
      <Table
        bordered
        columns={[
          {
            title: (
              <TableHeadWrapper>
                <div className="flex justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <button /* onClick={toggleCollapse} */>
                      {false ? <CaretDownIcon /> : <CaretUpIcon />}
                    </button>
                    <Typography.Text className={HEAD_TYPE_CLASSES[type]}>
                      {GROUP_NAMES[type]}
                    </Typography.Text>
                  </div>
                  <Typography.Text className="ml-2">Tarea</Typography.Text>
                </div>
              </TableHeadWrapper>
            ),
            key: "name",
            render: (record) => {
              return record?.name ?? record?.title;
            },
            onCell: onCellGroup,
          },
          {
            title: "Proyecto",
            dataIndex: TaskInterfaceKeys.PROJECT_NAME,
            key: TaskInterfaceKeys.PROJECT_NAME,
            onCell: sharedOnCell,
          },
          {
            title: "Encargados",
            dataIndex: TaskInterfaceKeys.ASSIGNED,
            onCell: sharedOnCell,
            render: (assigned) => {
              return <PersonNameBadgeGrouped align="center" persons={assigned} />;
            },
          },
          {
            title: "Fechas",
            onCell: sharedOnCell,
            render: () => (
              <div className="flex items-center justify-center">
                <TaskDates />
              </div>
            ),
          },
          {
            title: "Tiempo Registrado",
            onCell: sharedOnCell,
            render: () => {
              return (
                <div className="flex items-center justify-center">
                  <TaskTime type="tracked" />
                </div>
              );
            },
          },
          {
            title: "Tiempo Estimado",
            onCell: sharedOnCell,
            render: () => {
              return (
                <div className="flex items-center justify-center">
                  <TaskTime type="estimated" />
                </div>
              );
            },
          },
          {
            title: "Estado",
            onCell: sharedOnCell,
            render: () => {
              return (
                <div className="flex items-center justify-center">
                  <ProjectStatus />
                </div>
              );
            },
          },
        ]}
        dataSource={[]}
        expandable={{
          defaultExpandAllRows: true,
        }}
        pagination={false}
      />
    </TableGroupProvider>
  );
};

export default TableGroup;
