import type {IUIState} from "redux/@types/ui";
import type {MenuProps} from "antd";

import {useState, useEffect, useCallback} from "react";
import {uiSetSubmenuCollapsed, uiSetSubmenuItems} from "redux/actions/ui.actions";
import {selectUiSubmenuItems} from "redux/selectors/ui.selectors";
import useAppDispatch from "shared/hooks/redux/useAppDispatch";
import useAppSelector from "shared/hooks/redux/useAppSelector";
import menuIcons from "shared/configs/menu-icons";
import {useLocation, useNavigate} from "react-router-dom";
import MENU, {MainMenuKeys, SubmenuKeys} from "shared/configs/menu";
import useLocalStorage from "shared/hooks/useLocalStorage";

export const useAuthLayout = () => {
  const [submenuItems, setSubmenuItems] = useState<MenuProps["items"]>([]);
  const [mainMenuSelected, setMainMenuSelected] = useState<MainMenuKeys>();
  const [submenuSelected, setSubmenuSelected] = useState<SubmenuKeys>();
  const [crumbs, setCrumbs] = useState<string[]>([]);
  const [submenuCollapsed, setSubmenuCollapsed] = useLocalStorage("layoutSubMenuCollapsed", false);

  // const submenuCollapsed = useAppSelector(selectUiSubmenuCollapsed())
  const uiConfigSubmenu = useAppSelector(selectUiSubmenuItems());

  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const handleToggleSubmenu = useCallback(() => {
    const newValue = !submenuCollapsed;

    setSubmenuCollapsed(newValue);
    dispatch(uiSetSubmenuCollapsed(newValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submenuCollapsed, dispatch]);

  const handleSetSubmenu = useCallback(
    (submenu: IUIState["submenuItems"]) => {
      dispatch(uiSetSubmenuItems(submenu));
    },
    [dispatch],
  );

  const crumbsReducer = () => {
    if (mainMenuSelected && submenuSelected) {
      const mainMenu = MENU[mainMenuSelected];
      const subMenu = mainMenu.submenu;

      setCrumbs([mainMenu.title, subMenu?.find((it) => it.key === submenuSelected)?.label || ""]);
    }
  };

  const menuReducer = useCallback(() => {
    if (!mainMenuSelected) return;
    handleSetSubmenu(MENU[mainMenuSelected]?.submenu || []);
  }, [handleSetSubmenu, mainMenuSelected]);

  const submenuReducer = useCallback(() => {
    setSubmenuItems(
      uiConfigSubmenu.map((menu) => ({
        key: menu.key,
        label: menu.label,
        icon: menuIcons.sub[menu.icon],
        onClick: () => navigate(menu.key),
      })),
    );
  }, [uiConfigSubmenu, navigate]);

  const routerMenuReducer = useCallback(() => {
    const [main] = location.pathname
      .split("/")
      .filter((it) => !!it)
      .map((it) => `/${it}`);

    setMainMenuSelected((main as MainMenuKeys) || MainMenuKeys.PROJECTS);
    setSubmenuSelected(
      location.pathname === "/" ? SubmenuKeys.PROJECTS : (location.pathname as SubmenuKeys),
    );
  }, [location.pathname]);

  const handleClickMainMenuItem: MenuProps["onClick"] = ({key}) => {
    navigate(key);
  };

  useEffect(() => {
    submenuReducer();
  }, [uiConfigSubmenu, submenuReducer]);

  useEffect(() => {
    routerMenuReducer();
  }, [location.pathname, routerMenuReducer]);

  useEffect(() => {
    if (mainMenuSelected) menuReducer();
  }, [mainMenuSelected, menuReducer]);

  useEffect(() => {
    crumbsReducer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenuSelected, submenuSelected]);

  return {
    submenuCollapsed,
    mainMenu: Object.values(MENU),
    submenuItems,
    mainMenuSelected,
    submenuSelected,
    crumbs,
    handleToggleSubmenu,
    handleClickMainMenuItem,
  };
};
