import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'
import { NavbarModule } from 'shared/components/common/Globals'

import useTemplates from '../lib/useTemplates'

import { ModalTemplate } from './ModalTemplate'
import { TemplatesEmpty } from './TemplatesEmpty'
import { TemplatesList } from './TemplatesList'

export const Templates: FC = () => {
  const { templates, showModalTemplate, handleModalTemplate, handleCreateTemplate } = useTemplates()

  return (
    <div className="h-full flex flex-col">
      <ModalTemplate
        open={showModalTemplate}
        onClose={() => handleModalTemplate(false)}
        onCreate={handleCreateTemplate}
      />
      <NavbarModule>
        <AppButton $success type="primary" onClick={() => handleModalTemplate(true)}>
          + Plantilla
        </AppButton>
      </NavbarModule>
      {templates.length ? (
        <TemplatesList templates={templates} />
      ) : (
        <TemplatesEmpty handleAddTemplate={() => handleModalTemplate(true)} />
      )}
    </div>
  )
}
