import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const RacingFlag: FC<IBaseIconProps> = ({ color, size }) => {
  return (
    <svg height={size} viewBox="0 0 14.709 19.947" width={size} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 0.496)">
        <g data-name="Group 18" id="Group_18">
          <path
            d="M61.859,65.92a.6.6,0,1,0-1.16.294l3.909,15.424a.6.6,0,0,0,1.16-.294Z"
            data-name="Path 8"
            fill={color}
            transform="translate(-60.68 -62.639)"
          />
          <path
            d="M112.612,7.625,110.7.568a.642.642,0,0,0-.645-.555c-3.583-.259-5.935,3.308-9.518,3.049-.283-.035-.464.112-.4.367q.956,3.529,1.912,7.057a.709.709,0,0,0,.645.555c3.583.26,5.935-3.308,9.518-3.049C112.5,8.027,112.681,7.88,112.612,7.625Z"
            data-name="Path 9"
            fill="none"
            stroke={color}
            strokeWidth="1"
            transform="translate(-98.422 0)"
          />
        </g>
      </g>
    </svg>
  )
}

export default RacingFlag
