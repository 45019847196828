import {useDroppable} from "@dnd-kit/core";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {useId, type FC} from "react";

import AppButton from "../../AppButton";
import {type IColumnDirective} from "../@types/IColumnDirective";
import {useKanbanContext} from "../lib/context/Kanban.context";
import useAddNew from "../lib/useAddNew";

import {ColumnWrapper} from "./ColumnWrapper";

export const ColumnDirective: FC<IColumnDirective> = ({
  keyField,
  title,
  allowAdd = false,
  renderHeader,
  renderAddForm = () => undefined,
}) => {
  const componentId = useId();

  const {isAddNew, confirm, cancel, handleAddNew} = useAddNew();

  const {setNodeRef} = useDroppable({
    id: `col-${keyField}`,
    data: {
      keyField,
    },
  });

  const {
    dataSource,
    idField,
    cardSettings: {render},
  } = useKanbanContext();

  return (
    <div className="w-[250px] flex-none">
      <SortableContext
        items={dataSource?.[keyField]?.map((it) => it?.id) ?? []}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef} className="flex flex-col gap-4 px-6 min-h-[500px]">
          <div>
            {renderHeader ? (
              renderHeader({
                title,
                totalItems: dataSource?.[keyField]?.length,
              })
            ) : (
              <>
                <h4 className="mb-0 text-2xl font-bold">{title}</h4>
                <span className="text-base">{dataSource?.[keyField]?.length} Tasks</span>
              </>
            )}
          </div>

          {dataSource?.[keyField]?.map((record) => {
            return (
              <ColumnWrapper
                key={`kanban-card-${record[idField]}-${componentId}`}
                idField={idField}
                record={record}
              >
                {render && render(record)}
              </ColumnWrapper>
            );
          })}
          {isAddNew &&
            renderAddForm({
              keyFieldValue: keyField,
              confirm,
              cancel,
            })}
          {allowAdd && (
            <div className="my-2">
              <AppButton $hovered block disabled={isAddNew} type="ghost" onClick={handleAddNew}>
                +
              </AppButton>
            </div>
          )}
        </div>
      </SortableContext>
    </div>
  );
};
