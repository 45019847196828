import type { ReactNode, FC } from 'react'
import type { IActivityContext } from '../../@types/IActivityContext'

import { createContext, useContext } from 'react'

import useActivityForm from '../useActivityForm'

const INITIAL_STATE: IActivityContext = {
  start_at: null,
  end_at: null,
  title: null,
  setState: () => {},
}

export const ActivityFormContext = createContext(INITIAL_STATE)

export const useActivityContext = () => useContext(ActivityFormContext)

export const ActivityFormProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const values = useActivityForm()

  return <ActivityFormContext.Provider value={values}>{children}</ActivityFormContext.Provider>
}
