import type {IAuthLayout} from "./types";
import type {FC} from "react";

import React from "react";
import {Layout} from "antd";
import {
  CaretLeftFilled as CaretLeftIcon,
  CaretRightFilled as CaretRightIcon,
} from "@ant-design/icons";

import {useAuthLayout} from "../lib";

import BreadCrumb from "./components/BreadCrumb";
import SearchInput from "./components/SearchInput";
import UserActions from "./components/UserActions";
import {Header, Menu, SiderMain, SiderSubmenu, SubMenu, SubmenuTrigger} from "./styled";
import SearchResultPopover from "./components/SearchResultPopover";
const {Content} = Layout;

const AuthLayout: FC<IAuthLayout> = ({children}) => {
  const {
    submenuCollapsed,
    mainMenu,
    submenuItems,
    mainMenuSelected,
    submenuSelected,
    crumbs,
    handleToggleSubmenu,
    handleClickMainMenuItem,
  } = useAuthLayout();

  return (
    <>
      <Layout hasSider>
        <SiderMain collapsed={true} collapsible={false} trigger={null}>
          <Menu
            items={mainMenu}
            mode="inline"
            selectedKeys={mainMenuSelected ? [mainMenuSelected] : []}
            onClick={handleClickMainMenuItem}
          />
        </SiderMain>
        <Layout>
          <Header>
            <BreadCrumb crumbs={crumbs} />
            <SearchResultPopover>
              <SearchInput />
            </SearchResultPopover>
            <UserActions />
          </Header>
          <SiderSubmenu collapsible collapsed={submenuCollapsed} trigger={null} width={260}>
            <SubMenu
              items={submenuItems ?? []}
              mode="inline"
              selectedKeys={submenuSelected ? [submenuSelected] : []}
            />
            <SubmenuTrigger
              icon={submenuCollapsed ? <CaretRightIcon /> : <CaretLeftIcon />}
              type="text"
              onClick={handleToggleSubmenu}
            />
          </SiderSubmenu>
          <Layout style={{marginTop: 65}}>
            <Content className="bg-white">{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default React.memo(AuthLayout);
