import type {IOrganizationExtended} from "types/dto/IOrganization";
import type {IOrganizationList} from "../@types/UseOrganization";

import {useEffect, useState} from "react";
import {organizationsService} from "shared/services";

const useOrganizations = () => {
  const [organizations, setOrganizations] = useState<IOrganizationList[]>([]);
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    skip: 0,
  });
  const [showModalOrganization, setShowModalOrganization] = useState(false);

  const getOrganizations = () => {
    organizationsService
      .find({
        query: {
          $limit: 50,
          $client: {
            organizationList: "true",
          },
        },
      })
      .then(({data, ...pag}) => {
        setOrganizations(data as unknown as IOrganizationList[]);
        setPagination(pag);
      });
  };

  const appendOrganization = (newOrganization: IOrganizationExtended) => {
    setOrganizations((prev) => prev.concat(newOrganization as unknown as IOrganizationList));
  };

  const subscribeEvents = () => {
    organizationsService.service.on("created", appendOrganization);
  };

  const unsubscribeEvents = () => {
    organizationsService.service.removeListener("created", appendOrganization);
  };

  const handleModalOrganization = (status: boolean) => {
    setShowModalOrganization(status);
  };

  useEffect(() => {
    getOrganizations();
    subscribeEvents();

    return () => {
      unsubscribeEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pagination,
    organizations,
    showModalOrganization,
    handleModalOrganization,
  };
};

export default useOrganizations;
