import {feathers} from "@feathersjs/feathers";
import socketIo from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";

const io = require("socket.io-client");

const socket = io("wss://wow-api2.e-me.co", {
  transports: ["websocket"],
});

export const app = feathers();

app.configure(socketIo(socket));
app.configure(
  authentication({
    path: "/authentication",
    storageKey: "jwtToken",
  }),
);
