import type {TreeData, TreeTasksListProps} from "../@types";
import type {UniqueIdentifier} from "@dnd-kit/core";
import type {TreeTasksListContextProps} from "../@types/context";

import _ from "lodash";
import React from "react";

import {TreeTasksListProvider} from "../context";

import {ListGroup} from "./styled";

export default function TreeTasksList<TTreeData extends TreeData = TreeData>({
  children,
  dataSource: data,
  indentationWidth = 39,
  idField,
  ...props
}: TreeTasksListProps<TTreeData>) {
  const [dataSource, setDataSource] = React.useState<Record<UniqueIdentifier, TTreeData[]>>({});
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = React.useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = React.useState(0);

  React.useEffect(() => {
    setDataSource(
      _(data)
        .groupBy(props.keyField)
        .map((items, key) => ({
          [key]: items,
        }))
        .reduce((acc, it) => ({
          ...acc,
          ...it,
        })) ?? {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <TreeTasksListProvider
      value={
        {
          dataSource,
          idField,
          indentationWidth,
          activeId,
          overId,
          offsetLeft,
          setActiveId,
          setDataSource,
          setOffsetLeft,
          setOverId,
          ...props,
        } as TreeTasksListContextProps
      }
    >
      <div>
        <ListGroup>{children}</ListGroup>
      </div>
    </TreeTasksListProvider>
  );
}
