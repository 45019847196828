import { ThemeConfig } from 'antd/es/config-provider/context'

export default <ThemeConfig>{
  token: {
    colorPrimary: '#220648',
    colorInfo: '#367ddf',
    colorSuccess: '#318549',
    fontFamily: "'Montserrat', sans-serif",
    colorError: '#ff00008a',
  },
}
