import type { FC } from 'react'

import NotesIcon from 'shared/assets/icons/Notes'
import AppButton from 'shared/components/common/AppButton'
import UserGroupIcon from 'shared/assets/icons/userGroup'
import EmailIcon from 'shared/assets/icons/Email'
import clsx from 'clsx'
import ClipIcon from 'shared/assets/icons/Clip'
// import DocumentListIcon from 'shared/assets/icons/DocumentList'

import useExtraTabs from '../lib/useExtraTabs'
import { ExtraTabsViewKeys } from '../@types/useExtraTabs'

import { Notes } from './Notes'
import { Activities } from './Activities'
import { Email } from './Email'
import { Files } from './Files'

const TAB_VIEWS = {
  [ExtraTabsViewKeys.NOTES]: <Notes />,
  [ExtraTabsViewKeys.ACTIVITIES]: <Activities />,
  [ExtraTabsViewKeys.EMAIL]: <Email />,
  [ExtraTabsViewKeys.FILES]: <Files />,
  // [ExtraTabsViewKeys.DOCUMENTS]: <div>Documents</div>,
}

export const ExtraTabs: FC = () => {
  const { currentTab, handleChangeView } = useExtraTabs()

  return (
    <div>
      <div className="border border-b-0 border-gray-200 rounded-t-md p-5 bg-white">
        <div className="flex items-center gap-4">
          <AppButton
            $info
            $hovered={false}
            className="font-semibold"
            icon={<NotesIcon className="w-6 h-6 mr-2" />}
            type={currentTab === ExtraTabsViewKeys.NOTES ? 'primary' : 'text'}
            onClick={() => handleChangeView(ExtraTabsViewKeys.NOTES)}
          >
            Notas
          </AppButton>
          <AppButton
            $info
            $hovered={false}
            className="font-semibold"
            icon={<UserGroupIcon className="w-8 h-8 mr-2" />}
            type={currentTab === ExtraTabsViewKeys.ACTIVITIES ? 'primary' : 'text'}
            onClick={() => handleChangeView(ExtraTabsViewKeys.ACTIVITIES)}
          >
            Actividad
          </AppButton>
          <AppButton
            $info
            $hovered={false}
            className="font-semibold"
            icon={
              <EmailIcon
                className={clsx('w-8 h-8 mr-2 stroke-black', {
                  'stroke-[#367ddf]': currentTab === ExtraTabsViewKeys.EMAIL,
                })}
              />
            }
            type={currentTab === ExtraTabsViewKeys.EMAIL ? 'primary' : 'text'}
            onClick={() => handleChangeView(ExtraTabsViewKeys.EMAIL)}
          >
            Email
          </AppButton>
          <AppButton
            $info
            $hovered={false}
            className="font-semibold"
            icon={<ClipIcon className="w-8 h-8 mr-2" />}
            type={currentTab === ExtraTabsViewKeys.FILES ? 'primary' : 'text'}
            onClick={() => handleChangeView(ExtraTabsViewKeys.FILES)}
          >
            Archivos
          </AppButton>
          {/* <AppButton
            $info
            $hovered={false}
            className="font-semibold"
            icon={<DocumentListIcon className="w-7 h-7 mr-2" />}
            type={currentTab === ExtraTabsViewKeys.DOCUMENTS ? 'primary' : 'text'}
            onClick={() => handleChangeView(ExtraTabsViewKeys.DOCUMENTS)}
          >
            Documentos
          </AppButton> */}
        </div>
      </div>
      <div>{TAB_VIEWS[currentTab]}</div>
    </div>
  )
}
