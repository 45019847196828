import { useController } from 'react-hook-form'

import { IUseColorPickerProps } from '../@types/useColorPicker'

const useColorPicker = ({ control, name }: IUseColorPickerProps) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  })

  const handleClickPreset = (color: string) => {
    onChange(color)
  }

  return {
    value,
    handleClickPreset,
  }
}

export default useColorPicker
