import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProjectTabsKeys } from './ProjectTabs.keys'

const useProjectTabs = () => {
  const [currentTab, setCurrentTab] = useState<ProjectTabsKeys>()

  const navigate = useNavigate()
  const location = useLocation()

  const handleChangeTab = (tab: ProjectTabsKeys) => {
    if (tab === currentTab) return
    const search = new URLSearchParams()

    search.set('tab', tab)

    navigate({
      search: search.toString(),
    })
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const tab = <ProjectTabsKeys | undefined>search.get('tab')

    if (!tab) {
      search.set('tab', ProjectTabsKeys.PLAN)
      navigate({
        search: search.toString(),
      })
    } else {
      setCurrentTab(tab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return {
    currentTab,
    handleChangeTab,
  }
}

export default useProjectTabs
