import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const UserGroupIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    // <svg
    //   className={className}
    //   height={size}
    //   viewBox="0 0 20.79 14"
    //   width={size}
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g data-name="Group 319" transform="translate(-841 -495.778)">
    //     <g data-name="Group 61" transform="translate(-823 354.219)">
    //       <g
    //         data-name="Rectangle 73"
    //         fill={color}
    //         stroke={color}
    //         strokeWidth="1"
    //         transform="translate(1664 149.664)"
    //       >
    //         <rect height="5.895" rx="2.947" stroke="none" width="12.526" />
    //         <rect fill="none" height="4.895" rx="2.447" width="11.526" x="0.5" y="0.5" />
    //       </g>
    //       <g
    //         data-name="Ellipse 9"
    //         fill={color}
    //         id="Ellipse_9"
    //         stroke={color}
    //         strokeWidth="1"
    //         transform="translate(1666.947 141.559)"
    //       >
    //         <circle cx="3.684" cy="3.684" r="3.684" stroke="none" />
    //         <circle cx="3.684" cy="3.684" fill="none" r="3.184" />
    //       </g>
    //     </g>
    //     <g data-name="Group 62" id="Group_62" transform="translate(-814.736 354.219)">
    //       <g
    //         data-name="Rectangle 73"
    //         fill="#fff"
    //         id="Rectangle_73-2"
    //         stroke={color}
    //         strokeWidth="1"
    //         transform="translate(1664 149.664)"
    //       >
    //         <rect height="5.895" rx="2.947" stroke="none" width="12.526" />
    //         <rect fill="none" height="4.895" rx="2.447" width="11.526" x="0.5" y="0.5" />
    //       </g>
    //       <g
    //         data-name="Ellipse 9"
    //         fill="#fff"
    //         id="Ellipse_9-2"
    //         stroke={color}
    //         strokeWidth="1"
    //         transform="translate(1666.947 141.559)"
    //       >
    //         <circle cx="3.684" cy="3.684" r="3.684" stroke="none" />
    //         <circle cx="3.684" cy="3.684" fill="none" r="3.184" />
    //       </g>
    //     </g>
    //   </g>
    // </svg>
    <svg
      className={className}
      height={size}
      viewBox="0 0 27.211 18.325"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 171" transform="translate(-786.789 -309.615)">
        <g data-name="Group 61" transform="translate(786.789 309.615)">
          <g
            data-name="Rectangle 73"
            id="Rectangle_73"
            stroke={color}
            strokeWidth="1"
            transform="translate(0 10.609)"
          >
            <rect height="7.716" rx="3.858" stroke="none" width="16.395" />
            <rect fill="none" height="6.716" rx="3.358" width="15.395" x="0.5" y="0.5" />
          </g>
          <g
            data-name="Ellipse 9"
            id="Ellipse_9"
            stroke={color}
            strokeWidth="1"
            transform="translate(3.858 0)"
          >
            <ellipse cx="4.822" cy="4.822" rx="4.822" ry="4.822" stroke="none" />
            <ellipse cx="4.822" cy="4.822" fill="none" rx="4.322" ry="4.322" />
          </g>
        </g>
        <g data-name="Group 62" transform="translate(797.605 309.615)">
          <g
            data-name="Rectangle 73"
            fill="none"
            id="Rectangle_73-2"
            stroke="#000"
            strokeWidth="1"
            transform="translate(0 10.609)"
          >
            <rect height="7.716" rx="3.858" stroke="none" width="16.395" />
            <rect fill="none" height="6.716" rx="3.358" width="15.395" x="0.5" y="0.5" />
          </g>
          <g
            data-name="Ellipse 9"
            fill="none"
            id="Ellipse_9-2"
            stroke="#000"
            strokeWidth="1"
            transform="translate(3.858 0)"
          >
            <ellipse cx="4.822" cy="4.822" rx="4.822" ry="4.822" stroke="none" />
            <ellipse cx="4.822" cy="4.822" fill="none" rx="4.322" ry="4.322" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UserGroupIcon
