import type {SectionDirectiveProps} from "../@types/SectionDirective";
import type {TreeData} from "../@types";

import React from "react";
import {UniqueIdentifier, useDroppable} from "@dnd-kit/core";
import {DownOutlined} from "@ant-design/icons";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";

import {useTreeTasksListContext} from "../context";
import {
  findContainer,
  flattenTree,
  getProjection,
  removeChildrenOf,
  setProperty,
} from "../lib/utilities";

import {
  TaskList,
  TaskListHeader,
  TaskListHeaderActionIconArrow,
  TaskListHeaderActions,
  TaskListHeaderField,
  TaskListHeaderFieldItem,
  TaskListHeaderItemLabel,
  TaskListHeaderItemMain,
  TaskListHeaderItems,
  TaskListItemLabelText,
  TaskListRows,
  TaskRow,
} from "./styled";
import SortableTreeItem from "./SortableTreeItem";

export default function SectionDirective({
  keyField,
  title,
  allowAdd = false,
  containerKeys = [],
}: SectionDirectiveProps) {
  const [items, setItems] = React.useState([] as TreeData[]);

  const {setNodeRef} = useDroppable({
    id: `section-${keyField}`,
    data: {
      keyField,
    },
  });

  const {
    dataSource,
    idField = "id",
    columns,
    indentationWidth = 39,
    activeId,
    overId,
    offsetLeft,
  } = useTreeTasksListContext();

  const flattenedItems = React.useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce(
      (acc: number[], {children, collapsed, id}) =>
        collapsed && children?.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [items, activeId]);

  const sortedIds = React.useMemo(() => {
    return flattenedItems.map((item) => item[idField]);
  }, [flattenedItems, idField]);

  const projected = React.useMemo(() => {
    if (activeId && overId && dataSource) {
      const overContainer = findContainer(overId, idField, dataSource, containerKeys);
      const activeContainer = findContainer(activeId, idField, dataSource, containerKeys);

      if (!overContainer || !activeContainer) return null;

      return getProjection(
        dataSource,
        activeId,
        overId,
        offsetLeft,
        indentationWidth,
        idField,
        overContainer,
        activeContainer,
      );
    }

    return null;
  }, [activeId, overId, indentationWidth, offsetLeft, idField, dataSource, containerKeys]);

  const handleCollapse = (id: UniqueIdentifier) => {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      }),
    );
  };

  React.useEffect(() => {
    setItems(dataSource?.[keyField] || []);
  }, [dataSource, keyField]);

  return (
    <SortableContext items={sortedIds ?? []} strategy={verticalListSortingStrategy}>
      <TaskList>
        <TaskListHeader className="scrollbar-hide">
          <TaskListHeaderActions>
            <TaskListHeaderActionIconArrow>
              <DownOutlined />
            </TaskListHeaderActionIconArrow>
          </TaskListHeaderActions>

          <TaskListHeaderItemMain>
            <TaskListHeaderItemLabel>
              <TaskListItemLabelText>{title}</TaskListItemLabelText>
            </TaskListHeaderItemLabel>
            <TaskListHeaderField>
              <TaskListHeaderFieldItem>
                {dataSource?.[keyField]?.length} Tarea
                {dataSource?.[keyField]?.length > 1 ? "s" : ""}
              </TaskListHeaderFieldItem>
            </TaskListHeaderField>
          </TaskListHeaderItemMain>

          <TaskListHeaderItems>
            {columns?.map((column, colIndex) => (
              <TaskListHeaderField
                key={`row-section-${keyField}-col-${colIndex}`}
                style={{width: column?.width || 160}}
              >
                <TaskListHeaderFieldItem>{column.title}</TaskListHeaderFieldItem>
              </TaskListHeaderField>
            ))}
          </TaskListHeaderItems>
        </TaskListHeader>

        <TaskListRows ref={setNodeRef} id={`droppable-section-${keyField}`}>
          {flattenedItems?.map((data, dataIndex) => (
            <TaskRow key={`row-section-${keyField}-data-${dataIndex}`}>
              <SortableTreeItem
                columns={columns}
                data={data as any}
                depth={data[idField] === activeId && projected ? projected.depth : data.depth}
                indentationWidth={indentationWidth}
                onCollapse={
                  data?.children?.length ? () => handleCollapse(data[idField]) : undefined
                }
              />
            </TaskRow>
          ))}
        </TaskListRows>
      </TaskList>
    </SortableContext>
  );
}
