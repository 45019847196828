import type {IPerson} from "types/dto/IPerson";
import type {IOrganization} from "types/dto/IOrganization";
import type {ILabel} from "types/dto/ILabel";
import type {IUser} from "types/dto/IUser";
import type {ILabelRelation} from "types/dto/ILabelRelation";
import type {ICompany} from "types/dto/ICompany";
import type {ICompanyUser} from "types/dto/ICompanyUser";
import type {IOrganizationPerson} from "types/dto/IOrganizationPerson";
import type {IProjectBoard} from "types/dto/IProjectBoard";
import type {IProjectPhase} from "types/dto/IProjectPhase";
import type {IDeal} from "types/dto/IDeal";
import type {IProject} from "types/dto/IProject";
import type {ITask} from "types/dto/ITask";
import type {ITaskStatus} from "types/dto/ITaskStatus";

import {ITaskComment} from "types/dto/ITaskComment";

import Service from "./Service.class";
import {app} from "./app";
import AuthenticationService from "./authentication.service";

export const authenticationService = new AuthenticationService(app);
export const organizationsService = new Service<IOrganization>("organizations", app);
export const personsService = new Service<IPerson>("persons", app);
export const labelsService = new Service<ILabel>("labels", app);
export const labelRelationsService = new Service<ILabelRelation>("label-relations", app);
export const usersService = new Service<IUser>("users", app);
export const companiesService = new Service<ICompany>("companies", app);
export const companyUsersService = new Service<ICompanyUser>("company-users", app);
export const organizationPersonsService = new Service<IOrganizationPerson>(
  "organization-persons",
  app,
);
export const projectBoardsService = new Service<IProjectBoard>("project-boards", app);
export const projectPhasesService = new Service<IProjectPhase>("project-phases", app);
export const dealsService = new Service<IDeal>("deals", app);
export const projectsService = new Service<IProject>("projects", app);
export const tasksService = new Service<ITask>("tasks", app);
export const taskStatusService = new Service<ITaskStatus>("task-status", app);
export const taskCommentsService = new Service<ITaskComment>("task-comments", app);
