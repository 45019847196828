import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const TemplatesIcon: FC<IBaseIconProps> = ({ size, color }) => {
  return (
    <svg
      fill={color}
      height={size || 30}
      viewBox="0 0 22 26.529"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copy" transform="translate(-43.5 0.5)">
        <path
          d="M59.625,80H44.965a.966.966,0,0,0-.965.965v19.482a.966.966,0,0,0,.965.965h14.66a.966.966,0,0,0,.965-.965V80.965A.966.966,0,0,0,59.625,80ZM56.353,93.588H48.529a.824.824,0,0,1,0-1.647h7.824a.824.824,0,1,1,0,1.647Zm0-3.294H48.529a.824.824,0,1,1,0-1.647h7.824a.824.824,0,1,1,0,1.647Zm0-3.294H48.529a.824.824,0,1,1,0-1.647h7.824a.824.824,0,1,1,0,1.647Z"
          data-name="Path 14"
          strokeWidth="1"
          transform="translate(0 -75.882)"
        />
        <path
          d="M115.927,2.471V20.588a2.559,2.559,0,0,1-2.763,2.471V5.083a2.615,2.615,0,0,0-2.612-2.612H97.69A2.55,2.55,0,0,1,100.307,0h13A2.55,2.55,0,0,1,115.927,2.471Z"
          data-name="Path 15"
          strokeWidth="1"
          transform="translate(-50.927)"
        />
      </g>
    </svg>
  )
}

export default TemplatesIcon
