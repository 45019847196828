import type { FC } from 'react'

import { GridCardBacklog } from '../../GridCard'

export const BacklogGrids: FC = () => {
  return (
    <>
      <GridCardBacklog title="Kick Off" />
      <GridCardBacklog title="Montaje Web" />
      <GridCardBacklog title="Revisión" />
    </>
  )
}
