import type {FC} from "react";
import type {ITableGroup} from "../@types";

import clsx from "clsx";

import {TableGroupProvider} from "../lib/context/TableGroup.context";
import {TableGroupType} from "../lib/TableGroup.keys";
import useTableGroup from "../lib/useTableGroup";

import TableHead from "./TableHead";
import TableBody from "./TableBody";

const GROUP_NAMES = {
  [TableGroupType.OVERDUE]: "Overdue",
  [TableGroupType.TODAY]: "Hoy",
  [TableGroupType.TOMORROW]: "Mañana",
  [TableGroupType.FUTURE]: "Futuro",
};

const TableGroup: FC<ITableGroup> = ({type}) => {
  const values = useTableGroup({type});

  return (
    <TableGroupProvider values={values}>
      <table className="w-full">
        <thead>
          <TableHead name={GROUP_NAMES[type]} />
        </thead>
        <tbody
          className={clsx({
            hidden: values.collapsed,
          })}
        >
          <TableBody />
        </tbody>
      </table>
    </TableGroupProvider>
  );
};

export default TableGroup;
