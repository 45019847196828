import type {DefaultOptionType} from "antd/es/select";
import type {IUseLabelsFetcherProps} from "../@types";
import type {ILabel} from "types/dto/ILabel";
import type {FieldValues} from "react-hook-form";

import {debounce} from "lodash";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useController} from "react-hook-form";
import {labelsService} from "shared/services";

const DEBOUNCE_TIMEOUT = 800;

function useLabelsFetcher<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  entities,
}: IUseLabelsFetcherProps<TFieldValues>) {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [labels, setLabels] = useState<ILabel[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>([]);
  const [fetching, setFetching] = useState(false);

  const fetchRef = useRef(0);

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const selectedValues = useMemo(() => {
    return (value ?? []).flatMap((labelId: number) => {
      const findLabel = selectedLabels.find(({id}) => id === labelId);

      if (!findLabel) return [];

      return [
        {
          label: findLabel!.name,
          value: labelId,
        },
      ];
    });
  }, [value, selectedLabels]);

  const fetchLabels = useCallback(
    (search?: string) => {
      return labelsService.find({
        query: {
          ...(entities.length
            ? {
                entity: {
                  $in: entities,
                },
              }
            : {}),
          ...(search
            ? {
                $client: {
                  searchValue: search,
                },
              }
            : {}),
        },
      });
    },
    [entities],
  );

  const fetchInitialLabels = async () => {
    setFetching(true);
    await fetchLabels()
      .then(({data}) => {
        setLabels(data);
        setOptions(
          data.map(({id, name}) => ({
            label: name,
            value: id,
          })),
        );
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      setOptions([]);
      setLabels([]);
      setFetching(true);

      fetchLabels(value).then(({data}) => {
        if (fetchId !== fetchRef.current) return;

        setOptions(
          data.map(({id, name}) => ({
            label: name,
            value: id,
          })),
        );
        setLabels(data);
        setFetching(false);
      });
    };

    return debounce(loadOptions, DEBOUNCE_TIMEOUT);
  }, [fetchLabels]);

  const getTagProps = (labelId: number) => {
    const label =
      labels.find(({id}) => id === labelId) ?? selectedLabels.find(({id}) => id === labelId);

    return {
      bgColor: label?.color_background,
      fontColor: label?.label_color_text,
    };
  };

  const handleChange = (values: Array<{label: string; value: number}>) => {
    onChange(values.map(({value}) => value));
    setSelectedLabels((prev) => {
      return prev.concat(
        values
          .filter(({value}) => prev.findIndex((it) => it.id === value) === -1)
          .map(({value}) => labels.find((it) => it.id === value)!),
      );
    });
    fetchInitialLabels();
  };

  const onCreateLabel = (label: ILabel) => {
    onChange((value ?? []).concat(label.id as never));
    setLabels((prev) => prev.concat(label));
    setOptions((prev) => prev.concat({label: label.name, value: label.id}));
  };

  useEffect(() => {
    fetchInitialLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    labels,
    values: selectedValues,
    options,
    fetching,
    getTagProps,
    handleChange,
    onCreateLabel,
    debounceFetcher,
  };
}

export default useLabelsFetcher;
