import moment from 'moment'
import { useForm as useReactHookForm } from 'react-hook-form'
import { ICreateActivity } from 'types/dto/IActivity'
import { useEffect, useState } from 'react'

import { useActivityContext } from './context/ActivityForm.context'

const useForm = () => {
  const [extraCollapsed, setExtraCollapsed] = useState(true)
  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    getValues,
    watch,
    reset,
  } = useReactHookForm<ICreateActivity>({
    defaultValues: {
      icon: 'phone',
      start_at: moment().toDate(),
      end_at: moment().add(1, 'hours').toDate(),
    },
  })

  const { setState, ...contextData } = useActivityContext()

  const startAt = watch('start_at')
  const endAt = watch('end_at')
  const title = watch('title')
  const icon = watch('icon')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = onSubmit((data) => {
    // TODO
  })

  const toggleCollapsed = (status: boolean) => {
    setExtraCollapsed(status)
  }

  useEffect(() => {
    if (contextData.title !== title) {
      setState((prev) => ({
        ...prev,
        title,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  useEffect(() => {
    const { start_at, end_at } = contextData

    if (
      moment(startAt).diff(moment(start_at), 'minutes') !== 0 ||
      moment(endAt).diff(moment(end_at), 'minutes') !== 0
    ) {
      setState((prev) => ({
        ...prev,
        ...(startAt ? { start_at: moment(startAt).toDate() } : {}),
        ...(endAt
          ? { end_at: moment(endAt).toDate() }
          : { end_at: startAt ? moment(startAt).add(1, 'hours').toDate() : null }),
      }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt, endAt, title])

  useEffect(() => {
    const { start_at, end_at } = contextData

    if (
      moment(start_at).diff(moment(startAt), 'minutes') !== 0 ||
      moment(end_at).diff(moment(endAt), 'minutes') !== 0
    ) {
      reset({
        ...getValues(),
        start_at: contextData.start_at!,
        end_at: contextData.end_at!,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextData.start_at, contextData.end_at])

  return {
    control,
    extraCollapsed,
    icon,
    setValue,
    handleSubmit,
    toggleCollapsed,
  }
}

export default useForm
