import { FC /* , useMemo */ } from 'react'
// import { GridTable } from 'shared/components/common/GridTable'
// import { IGridColumns } from 'shared/components/common/GridTable/@types/GridTable'
// import { ITask, TaskInterfaceKeys } from 'types/dto/ITask'
// import fakeTasksData from 'shared/mocks/fake-tasks'
// import { PersonNameBadgeGrouped } from 'shared/components/common/PersonNameBadge'
// import TaskDates from 'shared/components/common/TaskDates/ui'
// import { TaskTime } from 'shared/components/common/TaskTime'
// import ProjectStatus from 'shared/components/common/ProjectStatus/ui/ProjectStatus'
// import ListIcon from 'shared/assets/icons/list'

import { TableGroupType } from '../lib/TableGroup.keys'

import TableGroup from './TableGroup'

const Date: FC = () => {
  // const tasks = useMemo(() => fakeTasksData(), [])
  // const columns = useMemo<IGridColumns<ITask>[]>(
  //   () => [
  //     {
  //       title: 'Tarea',
  //       dataIndex: TaskInterfaceKeys.TITLE,
  //       className: 'p-6',
  //       render: (title) => (
  //         <>
  //           <ListIcon /> {title}
  //         </>
  //       ),
  //     },
  //     {
  //       title: 'Proyecto',
  //       dataIndex: TaskInterfaceKeys.PROJECT_NAME,
  //       className: 'p-6',
  //     },
  //     {
  //       title: 'Encargados',
  //       dataIndex: TaskInterfaceKeys.ASSIGNED,
  //       className: 'p-6',
  //       render: (record) => <PersonNameBadgeGrouped align="center" persons={record} />,
  //     },
  //     {
  //       title: 'Fechas',
  //       className: 'p-6',
  //       render: () => (
  //         <div className="flex items-center justify-center">
  //           <TaskDates />
  //         </div>
  //       ),
  //     },
  //     {
  //       title: 'Tiempo registrado',
  //       className: 'p-6',
  //       render: () => (
  //         <div className="flex items-center justify-center">
  //           <TaskTime type="tracked" />
  //         </div>
  //       ),
  //     },
  //     {
  //       title: 'Tiempo estimado',
  //       className: 'p-6',
  //       render: () => (
  //         <div className="flex items-center justify-center">
  //           <TaskTime type="estimated" />
  //         </div>
  //       ),
  //     },
  //     {
  //       title: 'Estado',
  //       className: 'p-6',
  //       render: () => (
  //         <div className="flex items-center justify-center">
  //           <ProjectStatus />
  //         </div>
  //       ),
  //     },
  //   ],
  //   [],
  // )

  return (
    <div className="overflow-x-auto">
      {/* <GridTable collapsible columns={columns} dataSource={tasks} headerClassName="p-6" /> */}
      <TableGroup type={TableGroupType.OVERDUE} />
      <TableGroup type={TableGroupType.TODAY} />
      <TableGroup type={TableGroupType.TOMORROW} />
      <TableGroup type={TableGroupType.FUTURE} />
    </div>
  )
}

export default Date
