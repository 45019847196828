import type {FC} from "react";

import {CloseOutlined, LeftOutlined} from "@ant-design/icons";
import useAppSelector from "shared/hooks/redux/useAppSelector";
import {selectUiTaskModal} from "redux/selectors/ui.selectors";
import {Button} from "antd";
import useAppDispatch from "shared/hooks/redux/useAppDispatch";
import {uiSetHideTaskModal, uiSetShowTaskModal} from "redux/actions/ui.actions";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import React from "react";

import {ModalTaskContext, ModalTaskProvider} from "../context";

import {CustomModal, Header} from "./styles/ModalTask.styled";
import {TaskForm} from "./TaskForm";
import {TaskComments} from "./TaskCommets";

export const ModalTask: FC = () => {
  const {show} = useAppSelector(selectUiTaskModal());

  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch("/projects/:id/task/:taskId");

  React.useEffect(() => {
    if (match?.params.taskId) {
      dispatch(uiSetShowTaskModal(Number(match.params.taskId)));
    } else {
      dispatch(uiSetHideTaskModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <CustomModal
      centered
      destroyOnClose
      closable={false}
      footer={false}
      open={show}
      title={null}
      width={1400}
    >
      <ModalTaskProvider>
        <Header className="text-md">
          <ModalTaskContext.Consumer>
            {({task}) => (
              <div className="flex items-center gap-2">
                <LeftOutlined />
                {task?.task_parent?.name || task?.project?.name}
              </div>
            )}
          </ModalTaskContext.Consumer>
          <Button
            className="text-white flex items-center justify-center"
            icon={<CloseOutlined />}
            type="text"
            onClick={() => {
              if (match?.pathname) {
                navigate(`${match.pathname.split("/task")[0]}${location.search}`);
              }
            }}
          />
        </Header>
        <div className="flex divide-x divide-slate-200" style={{height: "calc(100% - 26px)"}}>
          <div className="flex-1">
            <TaskForm />
          </div>
          <div className="w-[45%]">
            <TaskComments />
          </div>
        </div>
      </ModalTaskProvider>
    </CustomModal>
  );
};
