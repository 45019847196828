import type {FC, ReactNode} from "react";

import {MailOutlined, PhoneFilled, UserOutlined} from "@ant-design/icons";

import useLogin, {LoginStrategies} from "../lib/useLogin";

import {LoginStrategy} from "./LoginStrategy";
import {SMSStrategy} from "./SMSStrategy";
import {EmailStrategy} from "./EmailStrategy";
import {LocalStrategy} from "./LocalStrategy";
import {StrategyBase} from "./StrategyBase";

const Strategies: Record<LoginStrategies, ReactNode> = {
  [LoginStrategies.SMS]: <SMSStrategy />,
  [LoginStrategies.EMAIL]: <EmailStrategy />,
  [LoginStrategies.LOCAL]: <LocalStrategy />,
};

export const Login: FC = () => {
  const {strategy, handleClickStrategy} = useLogin();

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="max-w-[380px]">
        <div className="px-4py-5">
          <h1 className="font-bold text-4xl text-center mb-10 animate-fade-top">
            Bienvenido de nuevo
          </h1>
          <div className="mt-3">
            {!strategy ? (
              <div className="animate-fade-left flex flex-col gap-4">
                <LoginStrategy
                  icon={<PhoneFilled className="text-green-500" />}
                  text="Continua con tu teléfono"
                  onCLick={() => handleClickStrategy(LoginStrategies.SMS)}
                />
                <LoginStrategy
                  icon={<MailOutlined className="text-primary" />}
                  text="Continua con tu e-mail"
                  onCLick={() => handleClickStrategy(LoginStrategies.EMAIL)}
                />
                <LoginStrategy
                  icon={<UserOutlined className="text-primary" />}
                  text="Continua con tu usuario y contraseña"
                  onCLick={() => handleClickStrategy(LoginStrategies.LOCAL)}
                />
              </div>
            ) : (
              <StrategyBase onBack={() => handleClickStrategy(null)}>
                {Strategies[strategy]}
              </StrategyBase>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
