import { useState } from 'react'

const useCollapse = (collapsible: boolean) => {
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapse = () => {
    if (!collapsible) return
    setCollapsed(!collapsed)
  }

  return {
    collapsed,
    toggleCollapse,
  }
}

export default useCollapse
