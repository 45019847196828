import type {Application} from "@feathersjs/feathers";

class AuthenticationService {
  private app: Application<any, any>;

  constructor(app: Application) {
    this.app = app;
  }

  requestOtpSms(countryCode: string, phone: string) {
    return this.app.authenticate({
      strategy: "sms",
      phone,
      phone_country_code: countryCode,
    });
  }

  smsLogin(countryCode: string, phone: string, otp: string) {
    return this.app.authenticate({
      strategy: "sms",
      phone,
      phone_country_code: countryCode,
      otp_phone_token: otp,
    });
  }

  jwtLogin() {
    return this.app.reAuthenticate();
  }

  logout() {
    return this.app.logout();
  }
}

export default AuthenticationService;
