import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ViewsKeys } from './tasks.keys'

const useTaskBase = () => {
  const [currentView, setCurrentView] = useState<ViewsKeys>()

  const location = useLocation()
  const navigate = useNavigate()

  const handleChangeView = (view: ViewsKeys) => {
    const search = new URLSearchParams(location.search)

    search.set('view', view)
    navigate({
      search: search.toString(),
    })
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const view = <ViewsKeys | undefined>search.get('view')

    if (!view) {
      search.set('view', ViewsKeys.DATE)
      navigate({
        search: search.toString(),
      })
    } else {
      setCurrentView(view)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return {
    currentView,
    handleChangeView,
  }
}

export default useTaskBase
