import type { IBaseIconProps } from './types'

import { FC } from 'react'

const UserIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 24.898 27.828"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 209" transform="translate(23745 24065.324)">
        <g
          data-name="Rectangle 69"
          stroke={color}
          strokeWidth="2"
          transform="translate(-23745 -24049.213)"
        >
          <path
            d="M6,0H18.9a6,6,0,0,1,6,6V8.717a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V6A6,6,0,0,1,6,0Z"
            stroke="none"
          />
          <path d="M6,1H18.9a5,5,0,0,1,5,5V8.717a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V6A5,5,0,0,1,6,1Z" />
        </g>
        <g
          data-name="Ellipse 8"
          stroke={color}
          strokeWidth="2"
          transform="translate(-23739.141 -24065.324)"
        >
          <circle cx="7.323" cy="7.323" r="7.323" stroke="none" />
          <circle cx="7.323" cy="7.323" fill="none" r="6.323" />
        </g>
      </g>
    </svg>
  )
}

export default UserIcon
