import { IUIState } from 'redux/@types/ui'

export const UI_SET_SUBMENU_COLLAPSED = 'uiActions/UI_SET_SUBMENU_COLLAPSED'
export interface ISetUiSubmenuCollapsed {
  type: typeof UI_SET_SUBMENU_COLLAPSED
  payload: boolean
}

export const UI_SET_SUBMENU_ITEMS = 'uiActions/UI_SET_SUBMENU_ITEMS'
export interface ISetUiSubmenuItems {
  type: typeof UI_SET_SUBMENU_ITEMS
  payload: IUIState['submenuItems']
}

export const UI_SET_SHOW_SEARCH_POPOVER = 'uiActions/UI_SET_SHOW_SEARCH_POPOVER'
export interface ISetUiShowSearchPopover {
  type: typeof UI_SET_SHOW_SEARCH_POPOVER
  payload: boolean
}

export const UI_SET_SHOW_TASK_MODAL = 'uiActions/UI_SET_SHOW_TASK_MODAL'
export interface ISetShowTaskModal {
  type: typeof UI_SET_SHOW_TASK_MODAL
  /**
   * Task Id
   */
  payload: number
}

export const UI_SET_HIDE_TASK_MODAL = 'uiActions/UI_SET_HIDE_TASK_MODAL'
export interface ISetHideTaskModal {
  type: typeof UI_SET_HIDE_TASK_MODAL
}
