import {projectPhasesService} from "shared/services";

export default {
  getProjectPhases(boardId: number) {
    return projectPhasesService
      .find({
        query: {
          project_board_id: boardId,
          $sort: {
            position: 1,
          },
        },
      })
      .then(({data}) => data);
  },
};
