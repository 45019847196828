import type { FC } from 'react'

import { Typography } from 'antd'
import CustomerIcon from 'shared/assets/icons/customer'
import { PersonNameBadgeGrouped } from 'shared/components/common/PersonNameBadge'
import { ProjectStatusBar } from 'shared/components/common/ProjectStatusBar'
import { TaskTimeGrouped } from 'shared/components/common/TaskTime'
import TaskDates from 'shared/components/common/TaskDates'
import ProjectProgress from 'shared/components/common/ProjectProgress'
import { Link } from 'react-router-dom'

import { CardSection, CardSectionContent, CustomCard, TextIconWrapper } from './styled'

const Card: FC = () => {
  return (
    <CustomCard size="small">
      <Link to="/projects/nueva-scp">
        <div className="flex">
          <CardSection>
            <CardSectionContent>
              <Typography.Text strong>SCP Nueva Web</Typography.Text>
              <TextIconWrapper>
                <CustomerIcon color="#00000073" size={15} />
                <Typography.Text>SCP</Typography.Text>
              </TextIconWrapper>
              <PersonNameBadgeGrouped
                persons={[
                  { name: 'Kin Sandoval', color: '#B142EB' },
                  { name: 'Hugo Gutierrez', color: '#07C300' },
                  { name: 'John Doe' },
                  { name: 'Albert Einstein' },
                ]}
              />
            </CardSectionContent>
          </CardSection>
          <CardSection flex={2}>
            <div className="flex h-full">
              <div className="flex flex-col h-full flex-2">
                <div className="border-b flex-1">
                  <TaskDates />
                </div>
                <div className="flex-1">
                  <TaskTimeGrouped />
                </div>
              </div>
              <div className="flex-1 border-l h-full p-3 flex flex-col justify-center items-center">
                <ProjectProgress />
              </div>
            </div>
          </CardSection>
          <CardSection>
            <div className="flex items-center justify-center w-full h-full px-3">
              <ProjectStatusBar className="text-base" />
            </div>
          </CardSection>
          <CardSection>
            <div className="flex items-center justify-center w-full h-full px-3">
              <ProjectStatusBar completed className="text-base" />
            </div>
          </CardSection>
          <CardSection>
            <div className="flex items-center justify-center w-full h-full px-3">
              <ProjectStatusBar className="text-base" />
            </div>
          </CardSection>
          <CardSection>
            <div className="flex items-center justify-center w-full h-full px-3">
              <ProjectStatusBar none className="text-base" />
            </div>
          </CardSection>
          <CardSection>
            <div className="flex items-center justify-center w-full h-full px-3">
              <ProjectStatusBar className="text-base" />
            </div>
          </CardSection>
        </div>
      </Link>
    </CustomCard>
  )
}

export default Card
