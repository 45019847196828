import {UniqueIdentifier} from "@dnd-kit/core";
import {useEffect, useState} from "react";
import _ from "lodash";

import {type IKanban} from "../@types/IKanban";
import {KanbanProvider} from "../lib/context/Kanban.context";

export function Kanban<T = any>({
  children,
  dataSource: data,
  idField = "id" as keyof T,
  ...props
}: IKanban<T>): JSX.Element {
  const [dataSource, setDataSource] = useState<Record<UniqueIdentifier, T[]>>({});

  useEffect(() => {
    setDataSource(
      _(data)
        .groupBy(props.keyField)
        .map((items, key) => ({
          [key]: items,
        }))
        .reduce((acc, it) => ({
          ...acc,
          ...it,
        })) ?? {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <KanbanProvider
      value={{
        dataSource,
        idField: idField as string,
        setDataSource,
        ...props,
      }}
    >
      <div className="w-full overflow-auto beautifull-scrollbar h-full">{children}</div>
    </KanbanProvider>
  );
}
