import type {FC} from "react";

import {Typography} from "antd";
import CustomerIcon from "shared/assets/icons/customer";
import FlagIcon, {FlagEmpty} from "shared/assets/icons/flag";
import moment from "moment";
import {ProjectStepBar} from "shared/components/common/ProjectStepBar";
import {ProgressBar} from "shared/components/common/Progress";
import AppButton from "shared/components/common/AppButton";
import {EllipsisOutlined} from "@ant-design/icons";
import {useTheme} from "styled-components";
import TitleInput from "shared/components/common/TitleInput";
import {IProject, IProjectExtended} from "types/dto/IProject";
import {projectsService} from "shared/services";
import {TimelinePicker} from "shared/components/common/TimelinePicker";
import {useForm} from "react-hook-form";
import React from "react";

import {useProject} from "../context";

import {HeadResume, ProjectInfo, ProjectStatusWrapper} from "./styles/ProjectStatus.styled";

export const ProjectStatus: FC = () => {
  const theme = useTheme();

  const project = useProject();

  const {control, getValues, reset} = useForm<IProjectExtended>();

  const handlePatchProject = (key: keyof IProject) => async (value: unknown) => {
    await projectsService.patch(project.id, {
      [key]: value,
    });
  };

  const handleSaveProjectDates = async () => {
    const startDate = getValues("start_date");
    const endDate = getValues("end_date");

    await projectsService.patch(project.id, {
      start_date: startDate || null,
      end_date: endDate || null,
    });
  };

  React.useEffect(() => {
    if (project) {
      reset(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <HeadResume className="sticky top-[62px] z-50 bg-white">
      <ProjectInfo>
        <TitleInput saveValue={handlePatchProject("name")} value={project.name} />
        <div className="flex gap-4 divide-x divide-gray-300">
          <div className="flex gap-2 pr-2 py-2 text-md">
            <CustomerIcon color={theme.colors.grey} size={18} />
            <Typography.Text>{project?.organization?.name}</Typography.Text>
          </div>
          <div className="flex gap-3 pl-4 py-2 text-md">
            <TimelinePicker
              control={control}
              endDateFieldName="end_date"
              startDateFieldName="start_date"
              onBlur={handleSaveProjectDates}
              onChange={handleSaveProjectDates}
            />
          </div>
        </div>
      </ProjectInfo>
      <ProjectStatusWrapper>
        <div className="flex justify-between items-center">
          <div>
            <ProjectStepBar />
            <div className="mt-4 flex gap-2 max-w-xl items-center align-middle text-md">
              <Typography.Text className="inline-block flex-none">
                Progreso del proyecto:
              </Typography.Text>
              <div className="w-[140px] block">
                <ProgressBar
                  max={project?.project_progress?.total_tasks || 1}
                  progress={project?.project_progress?.completed_tasks || 0}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <AppButton $success type="primary">
              Finalizado
            </AppButton>
            <AppButton danger type="primary">
              Cancelado
            </AppButton>
            <AppButton className="font-semibold" type="text">
              <EllipsisOutlined />
            </AppButton>
          </div>
        </div>
      </ProjectStatusWrapper>
    </HeadResume>
  );
};
