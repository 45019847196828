import {Button} from "antd";
import {TaskStatusPicker} from "shared/components/common/TaskStatusPicker";
import {useForm} from "react-hook-form";
import {ITask, ITaskList} from "types/dto/ITask";
import {Link, useLocation} from "react-router-dom";
import {PersonNameBadge} from "shared/components/common/PersonNameBadge";
import {TreeTaskList} from "shared/components/common/TreeTasksList";
import React from "react";
import moment from "moment";
import TreeListSectionDirective from "shared/components/common/TreeTasksList/ui/TreeListSectionDirective";
import SectionDirective from "shared/components/common/TreeTasksList/ui/SectionDirective";
import {UniqueIdentifier} from "@dnd-kit/core";
import {tasksService} from "shared/services";
import {IFlattenedItem} from "shared/components/common/TreeTasksList/@types";

export default function GroupedList({
  tasks,
}: {
  tasks: (ITaskList & {due_date_group_name: string})[];
}) {
  const {control, register} = useForm<ITask>({
    defaultValues: {
      task_status_id: 1,
    },
  });

  const groupDefinitions = React.useMemo(() => {
    const now = moment();

    return {
      overdue: "Vencido",
      today: "Hoy",
      ...new Array(6).fill(0).reduce((acc, _, i) => {
        return {
          ...acc,
          [moment(now)
            .add(i + 1, "day")
            .format("dddd")]: moment(now)
            .add(i + 1, "day")
            .format("dddd"),
        };
      }, {}),
      future: "Futuro",
      without_due_date: "Sin fecha límite",
    };
  }, []);

  const treeTasks = React.useMemo(() => {
    function buildTree(dataTasks: ITaskList[]) {
      function getChildren(
        parentId: number,
        dataTasks: ITaskList[],
      ): (ITaskList & {children: ITaskList[]})[] {
        return dataTasks
          .filter((task) => task.parent_id === parentId)
          .map((task) => ({
            ...task,
            children: getChildren(task.id, dataTasks),
          }));
      }

      return dataTasks
        .filter((task) => !task.parent_id)
        .map((task) => ({
          ...task,
          children: getChildren(task.id, dataTasks),
        }));
    }

    function groupTasks() {
      return tasks
        .map((task) => {
          if (!task.due_date) {
            return {
              ...task,
              group_value: "without_due_date",
            } as ITaskList & {group_value: string};
          } else {
            const taskDueDate = moment(task.due_date).format("DD-MM-YYYY");
            const now = moment().format("DD-MM-YYYY");

            if (moment(taskDueDate).isBefore(moment(now))) {
              return {
                ...task,
                group_value: "overdue",
              } as ITaskList & {group_value: string};
            } else if (taskDueDate === now) {
              return {
                ...task,
                group_value: "today",
              } as ITaskList & {group_value: string};
            } else {
              const weekDayNameIndex = Object.keys(groupDefinitions).findIndex(
                (key) => key === moment(taskDueDate).format("dddd"),
              );

              if (weekDayNameIndex > -1) {
                return {
                  ...task,
                  group_value: Object.keys(groupDefinitions)[weekDayNameIndex],
                } as ITaskList & {group_value: string};
              } else {
                return {
                  ...task,
                  group_value: "future",
                } as ITaskList & {group_value: string};
              }
            }
          }
        })
        .sort((a, b) => a.position - b.position);
    }

    return buildTree(groupTasks()) as unknown as Array<
      ITaskList & {group_value: string; children: Array<ITaskList & {group_value: string}>}
    >;
  }, [tasks, groupDefinitions]);

  const location = useLocation();

  const handleUpdateTask = async (
    task: IFlattenedItem,
    taskPosition: number,
    groupValue: UniqueIdentifier,
    parentId: UniqueIdentifier | null,
  ) => {
    const groupValues = {
      overDue: moment().subtract(1, "day").toDate().toISOString(),
      today: moment().toDate().toString(),
      future: treeTasks.filter((it) => it.group_value === "future").reverse()?.[0]?.due_date,
      without_due_date: null,
    };

    await tasksService.patch(task.id, {
      parent_id: parentId ? +parentId : null,
      position: taskPosition,
      due_date:
        groupValues[groupValue as keyof typeof groupValues] ||
        treeTasks.filter((it) => it.group_value === groupValue).reverse()?.[0]?.due_date,
    });
  };

  // const handleUpdateLocalTask = (task: ITask) => {

  // }

  // const subscribeEvents = () => {
  //   tasksService.service.on('patched', handleUpdateLocalTask)
  //   tasksService.service.on('created', handleAppendTask)
  // }

  // const unsubscribeEvents = () => {
  //   tasksService.service.removeListener('patched', handleUpdateLocalTask)
  //   tasksService.service.removeListener('created', handleAppendTask)
  // }

  // React.useEffect(() => {
  //   subscribeEvents()

  //   return () => {
  //     unsubscribeEvents()
  //   }
  // }, [])

  return (
    <TreeTaskList<
      ITaskList & {
        group_value: string;
      }
    >
      columns={[
        {
          title: "",
          dataIndex: "name",
          render: (name: string, record) => {
            return (
              <Link
                className="block w-full hover:text-black"
                to={`task/${record.id}${location.search}`}
              >
                {name}
              </Link>
            );
          },
        },
        {
          title: "Persona asignada",
          width: 200,
          rowColStyles: {
            overflow: "visible",
          },
          render: ({
            meta_owner_user_first_name,
            meta_owner_user_last_name,
            meta_owner_user_profile_color,
          }: ITaskList) => {
            if (!meta_owner_user_first_name) return <></>;

            return (
              <PersonNameBadge bg={meta_owner_user_profile_color}>
                {meta_owner_user_first_name.charAt(0).toUpperCase() +
                  meta_owner_user_last_name.charAt(0).toUpperCase()}
              </PersonNameBadge>
            );
          },
        },
        {
          title: "Fecha límite",
          dataIndex: "due_date",
          width: 160,
        },
        {
          title: "Tiempo registrado",
          width: 140,
          render: () => 0,
        },
        {
          title: "Tiempo estimado",
          dataIndex: "time_estimated_seconds",
          width: 140,
        },
        {
          title: "Estado",
          dataIndex: "task_status_id",
          width: 160,
          render: (value: number) => {
            return <TaskStatusPicker bordered={false} value={value} />;
          },
        },
      ]}
      dataSource={treeTasks}
      keyField="group_value"
      onDrop={handleUpdateTask}
    >
      <TreeListSectionDirective>
        {Object.entries(groupDefinitions)
          .filter(([group]) => treeTasks.some((task) => task.group_value === group))
          .map(([key, title]) => (
            <SectionDirective
              key={`section-${key}`}
              allowAdd
              keyField={key}
              title={title as string}
            />
          ))}
      </TreeListSectionDirective>
    </TreeTaskList>
  );
}
