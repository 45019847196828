import type {ITaskCommentExtends} from "types/dto/ITaskComment";

import React from "react";
import {taskCommentsService} from "shared/services";

export default function useComments({
  taskId,
  containerRef,
}: {
  taskId?: number;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const [comments, setComments] = React.useState<ITaskCommentExtends[]>([]);

  const loadingRef = React.useRef(false);

  const loadComments = async () => {
    if (loadingRef.current) return;

    loadingRef.current = true;

    await taskCommentsService
      .find<ITaskCommentExtends>({
        query: {
          task_id: taskId,
          $sort: {createdAt: 1},
        },
      })
      .then(({data}) => {
        setComments(data);
      })
      .finally(() => {
        loadingRef.current = false;
      });
  };

  const appendComment = (comment: ITaskCommentExtends) => {
    if (comment.task_id !== taskId) return;

    taskCommentsService.get<ITaskCommentExtends>(comment.id).then((res) => {
      setComments((prev) => prev.concat(res));
    });
  };

  const subscribeEvents = () => {
    taskCommentsService.service.on("created", appendComment);
  };

  const unsubscribeEvents = () => {
    taskCommentsService.service.removeListener("created", appendComment);
  };

  React.useEffect(() => {
    if (taskId) {
      loadComments();
    }

    return () => {
      setComments([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  React.useEffect(() => {
    subscribeEvents();

    return () => {
      unsubscribeEvents();
    };
  });

  React.useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
      });
    }
  }, [comments, containerRef]);

  return {
    comments,
  };
}
