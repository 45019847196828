import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const FlagIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      data-name="Group 199"
      fill={color || '#fff'}
      height={size || 30}
      viewBox="0 0 14.205 19.451"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.859,65.92a.6.6,0,1,0-1.16.294l3.909,15.424a.6.6,0,0,0,1.16-.294Z"
        data-name="Path 8"
        transform="translate(-60.68 -62.639)"
      />
      <path
        d="M112.612,7.625,110.7.568a.642.642,0,0,0-.645-.555c-3.583-.259-5.935,3.308-9.518,3.049-.283-.035-.464.112-.4.367q.956,3.529,1.912,7.057a.709.709,0,0,0,.645.555c3.583.26,5.935-3.308,9.518-3.049C112.5,8.027,112.681,7.88,112.612,7.625ZM109.8.9l.528,1.947a6.466,6.466,0,0,0-2.124.441l-.527-1.947A6.466,6.466,0,0,1,109.8.9Zm-7.383,7.276-.6-2.212a6.4,6.4,0,0,0,2.23-.485l-.528-1.947a21.488,21.488,0,0,0,2.076-1.1l.528,1.947a21.5,21.5,0,0,1-2.076,1.1l.6,2.212A6.4,6.4,0,0,1,102.418,8.177Zm2.766,1.49-.535-1.976A21.285,21.285,0,0,0,106.709,6.6l.535,1.976A21.27,21.27,0,0,1,105.183,9.668Zm1.54-3.079-.6-2.212a19.3,19.3,0,0,1,2.08-1.087l.6,2.212A19.33,19.33,0,0,0,106.724,6.589Zm2.628.883L108.816,5.5a6.455,6.455,0,0,1,2.111-.436l.535,1.976A6.454,6.454,0,0,0,109.352,7.472Z"
        data-name="Path 9"
        transform="translate(-98.422 0)"
      />
    </svg>
  )
}

export const FlagEmpty: FC<IBaseIconProps> = ({ color, size }) => {
  return (
    <svg
      data-name="Group 199"
      fill={color || '#fff'}
      height={size || 30}
      viewBox="0 0 14.205 19.451"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="racing-flag" transform="translate(0 0.496)">
        <g data-name="Group 18" id="Group_18">
          <path
            d="M61.859,65.92a.6.6,0,1,0-1.16.294l3.909,15.424a.6.6,0,0,0,1.16-.294Z"
            data-name="Path 8"
            fill="#919191"
            id="Path_8"
            transform="translate(-60.68 -62.639)"
          />
          <path
            d="M112.612,7.625,110.7.568a.642.642,0,0,0-.645-.555c-3.583-.259-5.935,3.308-9.518,3.049-.283-.035-.464.112-.4.367q.956,3.529,1.912,7.057a.709.709,0,0,0,.645.555c3.583.26,5.935-3.308,9.518-3.049C112.5,8.027,112.681,7.88,112.612,7.625Z"
            data-name="Path 9"
            fill="none"
            id="Path_9"
            stroke="#919191"
            strokeWidth="1"
            transform="translate(-98.422 0)"
          />
        </g>
      </g>
    </svg>
  )
}

export default FlagIcon
