import type { IUseModalFormProps } from '../@types/useModalForm'
import type { FormEvent } from 'react'

import { useState } from 'react'

const useModalForm = (props?: IUseModalFormProps) => {
  const [isSubmit, setIsSubmit] = useState(false)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (props?.onSubmit?.constructor.name === 'AsyncFunction') {
      setIsSubmit(true)
      ;(props.onSubmit(event) as Promise<void>).finally(() => {
        setIsSubmit(false)
      })
    } else {
      props?.onSubmit && props?.onSubmit(event)
    }
  }

  return {
    isSubmit,
    handleSubmit,
  }
}

export default useModalForm
