import {useForm} from "react-hook-form";
import {useAuth} from "shared/contexts/Auth";
import {taskCommentsService} from "shared/services";
import {ITaskComment} from "types/dto/ITaskComment";

export default function useAddComment(taskId?: number) {
  const {control, getValues, reset} = useForm<ITaskComment>();

  const {user} = useAuth();

  const handleComment = async () => {
    if (!taskId) return;
    const {body} = getValues();

    await taskCommentsService
      .create({
        body,
        task_id: taskId,
        user_id: user!.id,
      })
      .then(() => {
        reset({});
      });
  };

  return {
    control,
    handleComment,
  };
}
