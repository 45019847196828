import styled from 'styled-components'
import { Collapse as AntCollapse } from 'antd'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar } from 'react-big-calendar'

export const HeaderWrapper = styled.div`
  padding: 1.3rem 1.1rem;
  border-bottom: 1px solid #e5e7eb;
`

export const Collapse = styled(AntCollapse)`
  .ant-collapse-content-box,
  .ant-collapse-header {
    padding: 0 !important;
  }
`

const DndCalendar = withDragAndDrop(Calendar)

export const StyledCalendar = styled(DndCalendar)`
  & .rbc-today {
    background: #ffff;
  }

  & .rbc-time-gutter > .rbc-timeslot-group {
    border-bottom: none;
  }

  & .rbc-today > .rbc-timeslot-group > .rbc-time-slot {
    border-top: none;
  }

  & .rbc-timeslot-group > .rbc-time-slot > .rbc-label {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.grey};
  }

  & .rbc-events-container > .rbc-event {
    background: #367ddf;
    border: none;
  }

  & .rbc-current-time-indicator {
    background: #ff4e4e;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: #ff4e4e;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
    }
  }
`
