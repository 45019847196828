import type { FC } from 'react'

import { ModalForm } from 'shared/components/common/ModalForm'
import { AppSelectC } from 'shared/components/common/AppSelect'
import { AppInput } from 'shared/components/common/Input'

import { IModalTemplateProps } from '../@types/ModalTemplate'
import useModalTemplate from '../lib/useModalTemplate'

export const ModalTemplate: FC<IModalTemplateProps> = ({ open, onClose, onCreate }) => {
  const { control, handleSubmit } = useModalTemplate({
    onCreate,
  })

  return (
    <ModalForm
      centered
      footerProps={{
        submitText: 'Crear',
        okButtonProps: {
          width: 120,
        },
      }}
      open={open}
      title="Nueva Plantilla"
      width={420}
      onCancel={onClose}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-2">
        <AppSelectC
          className="w-full"
          control={control}
          defaultValue={1}
          label="Seleccione Plantilla"
          name="template"
          options={[
            {
              label: 'Ninguna',
              value: 1,
            },
          ]}
          separator={false}
        />
        <AppInput control={control} label="Título" name="title" />
        <AppInput control={control} label="Tablero" name="dashboard" />
      </div>
    </ModalForm>
  )
}
