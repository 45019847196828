import { useState } from 'react'

export enum LoginStrategies {
  SMS = 'sms',
  EMAIL = 'email',
  LOCAL = 'local',
}

const useLogin = () => {
  const [strategy, setStrategy] = useState<LoginStrategies | null>(null)

  const handleClickStrategy = (strategy: LoginStrategies | null) => {
    setStrategy(strategy)
  }

  return {
    strategy,
    handleClickStrategy,
  }
}

export default useLogin
