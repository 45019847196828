const getInitials = (name?: string) => {
  if (!name) return ''

  return name
    .trim()
    .split(' ')
    .slice(0, 2)
    .reduce((acc, it) => acc + it[0], '')
}

export default getInitials
