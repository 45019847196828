import type {FC} from "react";
import type {ITaskStatus} from "types/dto/ITaskStatus";

import clsx from "clsx";

export const TaskStatusBadge: FC<{
  status: ITaskStatus;
  rounded?: boolean;
  className?: string;
  size?: "small" | "large";
}> = ({status, rounded = true, className, size = "large"}) => {
  return (
    <div
      className={clsx("inline-block text-center", className, {
        "rounded-md": rounded,
        "px-6 py-4": size === "large",
        "px-2 py-1": size === "small",
      })}
      style={{
        color: status.tag_text_color,
        background: status.tag_background_color,
      }}
    >
      <p
        className={clsx("text-white font-semibold", {
          "text-lg": size === "large",
          "text-sm": size === "small",
        })}
      >
        {status.name}
      </p>
    </div>
  );
};
