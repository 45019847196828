import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const CheckListIcon: FC<IBaseIconProps> = ({ color, size }) => {
  return (
    <svg
      fill={color || '#fff'}
      height={size || 30}
      viewBox="0 0 22.57 27.5"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon" transform="translate(-2.75 -0.75)">
        <path
          d="M24.82,3.407A2.157,2.157,0,0,0,22.663,1.25H5.407A2.157,2.157,0,0,0,3.25,3.407V25.593A2.157,2.157,0,0,0,5.407,27.75H22.663a2.157,2.157,0,0,0,2.157-2.157ZM7.2,21.973l1.387,1.479A.924.924,0,0,0,10,23.374l2.311-3.081a.924.924,0,0,0-1.479-1.109l-1.65,2.2-.634-.675A.924.924,0,1,0,7.2,21.973Zm8.07.23H20.2a.924.924,0,1,0,0-1.849h-4.93a.924.924,0,1,0,0,1.849ZM7.2,15.194l1.387,1.479A.924.924,0,0,0,10,16.6l2.311-3.081A.924.924,0,0,0,10.83,12.4L9.18,14.6l-.634-.675A.924.924,0,1,0,7.2,15.194Zm8.07.23H20.2a.924.924,0,1,0,0-1.849h-4.93a.924.924,0,1,0,0,1.849ZM7.2,8.415,8.585,9.894A.924.924,0,0,0,10,9.816l2.311-3.081A.924.924,0,1,0,10.83,5.626l-1.65,2.2L8.546,7.15A.924.924,0,0,0,7.2,8.415Zm8.07.23H20.2a.924.924,0,1,0,0-1.849h-4.93a.924.924,0,1,0,0,1.849Z"
          data-name="Path 13"
          fillRule="evenodd"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default CheckListIcon
