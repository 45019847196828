import type { FC, MouseEvent } from 'react'

import { InputPhone } from 'shared/components/common/InputPhone'
import AppButton from 'shared/components/common/AppButton'
import { OtpInput } from 'shared/components/common/OtpInput'

import useSmsStrategy, { FormSteps } from '../lib/useSmsStrategy'

import { CountDown } from './CountDown'

export const SMSStrategy: FC = () => {
  const { control, errors, isSubmitting, formStep, otpExpiredAt, handleSubmit, requestNewOtp } =
    useSmsStrategy()

  return (
    <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
      {formStep === FormSteps.INPUT_PHONE && (
        <InputPhone
          control={control}
          error={errors?.phone?.message}
          label="Número de teléfono"
          name="phone"
        />
      )}

      {formStep === FormSteps.INPUT_CODE && (
        <OtpInput
          control={control}
          error={errors?.otp_phone_token?.message}
          label="Ingresa el código"
          name="otp_phone_token"
        />
      )}

      {formStep === FormSteps.INPUT_CODE && (
        <CountDown
          renderNodeOnFinish={
            <AppButton
              $hovered={false}
              className="mt-7 hover:scale-105"
              htmlType="submit"
              loading={isSubmitting}
              type="text"
              onClick={(e) => requestNewOtp(e as MouseEvent<HTMLButtonElement>)}
            >
              Solicitar nuevo código
            </AppButton>
          }
          timeEnd={otpExpiredAt}
        />
      )}

      <AppButton
        $hovered={false}
        className="mt-7 hover:scale-105"
        htmlType="submit"
        loading={isSubmitting}
        type="primary"
      >
        {formStep === FormSteps.INPUT_PHONE ? 'Solicitar código' : 'Ingresar'}
      </AppButton>
    </form>
  )
}
