import type { FC } from 'react'
import type { IExtraInputsProps } from 'modules/Contacts/@types/ExtraInputs'

import { AppInput } from 'shared/components/common/Input'
import { InputPhone } from 'shared/components/common/InputPhone'
import useExtraInputs from 'modules/Contacts/lib/useExtraInputs'
import { AppSelectC } from 'shared/components/common/AppSelect'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export const ExtraInputs: FC<IExtraInputsProps> = ({
  control,
  extraArrayInputName,
  label,
  primaryInputName,
  phoneInput = false,
  addTextButton,
}) => {
  const { error, errors, extraFields, handleAddNewExtra, handleRemoveExtra } = useExtraInputs({
    control,
    name: primaryInputName,
    extraArrayFieldName: extraArrayInputName,
  })

  return (
    <div>
      {phoneInput ? (
        <InputPhone
          control={control}
          error={error?.message as string}
          label={label}
          name={primaryInputName}
        />
      ) : (
        <AppInput
          control={control}
          error={error?.message as string}
          label={label}
          name={primaryInputName}
        />
      )}

      <div>
        {extraFields.map((field, index) => (
          <div key={field.fieldId} className="flex gap-2 items-center">
            <div className="flex-[60%]">
              {phoneInput ? (
                <InputPhone
                  control={control}
                  error={errors?.[index]?.phone?.message}
                  label="&nbsp;"
                  name={`${extraArrayInputName}.${index}.phone`}
                />
              ) : (
                <AppInput
                  control={control}
                  error={errors?.[index]?.email?.message}
                  label="&nbsp;"
                  name={`${extraArrayInputName}.${index}.email`}
                />
              )}
            </div>
            <div className="flex-auto flex items-end">
              <AppSelectC
                className="w-full"
                control={control}
                error={errors?.[index]?.type?.message}
                label="&nbsp;"
                name={`${extraArrayInputName}.${index}.type`}
                options={[
                  { label: 'Trabajo', value: 'work' },
                  { label: 'Personal', value: 'personal' },
                  { label: 'Otro', value: 'Other' },
                ]}
                separator={false}
              />
              <Button
                danger
                className="flex items-center justify-center"
                icon={<DeleteOutlined />}
                size="small"
                type="text"
                onClick={() => handleRemoveExtra(index)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="block w-full">
        <Button type="link" onClick={handleAddNewExtra}>
          {addTextButton}
        </Button>
      </div>
    </div>
  )
}
