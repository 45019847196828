import React from "react";
import {selectUiTaskModal} from "redux/selectors/ui.selectors";
import useAppSelector from "shared/hooks/redux/useAppSelector";
import {tasksService} from "shared/services";
import {ITaskDetail} from "types/dto/ITask";

export default function useTask() {
  const [task, setTask] = React.useState<ITaskDetail | null>(null);

  const {taskId} = useAppSelector(selectUiTaskModal());

  const getTask = async () => {
    if (!taskId) return;
    await tasksService.get<ITaskDetail>(taskId).then((res) => {
      setTask(res);
    });
  };

  const updateLocalTask = (updatedTask: ITaskDetail) => {
    if (updatedTask.id !== taskId) return;
    setTask((prev) => ({
      ...prev,
      ...updatedTask,
    }));
  };

  const addEvents = () => {
    tasksService.service.on("patched", updateLocalTask);
  };

  const removeEvents = () => {
    tasksService.service.removeListener("patched", updateLocalTask);
  };

  React.useEffect(() => {
    if (taskId) {
      getTask();
    }

    // return () => {
    //   setTask(null);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  React.useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    task,
  };
}
