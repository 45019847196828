export interface IBase {
  id: number;
  createdAt: string;
  updatedAt: string;
}

export enum EntityStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum EntityBoolean {
  TRUE = "true",
  FALSE = "false",
}
