import type {PickerProps} from "../@types";

import React from "react";
import {Button, Dropdown} from "antd";
import clsx from "clsx";

import {TaskStatusBadge} from "../../TaskStatusBadge";

export default function Picker({type, statuses, value, onChange}: PickerProps) {
  const statusData = React.useMemo(() => {
    return statuses.find((status) => status.id === value);
  }, [statuses, value]);

  return (
    <>
      <Dropdown
        className="flex items-center"
        menu={{
          items: statuses.map((status) => ({
            key: status.id,
            label: status.name,
            icon: (
              <span
                className={clsx("block", "w-4", "h-4", "rounded-md")}
                style={{
                  color: status.tag_text_color,
                  background: status.tag_background_color,
                }}
              />
            ),
            onClick: () => {
              if (onChange) {
                onChange(status.id);
              }
            },
          })),
          selectable: true,
          selectedKeys: value ? [value + ""] : [],
        }}
        trigger={["click"]}
      >
        <Button size="small" style={{padding: 0}} type="ghost">
          {!!statusData && type === "full" && (
            <TaskStatusBadge className="w-auto cursor-pointer" size="small" status={statusData} />
          )}
          {!!statusData && type === "compact" && (
            <div
              className="inline-block w-4 h-4 rounded-md"
              style={{
                color: statusData.tag_text_color,
                background: statusData.tag_background_color,
              }}
            />
          )}
        </Button>
      </Dropdown>
    </>
  );
}
