import {Tooltip, Typography} from "antd";
import clsx from "clsx";

import {IPhaseItemProps} from "../@types";

function PhaseItem({label, size, completed, disabled, onClick}: IPhaseItemProps) {
  return (
    <Tooltip
      color="white"
      overlay={<Typography.Text className="text-blue-300 text-md">{label}</Typography.Text>}
      overlayInnerStyle={{
        padding: "5px",
      }}
      showArrow={false}
      title="Kick Off"
      trigger={["hover"]}
    >
      <button
        className={clsx("inline-block", "flex-1", "outline-none", {
          "bg-green-700": completed,
          "bg-gray-100": !completed,
          "h-6": size === "small",
          "h-8": size === "large",
          "hover:bg-green-700 transition-all duration-200 cursor-pointer": !disabled,
        })}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
      />
    </Tooltip>
  );
}
export default PhaseItem;
