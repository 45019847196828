import type { FC, ReactNode } from 'react'
import type { DefaultOptionType } from 'antd/es/select'

import { SelectProps } from 'antd'
import { Select } from 'antd'
import styled, { css } from 'styled-components'
import { CaretDownFilled as CaretDownIcon } from '@ant-design/icons'
import { Control, Controller } from 'react-hook-form'
import clsx from 'clsx'

export interface IBaseSelect {
  width?: number
  hovered?: boolean
}

export interface IAppSelect extends Omit<SelectProps, 'options'>, IBaseSelect {
  options?: Array<
    DefaultOptionType & {
      icon?: ReactNode
      data?: Record<string, any>
    }
  >
  separator?: boolean
  control?: Control<any>
  name?: string
  label?: string
  error?: string
  // hovered?: boolean
  renderOption?: (option: DefaultOptionType & { icon?: ReactNode }) => ReactNode
}

export interface ISuffixWrap {
  bordered?: boolean
}

const SuffixWrap = styled.div<ISuffixWrap>`
  display: flex;
  ${({ bordered }) =>
    bordered !== false
      ? css`
          border-left: 1px solid #d9d9d9;
        `
      : ''}
  margin-left: 3px;
  padding-left: 6px;
  align-items: center;
  justify-content: center;
`

const BaseSelect = styled(Select)<IBaseSelect>`
  ${({ width }) => {
    if (width)
      return css`
        width: ${width}px;
      `
  }}

  &:hover .ant-select-selector {
    border-color: ${({ theme, bordered, hovered }) => {
      if (bordered !== false || hovered) return theme.colors.info

      return
    }} !important;
  }

  &.ant-select-focused .ant-select-selector {
    & .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.info} !important;
      & svg {
        fill: ${({ theme }) => theme.colors.info};
      }
    }
    color: ${({ theme }) => theme.colors.info} !important;
    border-color: ${({ theme, bordered }) =>
      bordered !== false ? theme.colors.info : 'none'} !important;
    box-shadow: 0 0 0 3px
      ${({ theme, bordered }) => (bordered !== false ? theme.colors['info-light'] : 'none')} !important;
  }

  &.ant-select-focused ${SuffixWrap} {
    border-left-color: ${({ theme, bordered }) =>
      bordered !== false ? theme.colors.info : 'none'} !important;
    color: ${({ theme }) => theme.colors.info};
  }

  &:hover ${SuffixWrap} {
    border-left-color: ${({ theme, bordered }) =>
      bordered !== false ? theme.colors.info : 'none'} !important;
    color: ${({ theme }) => theme.colors.info};
  }

  &:hover .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.info} !important;
    & svg {
      fill: ${({ theme }) => theme.colors.info};
    }
  }

  & .ant-select-selection-item {
    font-weight: 500;
    color: #707070;
  }
`

const AppSelect: FC<IAppSelect> = ({
  options,
  separator = true,
  hovered = false,
  bordered,
  renderOption,
  ...props
}) => {
  return (
    <BaseSelect
      bordered={hovered ? false : bordered}
      hovered={hovered}
      {...(props as any)}
      suffixIcon={
        <SuffixWrap bordered={separator}>
          <CaretDownIcon color="inherit" />
        </SuffixWrap>
      }
    >
      {options?.map((option, index) => (
        <Select.Option key={`key-option-${index}`} value={option.value}>
          {renderOption ? (
            renderOption(option)
          ) : option.icon ? (
            <div style={{ alignItems: 'center', display: 'flex', gap: 5 }}>
              <span className="w-10">{option.icon}</span>
              {option.label}
            </div>
          ) : (
            option.label
          )}
        </Select.Option>
      ))}
    </BaseSelect>
  )
}

export const AppSelectC: FC<IAppSelect> = ({
  options,
  separator = true,
  control,
  name,
  renderOption,
  onChange: _onChange,
  value: _value,
  label,
  id,
  defaultValue: _defaultValue,
  error,
  className,
  ...props
}) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name!}
      render={({ field: { value, onChange } }) => (
        <div>
          {label && (
            <label className="text-gray-400 font-bold" htmlFor={id}>
              {label}
            </label>
          )}
          <BaseSelect
            id={id}
            status={error ? 'error' : undefined}
            {...(props as any)}
            className={clsx('block', className)}
            defaultValue={value ?? _defaultValue}
            suffixIcon={
              <SuffixWrap bordered={separator}>
                <CaretDownIcon color="inherit" />
              </SuffixWrap>
            }
            value={value ?? _value}
            onChange={(value, option) => {
              onChange({ target: { value } })
              _onChange && _onChange(value, option as any)
            }}
          >
            {options?.map((option, index) => (
              <Select.Option key={`key-option-${index}`} value={option.value}>
                {renderOption ? (
                  renderOption(option)
                ) : option.icon ? (
                  <div style={{ alignItems: 'center', display: 'flex', gap: 5 }}>
                    <span className="w-10">{option.icon}</span>
                    {option.label}
                  </div>
                ) : (
                  option.label
                )}
              </Select.Option>
            ))}
          </BaseSelect>
          {Boolean(error) && <span className="block text-red-500 text-sm">{error}</span>}
        </div>
      )}
    />
  )
}

export default AppSelect
