import type { FC } from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'

import { IStrategyBaseProps } from '../@types/StrategyBase'

export const StrategyBase: FC<IStrategyBaseProps> = ({ children, onBack }) => {
  return (
    <div className="px-3 animate-fade-bottom ">
      {children}
      <div className="flex justify-center mt-8">
        <button
          className="flex items-center justify-between text-lg text-primary hover:underline transition-all duration-200"
          onClick={onBack}
        >
          <ArrowLeftOutlined className="mr-3" />
          Atrás
        </button>
      </div>
    </div>
  )
}
