import { useState } from 'react'

const useAddNewLabel = () => {
  const [open, setOpen] = useState(false)

  const handleOpen = (status: boolean) => {
    setOpen(status)
  }

  return {
    open,
    handleOpen,
  }
}

export default useAddNewLabel
