import type {IAppRoute} from "./type";

import {Template, Templates} from "modules/Templates";
import {Projects, Project} from "modules/projects";
import {Tasks} from "modules/tasks";

export const projectsRoutes: IAppRoute[] = [
  {
    path: "/projects",
    element: <Projects />,
    requireAuth: true,
  },
  {
    path: "/projects/task-and-activities",
    element: <Tasks />,
    requireAuth: true,
  },
  {
    path: "/projects/templates",
    element: <Templates />,
    requireAuth: true,
  },
  {
    path: "/projects/templates/:id",
    element: <Template />,
    requireAuth: true,
  },
  {
    path: "/projects/workload",
    element: <div>Workload</div>,
    requireAuth: true,
  },
  {
    path: "/projects/:id",
    element: <Project />,
    requireAuth: true,
  },
  {
    path: "/projects/:id/task/:taskId",
    element: <Project />,
    requireAuth: true,
  },
];
