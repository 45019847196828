import type {FC} from "react";
import type {IProject} from "types/dto/IProject";

import {Typography} from "antd";
import clsx from "clsx";

const STATUS_TEXTS: Record<IProject["status"], string> = {
  to_do: "Por Hacer",
  in_progress: "En Progreso",
  review: "En Revisión",
  closing: "Cerrando",
  canceled: "Cancelado",
  completed: "Completado",
};

const ProjectStatus: FC<{status?: IProject["status"]}> = ({status}) => {
  return (
    <Typography.Text
      className={clsx("text-white", "p-2", "rounded-md", "text-uppercase text-sm", {
        "bg-gray-300 border-gray-500": status === "to_do",
        "bg-yellow-300 border-yellow-500": status === "in_progress",
        "bg-orange-300 border-orange-500": status === "review",
        "bg-blue-300 border-blue-500": status === "closing",
        "bg-red-300 border-red-500": status === "canceled",
        "bg-green-300 border-green-500": status === "completed",
      })}
    >
      {STATUS_TEXTS[status ?? "to_do"]}
    </Typography.Text>
  );
};

export default ProjectStatus;
