import type {IProjectPhase} from "types/dto/IProjectPhase";

import {useEffect, useState} from "react";

import api from "../api";

const useProjectPhases = (boardId?: number) => {
  const [phases, setPhases] = useState<IProjectPhase[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (boardId) {
      setIsLoading(true);
      api
        .getProjectPhases(boardId)
        .then((res) => {
          setPhases(res);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setPhases([]);
    }
  }, [boardId]);

  return {
    isLoading,
    phases,
  };
};

export default useProjectPhases;
