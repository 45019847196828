import type {EntityStatus, IBase} from "./IBase";
import type {ILabelRelation} from "./ILabelRelation";

export enum OrganizationVisibilityTypes {
  OWNER = "owner",
  OWNER_AND_FOLLOWERS = "owner_and_followers",
  ALL = "all",
}

export const OrganizationVisibilities = {
  [OrganizationVisibilityTypes.OWNER]: "Solo yo",
  [OrganizationVisibilityTypes.OWNER_AND_FOLLOWERS]: "Integrantes",
  [OrganizationVisibilityTypes.ALL]: "Todos",
};

export interface IOrganization extends IBase {
  company_id: number;
  name: string;
  activity_type_id: number;
  owner_user_id: number;
  meta_owner_user_first_name: string;
  meta_owner_user_last_name: string;
  meta_owner_user_avatar_path: string;
  visible_to: OrganizationVisibilityTypes;
  avatar_path: string;
  status: EntityStatus;
}

export interface ICreateOrganization extends Omit<IOrganization, "id" | "createdAt" | "updatedAt"> {
  organization_labels: number[];
}

export interface IOrganizationExtended extends IOrganization {
  labels: ILabelRelation[];
}
