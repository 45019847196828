import type {IUseTableGroup} from "../@types/useTableGroup";

import {useState, useCallback} from "react";

import useProjectsByDates from "./useProjects";

const useTableGroup = ({type}: IUseTableGroup) => {
  const [collapsed, setCollapsed] = useState(false);

  const {loading, projects} = useProjectsByDates(type);

  const toggleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return {
    collapsed,
    type,
    loading: loading.current,
    projects,
    toggleCollapse,
  };
};

export default useTableGroup;
