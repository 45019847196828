import styled from 'styled-components'
import { Drawer } from 'antd'

export const CustomDrawer = styled(Drawer)`
  border-top-left-radius: 4px;

  .ant-drawer-body {
    padding: 0 !important;
  }
`
