import type { FC } from 'react'
import type { IStatusBar } from './types'

import { Typography } from 'antd'
import CheckRoundedIcon from 'shared/assets/icons/userGroup copy'

import { BarItem, Wrapper } from './styled'

const StatusBar: FC<IStatusBar> = ({ className, completed, none }) => {
  return (
    <Wrapper className={className}>
      {completed || none ? (
        <div className="flex justify-center w-full items-center">
          {completed ? (
            <div className="flex gap-2 items-center">
              <CheckRoundedIcon color="#2bff4b" size={16} />
              <Typography.Text strong>Completado</Typography.Text>
            </div>
          ) : (
            <div className="w-8 h-1 rounded-full bg-[#B7B7B7]" />
          )}
        </div>
      ) : (
        <>
          <BarItem bg="#FFDA0D" flex="1">
            1
          </BarItem>
          <BarItem bg="#398DFF" flex="1">
            3
          </BarItem>
          <BarItem bg="#2BFF4B" flex="1">
            5
          </BarItem>
          <BarItem bg="#FF4E4E" flex="1">
            2
          </BarItem>
        </>
      )}
    </Wrapper>
  )
}

export default StatusBar
