import { Modal } from 'antd'
import styled from 'styled-components'

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }

  .ant-modal-header {
    padding: 14px 18px;
    border-bottom: 2px solid #ededed;
    margin: 0;
  }

  .ant-modal-body {
    height: 100%;
  }
`

export const Header = styled.div`
  background: #919191;
  color: white;
  font-weight: 600;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`
