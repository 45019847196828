import {personsService} from "shared/services";

export default {
  getFirstPersons: (companyId: number) =>
    personsService
      .find({
        query: {
          company_id: companyId,
          $limit: 20,
        },
      })
      .then(({data}) => data),
  searchPersons: (companyId: number) => (searchValue: string) =>
    personsService
      .find({
        query: {
          company_id: companyId,
          $limit: 20,
          $client: {
            searchValue,
          },
        },
      })
      .then(({data}) => data),
};
