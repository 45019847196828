import type {FieldValues} from "react-hook-form";
import type {IPersonsSelectProps} from "../@types";

import {Select, Spin} from "antd";

import usePersonsSelect from "../lib/usePersonsSelect";

function PersonsSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  error,
  label,
}: IPersonsSelectProps<TFieldValues>) {
  const {value, options, isFetching, handleChange, handleSearch} = usePersonsSelect({
    control,
    name,
  });

  return (
    <div>
      {Boolean(label) && <label className="text-gray-400 text-md font-bold">{label}</label>}
      <Select
        showSearch
        className="block"
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={
          isFetching ? (
            <div className="flex justify-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        options={options}
        placeholder="Busca una persona"
        showArrow={false}
        status={error ? "error" : undefined}
        value={value}
        onChange={handleChange}
        onSearch={handleSearch}
      />
      {Boolean(error) && (
        <span className="text-red-500 text-sm block animate-fade-in">{error}</span>
      )}
    </div>
  );
}

export default PersonsSelect;
