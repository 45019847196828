import styled from 'styled-components'
import { Modal } from 'antd'

export const CustomModal = styled(Modal)`
  & .ant-modal-content {
    padding: 0px;
    overflow: hidden;

    .ant-modal-header {
      padding: 20px 24px;
      border-bottom: 2px solid #ededed;
      margin: 0;
    }
  }
`
