import type { ICreatePerson } from '../@types/ModalPerson'

import { yupResolver } from '@hookform/resolvers/yup'
import { message } from 'antd'
import { useForm } from 'react-hook-form'
import { personsService } from 'shared/services'
import { PersonVisibilityTypes } from 'types/dto/IPerson'
import { useAuth } from 'shared/contexts/Auth'

import { createPersonValidation } from './validations/createPerson.validation'
import { createPersonSerialize } from './dto'

const useModalPerson = ({ onCancel }: { onCancel: () => void }) => {
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
  } = useForm<ICreatePerson>({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(createPersonValidation),
    defaultValues: {
      visible_to: PersonVisibilityTypes.ALL,
    },
  })

  const { selectedCompany } = useAuth()

  const handleSubmit = onSubmit(async (data) => {
    await personsService
      .create(
        createPersonSerialize({
          ...data,
          company_id: selectedCompany!.id,
        }),
      )
      .then(() => {
        message.success('Se ha creado la persona')
        onCancel()
      })
      .catch((err) => {
        message.error(err?.message ?? 'Algo ha salido mal.')
      })
  })

  return {
    errors,
    control,
    handleSubmit,
  }
}

export default useModalPerson
