import type { FC } from 'react'

import { FinanceMeetingItem } from './FinanceMeetingItem'

export const FinanceMeetingList: FC = () => {
  return (
    <div className="flex flex-col gap-6">
      {new Array(5).fill(0).map((_, index) => (
        <FinanceMeetingItem key={`meeting-${index}`} />
      ))}
    </div>
  )
}
