import {
  ICreateOrganization,
  OrganizationVisibilities,
  OrganizationVisibilityTypes,
} from 'types/dto/IOrganization'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { organizationsService } from 'shared/services'
import { message } from 'antd'
import { useAuth } from 'shared/contexts/Auth'

const useModalOrganization = ({ onCancel }: { onCancel: () => void }) => {
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
  } = useForm<ICreateOrganization>({
    mode: 'onChange',
    defaultValues: {
      visible_to: OrganizationVisibilityTypes.ALL,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('Campo requerido.'),
        visible_to: yup
          .string()
          .required('Campo requerido.')
          .oneOf(Object.keys(OrganizationVisibilities), 'Seleccione una opción válida.'),
        organization_labels: yup
          .array(yup.number().integer())
          .typeError('Seleccione una opción válida.'),
      }),
    ),
  })

  const { selectedCompany } = useAuth()

  const handleSubmit = onSubmit(async (data) => {
    await organizationsService
      .create({
        ...data,
        company_id: selectedCompany!.id,
      })
      .then(() => {
        message.success('Se ha create la organización')
        onCancel()
      })
      .catch((err) => {
        message.error(err?.message ?? 'No se pudo realizar la acción')
      })
  })

  return {
    errors,
    control,
    handleSubmit,
  }
}

export default useModalOrganization
