import styled, { css } from 'styled-components'
import Input from 'react-phone-input-2'

export const PhoneInput = styled(Input)<{ error?: boolean }>`
  & .form-control {
    width: 100% !important;
    height: 30.4px !important;
  }
  ${({ error }) => {
    if (error)
      return css`
        & .form-control,
        .flag-dropdown {
          border: 1px solid #ff4e4e !important;
        }
      `
  }}
`
