import type { IUseModalTemplateProps } from '../@types/useModalTemplate'
import type { ITemplate } from 'types/dto/ITemplate'

import { useForm } from 'react-hook-form'

const useModalTemplate = (props?: IUseModalTemplateProps) => {
  const { control, handleSubmit: onSubmit } = useForm<ITemplate>()

  const handleSubmit = onSubmit(async (data) => {
    const delay = () => new Promise((r) => setTimeout(r, 3000))

    await delay()

    if (props?.onCreate) {
      props.onCreate({
        id: 1,
        title: 'Plantilla Redes sociales',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      })
    }
  })

  return {
    control,
    handleSubmit,
  }
}

export default useModalTemplate
