import type { FC } from 'react'

import moment from 'moment'
import AppButton from 'shared/components/common/AppButton'
import { GridTable } from 'shared/components/common/GridTable'
import { type IDeliverable, DeliverableKeys, DeliverableProgressKeys } from 'types/dto/IDeliverable'

import { GridCardBase } from './GridCard.base'

export const GridCardDeliverables: FC = () => {
  return (
    <GridCardBase
      actions={
        <div className="flex justify-between items-center">
          <AppButton className="p-0" type="link">
            + Entregable
          </AppButton>
          <AppButton $underline className="p-0" type="link">
            Ver todas
          </AppButton>
        </div>
      }
      content={
        <GridTable<IDeliverable>
          columns={[
            {
              title: 'Titulo',
              dataIndex: DeliverableKeys.title,
              render: (value, _, index) => (
                <span className={index % 2 === 0 ? 'text-red-500' : ''}>{value}</span>
              ),
            },
            {
              title: 'Fecha limite',
              dataIndex: DeliverableKeys.dateEnd,
              render: (value) => moment(value).format('MMM DD'),
            },
            {
              title: 'Progreso',
              dataIndex: DeliverableKeys.progress,
              render: (record) => (
                <>
                  ({record[DeliverableProgressKeys.completed]}/
                  {record[DeliverableProgressKeys.total]})
                </>
              ),
            },
          ]}
          dataSource={[]}
        />
      }
      progress={{
        max: 4,
        progress: 1,
      }}
      title="Entregables"
    />
  )
}
