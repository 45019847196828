import type {Moment} from "moment";
import type {FC} from "react";

import generatePicker, {PickerProps} from "antd/es/date-picker/generatePicker";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import {Control, Controller} from "react-hook-form";
import moment from "moment";

export const AntDatePicker = generatePicker<Moment>(momentGenerateConfig);

const DatePicker: FC<
  PickerProps<Moment> & {
    control: Control<any>;
    label?: string;
    error?: string;
  }
> = ({name, control, label, id, error, onChange: handleChange, ...props}) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name!}
      render={({field: {value, onChange}}) => (
        <div>
          {label && (
            <label className="text-gray-400 block font-bold" htmlFor={id}>
              {label}
            </label>
          )}
          <AntDatePicker
            status={error ? "error" : undefined}
            value={value ? moment(value) : null}
            onChange={(value, dateString) => {
              handleChange && handleChange(value, dateString);
              onChange({target: {value: value?.toDate() ?? null}});
            }}
            {...props}
            name={name}
          />
          {Boolean(error) && (
            <span className="block text-red-500 text-sm animate-fade-in">{error}</span>
          )}
        </div>
      )}
    />
  );
};

export const AppDatePicker: FC<PickerProps<Moment>> = (props) => {
  return <AntDatePicker {...props} />;
};

export default DatePicker;
