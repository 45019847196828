import type { IUseTableGroup } from '../@types/useTableGroup'

import { useState, useCallback } from 'react'

const useTableGroup = ({ type }: IUseTableGroup) => {
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev)
  }, [])

  return {
    collapsed,
    type,
    toggleCollapse,
  }
}

export default useTableGroup
