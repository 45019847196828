import type {ITaskStatus} from "types/dto/ITaskStatus";

import React from "react";
import {taskStatusService} from "shared/services";

export default function useTaskStatuses() {
  const [statuses, setStatuses] = React.useState<ITaskStatus[]>([]);
  const [loading, setLoading] = React.useState(false);

  const loadingRef = React.useRef(false);

  const getStatuses = async () => {
    if (loadingRef.current) return;

    loadingRef.current = true;
    setLoading(true);

    await taskStatusService
      .find()
      .then(({data}) => {
        setStatuses(data);
      })
      .finally(() => {
        setLoading(false);
        loadingRef.current = false;
      });
  };

  React.useEffect(() => {
    getStatuses();
  }, []);

  return {
    statuses,
    loading,
  };
}
