import type {FieldValues, FieldPath, Control} from "react-hook-form";

import {useController} from "react-hook-form";
import React from "react";
import clsx from "clsx";

export interface TitleInputProps<TFieldValues extends FieldValues = FieldValues> {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  saveValue: (value: string) => void;
}

export default function TitleInput<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  saveValue,
}: TitleInputProps<TFieldValues>) {
  const [isEditing, setIsEditing] = React.useState(false);

  const {field} = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  if (isEditing) {
    return (
      <input
        autoFocus
        className={clsx(
          "text-2xl",
          "font-bold",
          "mb-5",
          "rounded-md",
          "p-1",
          "focus:border",
          "outline-none",
          "hover:outline-none",
          "w-full",
          "whitespace-normal",
          "overflow-hidden",
          "text-ellipsis",
          "block",
        )}
        name={name}
        type="text"
        value={field.value}
        onBlur={() => {
          setIsEditing(false);
          saveValue(field.value);
        }}
        onChange={field.onChange}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            setIsEditing(false);
            saveValue(field.value);
          }
        }}
      />
    );
  }

  return (
    <h2
      className={clsx(
        "text-2xl",
        "font-bold",
        "mb-5",
        "rounded-md",
        "p-1",
        "cursor-text",
        "hover:border",
        "w-full",
        "overflow-hidden",
        "block",
        "text-ellipsis",
        "whitespace-normal",
      )}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {field.value}
    </h2>
  );
}
