import type {FC} from "react";

import {Button, Divider, Popover} from "antd";
import AppButton from "shared/components/common/AppButton";
import {AppDatePicker} from "shared/components/common/DatePicker";

import useDeliveryDateFilter from "../lib/useDeliveryDateFilter";

export const DeliveryDateFilter: FC = () => {
  const {dates, handleChange, handleConfirm, handleReset} = useDeliveryDateFilter();

  return (
    <Popover
      content={
        <div className="flex flex-col">
          <AppDatePicker
            allowClear={false}
            placeholder="Desde"
            value={dates?.start}
            onChange={handleChange("start")}
          />

          <Divider style={{margin: 0}}>
            <span className="text-sm">Hasta</span>
          </Divider>

          <AppDatePicker
            allowClear={false}
            placeholder="Hasta"
            value={dates.end}
            onChange={handleChange("end")}
          />

          <Divider className="my-4" />

          <AppButton type="primary" onClick={handleConfirm}>
            Filtrar
          </AppButton>

          <AppButton className="mt-2" onClick={handleReset}>
            Resetear
          </AppButton>
        </div>
      }
      showArrow={false}
      trigger="click"
    >
      <Button type="text">Fecha de entrega</Button>
    </Popover>
  );
};
