import type { FC } from 'react'
import type { IFooterProps, IModalFormProps } from '../@types/ModalForm'

import _ from 'lodash'

import AppButton from '../../AppButton'
import useModalForm from '../lib/useModalForm'

import { CustomModal } from './styles/ModalForm.styled'

const defaultFooterProps: IFooterProps = {
  cancelText: 'Cancelar',
  submitText: 'Ok',
  showCancelButton: true,
}

export const ModalForm: FC<IModalFormProps> = ({
  title,
  children,
  closable = true,
  open,
  centered,
  footerProps,
  width,
  onCancel,
  onSubmit,
}) => {
  const { cancelText, submitText, showCancelButton, cancelButtonProps, okButtonProps } = _.merge(
    {},
    defaultFooterProps,
    footerProps ?? {},
  )

  const { isSubmit, handleSubmit } = useModalForm({
    onSubmit,
  })

  return (
    <CustomModal
      destroyOnClose
      centered={centered}
      closable={closable}
      footer={false}
      open={open}
      title={<h3 className="font-bold text-lg">{title}</h3>}
      width={width}
      onCancel={onCancel}
    >
      <form onSubmit={handleSubmit}>
        <div className="py-4 px-8">{children}</div>
        <div className="py-4 px-8 flex justify-end bg-[#F4F7F9] items-center gap-3 border-t">
          {showCancelButton && (
            <AppButton
              $white
              type="primary"
              onClick={() => onCancel && onCancel()}
              {...(cancelButtonProps ?? {})}
            >
              {cancelText}
            </AppButton>
          )}
          <AppButton
            $success
            htmlType="submit"
            loading={isSubmit}
            type="primary"
            {...(okButtonProps ?? {})}
          >
            {submitText}
          </AppButton>
        </div>
      </form>
    </CustomModal>
  )
}
