import type { FC } from 'react'

import AppButton from '../../AppButton'
import useDropzone from '../lib/useDropzone'

import { DropzoneContent, DropzoneWrapper } from './styles/DropZone.styled'

export const Dropzone: FC = () => {
  const { dragging, componentId, handleClickUpload, ...events } = useDropzone()

  return (
    <DropzoneWrapper borderColor={dragging ? '#4DBE85' : '#707070'} {...events}>
      <DropzoneContent>
        <div className="flex gap-6 justify-center items-center">
          <AppButton $success type="primary" onClick={handleClickUpload}>
            Cargue un archivo
          </AppButton>
          <span className="text-md text-gray-500">ó arrastre un archivo aquí</span>
          <input className="hidden" id={`input-file-${componentId}`} type="file" />
        </div>
      </DropzoneContent>
    </DropzoneWrapper>
  )
}
