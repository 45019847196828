import type { IBaseIconProps } from './types'

import { FC } from 'react'

const CardContactIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      data-name="Component 62 – 1"
      height={size}
      viewBox="0 0 22.223 16.021"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.63,4.25H4.092A2.841,2.841,0,0,0,1.25,7.092V17.429a2.841,2.841,0,0,0,2.842,2.842H20.63a2.841,2.841,0,0,0,2.842-2.842V7.092A2.841,2.841,0,0,0,20.63,4.25ZM9.2,12.543a2.326,2.326,0,1,0-2.129,0,.763.763,0,0,0-.315.082l-.886.442a1.809,1.809,0,0,0-1,1.618V15.6a.775.775,0,0,0,.775.775h5.168a.775.775,0,0,0,.775-.775v-.878a1.81,1.81,0,0,0-1.076-1.654l-1.029-.456a.783.783,0,0,0-.283-.066Zm4.714,2.3H19.08a.775.775,0,1,0,0-1.55H13.912a.775.775,0,0,0,0,1.55Zm0-3.618H16.5a.775.775,0,1,0,0-1.55H13.912a.775.775,0,0,0,0,1.55Z"
        data-name="Path 148"
        fill={color}
        fillRule="evenodd"
        id="Path_148"
        transform="translate(-1.25 -4.25)"
      />
    </svg>
  )
}

export default CardContactIcon
