import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'

import useNotes from '../lib/useNotes'

import { EditorHolder } from './styles/Notes.styled'

export const Notes: FC = () => {
  const { componentId } = useNotes()

  return (
    <div className="border border-slate-200 rounded-b-md p-5 bg-yellow-100">
      <EditorHolder id={componentId} />
      <div className="flex justify-end gap-3">
        <AppButton $white $hovered={false} type="primary">
          Cancelar
        </AppButton>
        <AppButton $success $hovered={false} type="primary">
          Guardar
        </AppButton>
      </div>
    </div>
  )
}
