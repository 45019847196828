import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useAuth } from 'shared/contexts/Auth'
import { authenticationService } from 'shared/services'
import { message } from 'antd'
import { IUser } from 'types/dto/IUser'

export enum FormSteps {
  INPUT_PHONE,
  INPUT_CODE,
}

const useSmsStrategy = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formStep, setFormStep] = useState<FormSteps>(FormSteps.INPUT_PHONE)
  const [otpExpiredAt, setOtpExpiredAt] = useState(0)

  const { login } = useAuth()

  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    getValues,
    setValue,
    setError,
    reset,
  } = useForm<{ phone: string; otp_phone_token: string; formStep: FormSteps }>({
    defaultValues: {
      formStep: FormSteps.INPUT_PHONE,
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        formSTep: yup.number(),
        phone: yup
          .string()
          .required('Campo requerido.')
          .length(12, 'Ingrese un número de teléfono'),
        otp_phone_token: yup
          .string()
          .matches(/^\d+$/, 'Ingrese un código válido')
          .when('formStep', {
            is(v: FormSteps) {
              return v === FormSteps.INPUT_CODE
            },
            then(schema) {
              return schema.required('Ingrese el código')
            },
          })
          .typeError('Ingrese el código'),
      }),
    ),
  })

  const handleSubmit = onSubmit(async ({ phone, otp_phone_token }) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    if (!Boolean(otp_phone_token)) {
      await authenticationService
        .requestOtpSms(phone.substring(0, 2), phone.substring(2))
        .catch((err) => {
          if (err.name === 'SuccessError') {
            message.success(err.message)
            setFormStep(FormSteps.INPUT_CODE)
            setValue('formStep', FormSteps.INPUT_CODE)
            setOtpExpiredAt(err.data.expired_at)
          } else {
            message.error(err.message)
          }
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      await authenticationService
        .smsLogin(phone.substring(0, 2), phone.substring(2), otp_phone_token + '')
        .then((res) => {
          login(res.user as IUser)
        })
        .catch((err) => {
          const { errors } = err

          setError('otp_phone_token', {
            message: errors.otp_phone_token,
          })
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  })

  const requestNewOtp = (e: any) => {
    e.preventDefault()

    const dataForm = getValues()

    reset({
      phone: dataForm.phone,
      formStep: FormSteps.INPUT_PHONE,
      otp_phone_token: undefined,
    })

    handleSubmit(e)

    setValue('formStep', FormSteps.INPUT_CODE)
  }

  return {
    control,
    errors,
    isSubmitting,
    formStep,
    otpExpiredAt,
    handleSubmit,
    requestNewOtp,
  }
}

export default useSmsStrategy
