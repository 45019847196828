import type {IProjectExtended} from "types/dto/IProject";

import {useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "shared/contexts/Auth";
import {projectsService} from "shared/services";
import {useLocation} from "react-router-dom";

const useProjectsByDates = (dateType: "overdue" | "today" | "tomorrow" | "future") => {
  const [projects, setProjects] = useState<IProjectExtended[]>([]);

  const loading = useRef(false);
  const location = useLocation();

  const {selectedCompany} = useAuth();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getProjects = async () => {
    if (loading.current) return;

    loading.current = true;
    const orgId = search.get("orgId");
    const boardId = search.get("boardId");
    const deliveryStart = search.get("dStart");
    const deliveryEnd = search.get("dEnd");

    let deliveryDateFilters = {};

    if (deliveryStart && deliveryEnd) {
      deliveryDateFilters = {
        $and: [
          {
            end_date: {$gte: `${deliveryStart} 00:00:00`},
          },
          {
            end_date: {$lte: `${deliveryEnd} 23:59:59`},
          },
        ],
      };
    } else if (deliveryStart && !deliveryEnd) {
      deliveryDateFilters = {
        end_date: {$gte: `${deliveryStart} 00:00:00`},
      };
    } else if (!deliveryStart && deliveryEnd) {
      deliveryDateFilters = {
        end_date: {$lte: `${deliveryEnd} 23:59:59`},
      };
    }

    await projectsService
      .find<IProjectExtended>({
        query: {
          company_id: selectedCompany!.id,
          ...(orgId ? {organization_id: +orgId} : {}),
          ...(boardId ? {project_board_id: +boardId} : {}),
          ...deliveryDateFilters,
          $limit: 20,
          $client: {
            dateType: dateType,
          },
        },
      })
      .then(({data}) => {
        setProjects(data);
      })
      .finally(() => {
        loading.current = false;
      });
  };

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType, search]);

  return {
    projects,
    loading,
  };
};

export default useProjectsByDates;
