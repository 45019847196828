import {useState} from "react";

const useAddNew = () => {
  const [isAddNew, setIsAddNew] = useState(false);

  const handleAddNew = () => {
    if (isAddNew) return;

    setIsAddNew(true);
  };

  const confirm = () => {
    // setIsAddNew(false);
  };

  const cancel = () => {
    setIsAddNew(false);
  };

  return {
    isAddNew,
    confirm,
    cancel,
    handleAddNew,
  };
};

export default useAddNew;
