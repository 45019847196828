import type { FC } from 'react'

import useDocumentsList from '../lib/useDocumentsList'

export const DocumentsList: FC = () => {
  const { componentId } = useDocumentsList()

  return (
    <div>
      <div id={componentId} />
    </div>
  )
}
