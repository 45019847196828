import type {FieldValues} from "react-hook-form";
import type {ILabelsInputProps} from "../@types/LabelsInput";

import {Divider, Select, Spin, Tag} from "antd";
import {CloseOutlined} from "@ant-design/icons";

import useLabelsFetcher from "../lib/useLabelsFetcher";

import {AddNewLabel} from "./AddNewLabel";

const {Option} = Select;

export function LabelsInput<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label = "Etiquetas",
  allowCreate = true,
  entities = [],
  error,
}: ILabelsInputProps<TFieldValues>) {
  const {values, fetching, labels, getTagProps, handleChange, onCreateLabel, debounceFetcher} =
    useLabelsFetcher<TFieldValues>({
      control,
      name,
      entities,
    });

  return (
    <div>
      <label className="text-gray-400 font-bold text-md">{label}</label>
      <Select
        labelInValue
        className="block"
        dropdownRender={(items) => {
          return (
            <>
              {items}
              {allowCreate && (
                <div>
                  <Divider className="my-2 mx-0" />
                  <div className="pb-3 px-4">
                    <AddNewLabel entities={entities} onCreateLabel={onCreateLabel} />
                  </div>
                </div>
              )}
            </>
          );
        }}
        filterOption={false}
        mode="multiple"
        notFoundContent={
          fetching ? (
            <div className="flex w-full items-center justify-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        tagRender={({label, value, ...props}) => {
          const {bgColor, fontColor} = getTagProps(value);

          return (
            <Tag
              closeIcon={<CloseOutlined style={{color: fontColor}} />}
              {...props}
              color={bgColor}
              style={{
                color: fontColor,
              }}
            >
              {label}
            </Tag>
          );
        }}
        value={values}
        onChange={handleChange}
        onSearch={debounceFetcher}
      >
        {labels.map(({id, name, color_background, label_color_text}) => {
          return (
            <Option key={`label-index-${id}`} label={name} value={id}>
              <Tag
                color={color_background}
                style={{
                  color: label_color_text,
                }}
              >
                {name}
              </Tag>
            </Option>
          );
        })}
      </Select>
      {Boolean(error) && <span className="block text-red-500 animate-fade-in">{error}</span>}
    </div>
  );
}
