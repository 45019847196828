import {projectBoardsService} from "shared/services";

export default {
  getBoards: async (companyId: number) => {
    return await projectBoardsService
      .find({
        query: {
          company_id: companyId,
        },
      })
      .then(({data}) => data);
  },
};
