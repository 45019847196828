import type {FC} from "react";

import PageHeadWrapper from "shared/components/common/PageHeadWrapper";
import AppButton from "shared/components/common/AppButton";
import AppSelect from "shared/components/common/AppSelect";
import {Space} from "antd";
import KanbanIcon from "shared/assets/icons/kanban";
import GantIcon from "shared/assets/icons/gant";
import {CalendarOutlined as CalendarIcon, UserOutlined as UserIcon} from "@ant-design/icons";
import ListIcon from "shared/assets/icons/list";
import CustomerIcon from "shared/assets/icons/customer";
import FlagIcon from "shared/assets/icons/flag";
import PageContent from "shared/components/common/PageContent";

import useProject from "../lib/useProject";

import {HeadActions, SelectWithSuffix} from "./styles/projects.styled";
import {ViewsKeys} from "./projects.keys";
import Kanban from "./components/Kanban";
import Phases from "./components/Phases";
import Date from "./components/Date";
import {ModalProject} from "./components/ModalProject";
import {OrganizationFilter} from "./components/OrganizationsFilter";
import {BoardFilter} from "./components/BoardFilter";
import {DeliveryDateFilter} from "./components/DeliveryDateFilter";

const PROJECT_VIEWS = {
  [ViewsKeys.DATE]: <Date />,
  [ViewsKeys.PHASES]: <Phases />,
  [ViewsKeys.KANBAN]: <Kanban />,
  [ViewsKeys.DIAG_GANT]: <div>Gant view</div>,
};

export const ProjectsBase: FC = () => {
  const {currentView, openModalProject, handleChangeView, handleChangeModalProject} = useProject();

  return (
    <div>
      <ModalProject open={openModalProject} onClose={() => handleChangeModalProject(false)} />
      <PageHeadWrapper>
        <HeadActions>
          <Space size="middle">
            <AppSelect
              defaultValue={ViewsKeys.DATE}
              options={[
                {label: "Fecha", value: ViewsKeys.DATE, icon: <CalendarIcon />},
                {
                  label: "Fases",
                  value: ViewsKeys.PHASES,
                  icon: <ListIcon color="inherit" size={15} />,
                },
                {
                  label: "Kanban",
                  value: ViewsKeys.KANBAN,
                  icon: <KanbanIcon color="inherit" size={15} />,
                },
                {
                  label: "Diag. Gant",
                  value: ViewsKeys.DIAG_GANT,
                  icon: <GantIcon color="inherit" size={15} />,
                },
              ]}
              value={currentView}
              width={145}
              onChange={(key: ViewsKeys) => handleChangeView(key)}
            />
            <AppButton
              $success
              size="middle"
              type="primary"
              width={125}
              onClick={() => handleChangeModalProject(true)}
            >
              + Project
            </AppButton>
          </Space>
          <Space size="middle">
            <SelectWithSuffix>
              <div>
                <KanbanIcon color="inherit" size={15} />
              </div>
              <BoardFilter />
            </SelectWithSuffix>

            <SelectWithSuffix>
              <div>
                <CustomerIcon color="inherit" size={15} />
              </div>
              <OrganizationFilter />
            </SelectWithSuffix>
            <SelectWithSuffix>
              <div>
                <FlagIcon color="inherit" size={15} />
              </div>
              <DeliveryDateFilter />
            </SelectWithSuffix>
            <SelectWithSuffix>
              <div>
                <UserIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="persons"
                options={[{label: "Personas", value: "persons"}]}
                placeholder="Personas"
                width={150}
              />
            </SelectWithSuffix>
          </Space>
        </HeadActions>
      </PageHeadWrapper>
      <PageContent>{currentView ? PROJECT_VIEWS[currentView] : null}</PageContent>
    </div>
  );
};
