import type { IAppRoute } from './type'

import { Login } from 'modules/Login'

export const loginRoutes: IAppRoute[] = [
  {
    path: '/login',
    element: <Login />,
    requireAuth: false,
  },
]
