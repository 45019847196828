import { useState } from 'react'

const useGridCardBase = () => {
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev)
  }

  return {
    collapsed,
    toggleCollapse,
  }
}

export default useGridCardBase
