import type {FC} from "react";

import {Typography} from "antd";
import FlagIcon from "shared/assets/icons/flag";
import RacingFlag from "shared/assets/icons/racingFlag";
import moment, {Moment} from "moment";

const TaskDates: FC<{start_at?: string | Date | Moment; end_at?: string | Date | Moment}> = ({
  start_at,
  end_at,
}) => {
  return (
    <div className="flex justify-between px-6 py-4 items-center h-full">
      <div className="flex gap-2 items-center">
        <RacingFlag color="#919191" size={20} />
        <Typography.Text>{moment(start_at).format("D [de] MMM")}</Typography.Text>
      </div>
      <div className="w-5 rounded-full h-1 bg-[#B7B7B7]" />
      <div className="flex gap-2 items-center">
        <FlagIcon color="#919191" size={20} />
        <Typography.Text strong>{moment(end_at).format("D [de] MMM")}</Typography.Text>
      </div>
    </div>
  );
};

export default TaskDates;
