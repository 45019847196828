import type { IBaseIconProps } from './types'

import { FC } from 'react'

const TextIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      fill={color || '#fff'}
      height={size || 30}
      viewBox="0 0 24 24"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m14.3 2h-4.7c-1 0-1.9.8-1.9 1.9v.9c0 1 .8 1.9 1.9 1.9h4.7c1 0 1.9-.8 1.9-1.9v-.9c0-1.1-.8-1.9-1.9-1.9z" />
      <path d="m17.2 4.8c0 1.6-1.3 2.9-2.9 2.9h-4.7c-1.6 0-2.9-1.3-2.9-2.9 0-.6-.6-.9-1.1-.7-1.4.8-2.3 2.3-2.3 4v9.4c0 2.5 2 4.5 4.5 4.5h8.5c2.5 0 4.5-2 4.5-4.5v-9.4c0-1.7-1-3.2-2.4-3.9-.6-.3-1.2.1-1.2.6zm-4.8 12.1h-4.4c-.4 0-.8-.3-.8-.8 0-.4.3-.7.8-.7h4.4c.4 0 .8.3.8.7-.1.5-.4.8-.8.8zm2.6-4h-7c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h7c.4 0 .8.3.8.8s-.4.8-.8.8z" />
    </svg>
  )
}

export default TextIcon
