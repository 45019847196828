import { PersonVisibilities } from 'types/dto/IPerson'
import * as yup from 'yup'

export const createPersonValidation = yup.object().shape({
  first_name: yup.string().required('Campo requerido.'),
  last_name: yup.string().required('Campo requerido.'),
  organization_id: yup
    .number()
    .required('Campo requerido')
    .typeError('Seleccione una opción válida.'),
  person_labels: yup.array(yup.number().typeError('Seleccione una opción válida.')),
  primary_phone: yup
    .string()
    .matches(/^\d+$/, 'Ingrese un teléfono válido.')
    .length(12, 'Ingrese un teléfono')
    .required('Campo requerido.')
    .min(12, 'Ingrese un teléfono válido.'),
  person_extra_phones: yup.array(
    yup.object().shape({
      type: yup
        .string()
        .oneOf(['work', 'personal', 'other'], 'Seleccione una opción válida.')
        .required('Campo requerido.'),
      phone: yup
        .string()
        .matches(/^\d+$/, 'Ingrese un teléfono válido.')
        .length(12, 'Ingrese un teléfono')
        .required('Campo requerido.'),
    }),
  ),
  primary_email: yup.string().email('Ingres un email válido.').required('Campo requerido.'),
  person_extra_emails: yup.array(
    yup.object().shape({
      type: yup
        .string()
        .oneOf(['work', 'personal', 'other'], 'Seleccione una opción válida.')
        .required('Campo requerido.'),
      email: yup.string().email('Ingrese un email válido.').required('Campo requerido.'),
    }),
  ),
  owner_user_id: yup.number().required('Campo requerido.'),
  visible_to: yup
    .string()
    .oneOf(Object.keys(PersonVisibilities), 'Seleccione una opción válida.')
    .required('Campo requerido.'),
})
