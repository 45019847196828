import type {FieldValues} from "react-hook-form";
import type {IOrganizationSelectProps} from "../@types";

import {Select, Spin} from "antd";

import useOrganizationFetcher from "../lib/useOrganizationFetcher";

export function OrganizationSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  error,
  label,
  byPersonId,
}: IOrganizationSelectProps<TFieldValues>) {
  const {value, options, isFetching, handleChange, debounceFetcher} =
    useOrganizationFetcher<TFieldValues>({
      control,
      name,
      byPersonId,
    });

  return (
    <div>
      {Boolean(label) && <label className="text-gray-400 text-md font-bold">{label}</label>}
      <Select
        showSearch
        className="block"
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={
          isFetching ? (
            <div className="flex justify-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        options={options}
        placeholder="Busca una organización"
        showArrow={false}
        status={error ? "error" : undefined}
        value={value}
        onChange={handleChange}
        onSearch={debounceFetcher}
      />
      {Boolean(error) && (
        <span className="text-red-500 text-sm block animate-fade-in">{error}</span>
      )}
    </div>
  );
}
