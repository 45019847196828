import styled from 'styled-components'
import { Progress as AntProgress } from 'antd'

export const CustomProgress = styled(AntProgress)`
  margin: 0;
  overflow: visible;

  & .ant-progress-inner,
  .ant-progress-bg {
    overflow: visible;
    border-radius: 3px !important;
  }

  & .ant-progress-bg {
    overflow: visible;
  }

  & .ant-progress-bg::after {
    content: '';
    position: absolute;
    width: 6px;
    border-radius: 12px;
    height: 22px;
    background: white;
    box-shadow: 0px 0px 3px ${({ theme }) => theme.colors.grey};
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid ${({ theme }) => theme.colors.light};
  }
`
