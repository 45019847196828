import type { FC } from 'react'
import type { ISearchResultPopover } from './types'

import { Popover, Typography } from 'antd'
import TextIcon from 'shared/assets/icons/text'

import { Content, Menu, ResultContent } from './styled'

const SearchResultPopover: FC<ISearchResultPopover> = ({ children }) => {
  return (
    <Popover
      content={
        <Content>
          <Menu
            items={[
              {
                key: '1',
                label: 'Proyectos',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '2',
                label: 'Tratos',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '3',
                label: 'Organizaciones',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '4',
                label: 'Personas',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '5',
                label: 'Actividades',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '6',
                label: 'Productos',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '7',
                label: 'Actividades',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                key: '8',
                label: 'Archivos',
                icon: <TextIcon color="inherit" size={25} />,
              },
              {
                type: 'divider',
              },
              {
                key: '9',
                label: 'Ayuda Rápida',
                icon: <TextIcon color="inherit" size={25} />,
              },
            ]}
          />
          <ResultContent>
            <div style={{ flex: 1 }}>
              <Typography.Paragraph strong>
                Todos los resultados de la búsqueda
              </Typography.Paragraph>
            </div>
            <div style={{ flex: 1 }}>
              <Typography.Paragraph strong>Buscado recientemente</Typography.Paragraph>
            </div>
          </ResultContent>
        </Content>
      }
      // open={open}
      overlayInnerStyle={{ padding: 0, overflow: 'hidden' }}
      placement="bottom"
      showArrow={false}
      trigger="focus"
    >
      {children}
    </Popover>
  )
}

export default SearchResultPopover
