import type { FC } from 'react'

import { Space } from 'antd'
import AppSelect from 'shared/components/common/AppSelect'
import PageHeadWrapper from 'shared/components/common/PageHeadWrapper'
import KanbanIcon from 'shared/assets/icons/kanban'
import GantIcon from 'shared/assets/icons/gant'
import { CalendarOutlined as CalendarIcon, UserOutlined as UserIcon } from '@ant-design/icons'
import AppButton from 'shared/components/common/AppButton'
import CustomerIcon from 'shared/assets/icons/customer'
import FlagIcon from 'shared/assets/icons/flag'
import PageContent from 'shared/components/common/PageContent'

import { ViewsKeys } from '../lib/tasks.keys'
import useTaskBase from '../lib/useTasksBase'

import { HeadActions, SelectWithSuffix } from './tasks.styled'
import Date from './components/Date'
import Kanban from './components/Kanban'

const TASKS_VIEWS = {
  [ViewsKeys.DATE]: <Date />,
  [ViewsKeys.KANBAN]: <Kanban />,
  [ViewsKeys.DIAG_GANT]: <div>Gant view</div>,
}

const TasksBase: FC = () => {
  const { currentView, handleChangeView } = useTaskBase()

  return (
    <div>
      <PageHeadWrapper>
        <HeadActions>
          <Space size="middle">
            <AppSelect
              defaultValue={ViewsKeys.DATE}
              options={[
                { label: 'Fecha', value: ViewsKeys.DATE, icon: <CalendarIcon /> },
                {
                  label: 'Kanban',
                  value: ViewsKeys.KANBAN,
                  icon: <KanbanIcon color="inherit" size={15} />,
                },
                {
                  label: 'Diag. Gant',
                  value: ViewsKeys.DIAG_GANT,
                  icon: <GantIcon color="inherit" size={15} />,
                },
              ]}
              value={currentView}
              width={145}
              onChange={(key: ViewsKeys) => handleChangeView(key)}
            />
            <AppButton $success size="middle" type="primary" width={125}>
              + Tarea
            </AppButton>
          </Space>
          <Space size="middle">
            <SelectWithSuffix>
              <div>
                <KanbanIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="all"
                options={[
                  { label: 'Todos', value: 'all' },
                  { label: 'Nueva scp' },
                  { label: 'Experience System' },
                  { label: 'CCBQ' },
                  { label: 'BICOSA' },
                ]}
                placeholder="Proyecto"
                width={150}
              />
            </SelectWithSuffix>
            <SelectWithSuffix>
              <div>
                <KanbanIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="all"
                options={[
                  { label: 'Todos', value: 'all' },
                  { label: 'Web' },
                  { label: 'Redes Sociales' },
                  { label: 'Audio Visual' },
                  { label: 'Landing Page' },
                  { label: 'Pauta Digital' },
                  { label: 'Funnel' },
                ]}
                placeholder="Tipo de proyecto"
                width={150}
              />
            </SelectWithSuffix>

            <SelectWithSuffix>
              <div>
                <CustomerIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="customers"
                options={[{ label: 'Clientes', value: 'customers' }]}
                placeholder="Clientes"
                width={150}
              />
            </SelectWithSuffix>
            <SelectWithSuffix>
              <div>
                <FlagIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="delivery-date"
                options={[{ label: 'Fecha entrega', value: 'delivery-date' }]}
                placeholder="Fecha entrega"
                width={150}
              />
            </SelectWithSuffix>
            <SelectWithSuffix>
              <div>
                <UserIcon color="inherit" size={15} />
              </div>
              <AppSelect
                bordered={false}
                defaultValue="persons"
                options={[{ label: 'Personas', value: 'persons' }]}
                placeholder="Personas"
                width={150}
              />
            </SelectWithSuffix>
          </Space>
        </HeadActions>
      </PageHeadWrapper>
      <PageContent>{currentView ? TASKS_VIEWS[currentView] : null}</PageContent>
    </div>
  )
}

export default TasksBase
