import type { FC } from 'react'

import { TreeFiles } from './TreeFiles'
import { DocumentsList } from './DocumentsList'

export const Documents: FC = () => {
  return (
    <div className="flex h-full w-full divide-x">
      <div className="w-[250px] h-full p-5">
        <TreeFiles indicator />
      </div>
      <div className="flex-1 shadow-md h-full p-4">
        <DocumentsList />
      </div>
    </div>
  )
}
