import type {SelectProps} from "antd";
import type {FieldValues} from "react-hook-form";
import type {IUseDealsSelectProps} from "../@types";

import {useController} from "react-hook-form";
import {useEffect, useState} from "react";
import {useAuth} from "shared/contexts/Auth";

import api from "../api";

import {serializeOptions} from "./utils";
import useDealsSearch from "./useDealsSearch";

function useDealSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
}: IUseDealsSelectProps<TFieldValues>) {
  const [options, setOptions] = useState<SelectProps["options"]>([]);

  const {selectedCompany} = useAuth();

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const {deals, isFetching, handleSearch} = useDealsSearch();

  const handleChange = (value: number) => {
    onChange([value]);
  };

  useEffect(() => {
    api.getFirstDeals(selectedCompany!.id).then((res) => {
      setOptions(serializeOptions(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOptions(serializeOptions(deals));
  }, [deals]);

  return {
    value,
    options,
    isFetching,
    handleChange,
    handleSearch,
  };
}

export default useDealSelect;
