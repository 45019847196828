import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const EmailIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      viewBox="0 0 32 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 320" id="Group_320" transform="translate(-1331 -226)">
        <g data-name="Group 319" id="Group_319" transform="translate(-5 8)">
          <g
            data-name="Rectangle 1261"
            fill="none"
            id="Rectangle_1261"
            strokeWidth="2"
            transform="translate(1336 218)"
          >
            <rect height="20" rx="5" stroke="none" width="32" />
            <rect fill="none" height="18" rx="4" width="30" x="1" y="1" />
          </g>
          <path
            d="M532.984,106.848l11.482,8.252,9.878-8.252"
            data-name="Path 254"
            fill="none"
            id="Path_254"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            transform="translate(808.207 116.613)"
          />
        </g>
      </g>
    </svg>
  )
}

export default EmailIcon
