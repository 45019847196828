import type {IAuthContext, IAuthProvider} from "./types";
import type {IUser} from "types/dto/IUser";
import type {ICompany} from "types/dto/ICompany";

import {FC, useEffect} from "react";
import {createContext, useContext} from "react";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {authenticationService} from "shared/services";
import {EntityStatus} from "types/dto/IBase";

const INITIAL_STATE: IAuthContext = {
  isAuth: false,
  user: null,
  loading: true,
  selectedCompany: null,
  login() {},
  logout() {},
};

export const AuthContext = createContext<IAuthContext>(INITIAL_STATE);

export const AuthProvider: FC<IAuthProvider> = ({children}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedCompany] = useState<ICompany>({
    id: 1,
    name: "EME AGENCIA DIGITAL SAS",
    status: EntityStatus.ACTIVE,
    subscription_trial_start_date: null,
    subscription_trial_end_date: null,
    subscription_plan_id: 1,
    subscription_renewal_date: "2123-04-30 11:47:48",
    subscription_amount: 0,
    subscription_included_users: 100000,
    system_storage_file_size_quota_bytes: 2147483647,
    createdAt: "2023-04-05 11:48:08",
    updatedAt: "2023-04-05 11:48:08",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const redirectTo = (type: "auth" | "next") => {
    const currentPath = `${location.pathname}${location.search}`;
    const params = location.search;

    const searchParams = new URLSearchParams(params);

    if (type === "auth") {
      if (currentPath !== "/login") {
        searchParams.set("_redirect", encodeURIComponent(currentPath));

        navigate({
          pathname: "/login",
          search: searchParams.toString(),
        });
      }
    } else {
      const nextUrl = searchParams.get("_redirect");

      if (nextUrl) {
        navigate(decodeURIComponent(nextUrl));
      } else {
        navigate(currentPath !== "/login" ? currentPath : "/");
      }
    }
  };

  const login = (loggedUser: IUser) => {
    setUser(loggedUser);
    setIsAuth(true);

    redirectTo("next");
  };

  const logout = () => {
    localStorage.removeItem("jwtToken");
    setUser(null);
    setIsAuth(false);

    navigate("/login");
  };

  const authFromToken = () => {
    setLoading(true);
    authenticationService
      .jwtLogin()
      .then((res) => {
        setIsAuth(true);
        setUser(res.user);
        redirectTo("next");
      })
      .catch(() => {
        redirectTo("auth");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    authFromToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{isAuth, user, selectedCompany, loading, login, logout}}>
      {loading ? <div>Loading...</div> : null}
      {!loading && (isAuth || location.pathname === "/login") ? children : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
