import type {FC} from "react";

import {ProjectStatus, ProjectTabs} from "./components/Project";
import {ProjectProvider} from "./components/Project/context";

export const ProjectBase: FC = () => {
  return (
    <ProjectProvider>
      <div className="relative">
        <ProjectStatus />
        <ProjectTabs />
      </div>
    </ProjectProvider>
  );
};
