import type {FC} from "react";

import GantIcon from "shared/assets/icons/gant";
import KanbanIcon from "shared/assets/icons/kanban";
import ListIcon from "shared/assets/icons/list";
import AppSelect from "shared/components/common/AppSelect";
import {CalendarOutlined as CalendarIcon} from "@ant-design/icons";
import {useParams} from "react-router-dom";

import {PlanViewKeys} from "../@types/PlanView.keys";
import usePlan from "../lib/usePlan";
import useProjectTasks from "../lib/useProjectTasks";

import {Kanban} from "./components/Kanban";
import {Date} from "./components/Date";
import {GroupedList} from "./components/GroupedList";

export const PLAN_VIEWS = {
  [PlanViewKeys.KANBAN]: (props: any) => <Kanban {...props} />,
  [PlanViewKeys.DATE]: (props: any) => <GroupedList {...props} />,
  [PlanViewKeys.HITOS]: (_props: any) => <div>Hitos</div>,
  [PlanViewKeys.GANT]: (_props: any) => <div>GANT</div>,
};

export const Plan: FC = () => {
  const query = useParams();

  const {currentView, /* collapsedPane, hiddenContent, togglePane, */ handleChangeView} = usePlan();
  const {tasks} = useProjectTasks(Number(query.id));

  return (
    <div className="flex divide-x divide-slate-200 px-4 py-3">
      <div className="p-4 overflow-x-auto beautifull-scrollbar flex-1">
        <div className="mb-4">
          <AppSelect
            defaultValue={PlanViewKeys.KANBAN}
            options={[
              {
                label: "Kanban",
                value: PlanViewKeys.KANBAN,
                icon: <KanbanIcon color="inherit" size={15} />,
              },
              {
                label: "Hitos",
                value: PlanViewKeys.HITOS,
                icon: <ListIcon color="inherit" size={15} />,
              },
              {label: "Fecha", value: PlanViewKeys.DATE, icon: <CalendarIcon />},
              {
                label: "Diag. Gant",
                value: PlanViewKeys.GANT,
                icon: <GantIcon color="inherit" size={15} />,
              },
            ]}
            value={currentView}
            width={145}
            onChange={(key: PlanViewKeys) => handleChangeView(key)}
          />
        </div>
        <div>{!!currentView && PLAN_VIEWS[currentView]({tasks})}</div>
      </div>
    </div>
  );
};
