import React from "react";
import clsx from "clsx";

export interface TitleInputProps {
  value: string;
  saveValue: (value: string) => void;
}

export default function TitleInput({value, saveValue}: TitleInputProps) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (isEditing) {
    return (
      <input
        autoFocus
        className={clsx(
          "text-2xl",
          "font-bold",
          "mb-5",
          "rounded-md",
          "p-1",
          "focus:border",
          "outline-none",
          "hover:outline-none",
          "w-full",
          "whitespace-normal",
          "overflow-hidden",
          "text-ellipsis",
          "block",
        )}
        type="text"
        value={inputValue}
        onBlur={() => {
          setIsEditing(false);
          saveValue(inputValue);
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            setIsEditing(false);
            saveValue(inputValue);
          }
        }}
      />
    );
  }

  return (
    <h2
      className={clsx(
        "text-2xl",
        "font-bold",
        "mb-5",
        "rounded-md",
        "p-1",
        "cursor-text",
        "hover:border",
        "w-full",
        "overflow-hidden",
        "block",
        "text-ellipsis",
        "whitespace-normal",
      )}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {value}
    </h2>
  );
}
