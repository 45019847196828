import type { IPersonsList } from '../@types/usePersons'

import { useEffect, useState } from 'react'
import { personsService } from 'shared/services'

const usePersons = () => {
  const [persons, setPersons] = useState<IPersonsList[]>([])
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    skip: 0,
  })
  const [showModalPerson, setShowModalPerson] = useState(false)

  const getPersons = async () => {
    await personsService
      .find<IPersonsList>({
        query: {
          $client: {
            personsList: 'true',
          },
        },
      })
      .then(({ data, ...pag }) => {
        setPersons(data)
        setPagination(pag)
      })
  }

  const appendPerson = (newPerson: IPersonsList) => {
    setPersons((prev) => prev.concat(newPerson))
  }

  const subscribeEvents = () => {
    personsService.service.on('created', appendPerson)
  }

  const unsubscribeEvents = () => {
    personsService.service.removeListener('created', appendPerson)
  }

  const handleModalPerson = (status: boolean) => {
    setShowModalPerson(status)
  }

  useEffect(() => {
    getPersons()
    subscribeEvents()

    return () => {
      unsubscribeEvents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    persons,
    pagination,
    showModalPerson,
    handleModalPerson,
  }
}

export default usePersons
