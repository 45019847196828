import type { FC } from 'react'

import moment from 'moment'
import AppButton from 'shared/components/common/AppButton'
import { GridTable } from 'shared/components/common/GridTable'
import { PersonNameBadgeGrouped } from 'shared/components/common/PersonNameBadge'
import { ActivityKeys, type IActivity } from 'types/dto/IActivity'
import { Radio } from 'antd'

import { ActivityModal } from '../../Modals/ActivityModal'
import useModalActivity from '../lib/useModalActivity'

import { GridCardBase } from './GridCard.base'

export const GridCardActivities: FC = () => {
  const { open, toggleModal } = useModalActivity()

  return (
    <>
      <ActivityModal open={open} onClose={() => toggleModal(false)} />
      <GridCardBase
        actions={
          <div className="flex justify-between items-center">
            <AppButton className="p-0" type="link" onClick={() => toggleModal(true)}>
              + Actividad
            </AppButton>
            <AppButton $underline className="p-0" type="link">
              Ver todas
            </AppButton>
          </div>
        }
        content={
          <GridTable<IActivity>
            columns={[
              {
                title: 'Titulo',
                dataIndex: ActivityKeys.title,
                render: (value, _, index) => (
                  <div className="flex gap-2">
                    <Radio />
                    <span className={index % 2 === 0 ? 'text-red-500' : ''}>{value}</span>
                  </div>
                ),
              },
              {
                title: 'Miembro',
                dataIndex: ActivityKeys.member,
                render: (value) => (
                  <PersonNameBadgeGrouped
                    align="center"
                    persons={[{ name: value, color: 'purple' }]}
                    show={1}
                  />
                ),
              },
              {
                title: 'Timeline',
                dataIndex: ActivityKeys.timeline,
                render: (value) => moment(value).format('MMM DD'),
              },
            ]}
            dataSource={[]}
          />
        }
        progress={{
          max: 4,
          progress: 1,
        }}
        title="Activities"
      />
    </>
  )
}
