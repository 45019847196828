import styled from 'styled-components'
import { Menu as AntMenu } from 'antd'

export const Content = styled.div`
  display: grid;
  width: 800px;
  grid-template-areas: 'side content';
  grid-template-columns: 230px 1fr;
`

export const Menu = styled(AntMenu)`
  background: #f4f7f9 !important;
  padding: 0 14px;
  height: 100%;
  /* width: 230px; */
  grid-area: 'side';

  & > .ant-menu-item {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    height: 45px;
    font-size: 1.2em;
    font-weight: 600;

    &.ant-menu-item-selected {
      background: ${({ theme }) => theme.colors['info-light']};
      color: ${({ theme }) => theme.colors.info};
      & > svg {
        fill: ${({ theme }) => theme.colors.info};
      }
    }

    & > .ant-menu-title-content {
      margin-left: 12px;
    }
  }
`

export const ResultContent = styled.div`
  padding: 18px;
  grid-area: 'content';
  display: flex;
  flex-direction: column;
`
