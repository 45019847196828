import type {ITableGroupContext} from "../../@types";
import type {FC, ReactNode} from "react";

import {createContext, useContext} from "react";

import {TableGroupType} from "../TableGroup.keys";

const TableGroupContext = createContext<ITableGroupContext>({
  collapsed: false,
  type: TableGroupType.TODAY,
  loading: false,
  projects: [],
  toggleCollapse: () => {},
});

export const useTableGroupContext = () => useContext(TableGroupContext);

export const TableGroupProvider: FC<{children: ReactNode; values: ITableGroupContext}> = ({
  children,
  values,
}) => {
  return <TableGroupContext.Provider value={values}>{children}</TableGroupContext.Provider>;
};
