import type { FC } from 'react'

import { ActivityForm } from 'modules/Activities'

export const Activities: FC = () => {
  return (
    <div className="border border-slate-200 rounded-b-md h-[680px] overflow-hidden">
      <ActivityForm closable={false} />
    </div>
  )
}
