import type {
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";

import React from "react";
import {arrayMove} from "@dnd-kit/sortable";
import _ from "lodash";

import {TreeData} from "../@types";
import {useTreeTasksListContext} from "../context";

import {
  KeyParent,
  buildTree,
  findContainer,
  flattenTree,
  getDataItem,
  getProjection,
} from "./utilities";

export default function useTreeListSectionDirective(containerKeys: string[]) {
  const [clonedItems, setClonedItems] = React.useState<Record<UniqueIdentifier, TreeData[]> | null>(
    null,
  );

  const {
    dataSource,
    idField = "id",
    columns,
    indentationWidth = 39,
    offsetLeft,
    overId,
    activeId,
    setDataSource,
    onDrop,
    setOverId,
    setActiveId,
    setOffsetLeft,
  } = useTreeTasksListContext();

  const activeItem = React.useMemo(() => {
    if (!activeId) return null;

    return getDataItem(activeId, idField, dataSource);
  }, [activeId, dataSource, idField]);

  const projected = React.useMemo(() => {
    if (activeId && overId && dataSource) {
      const overContainer = findContainer(overId, idField, dataSource, containerKeys);
      const activeContainer = findContainer(activeId, idField, dataSource, containerKeys);

      if (!overContainer || !activeContainer) return null;

      return getProjection(
        dataSource,
        activeId,
        overId,
        offsetLeft,
        indentationWidth,
        idField,
        overContainer,
        activeContainer,
      );
    }

    return null;
  }, [activeId, overId, indentationWidth, offsetLeft, dataSource, idField, containerKeys]);

  const onDragStart = ({active: {id: activeId}}: DragStartEvent) => {
    setActiveId(activeId);
    setOverId(activeId);
    setClonedItems(dataSource);

    document.body.style.setProperty("cursor", "grabbing");
  };

  const onDragCancel = () => {
    if (clonedItems) setDataSource(clonedItems);

    setActiveId(null);
    setClonedItems(null);
  };

  const resetState = () => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty("cursor", "");
  };

  const onDragOver = ({active, over}: DragOverEvent) => {
    setOverId(over?.id ?? null);
    // setActiveId(active?.id ?? null);

    const overId = over?.id || null;
    const activeId = active.id;

    if (!overId) return;

    const {depth} = projected || {depth: 0};
    const parentId = projected?.[KeyParent] || null;

    const overContainer = findContainer(overId, idField, dataSource, containerKeys);
    const activeContainer = findContainer(activeId, idField, dataSource, containerKeys);

    if (!overContainer || !activeContainer) return;

    const overItems = _.clone(flattenTree(dataSource[overContainer] ?? []));
    const activeItems = _.clone(flattenTree(dataSource[activeContainer] ?? []));

    const overIndex = overItems.findIndex((item) => item[idField] === overId);
    const activeIndex = activeItems.findIndex((item) => item[idField] === activeId);
    const activeTreeItem = {
      ...activeItems[activeIndex],
      depth,
      [KeyParent]: parentId,
    };

    // activeItems[activeIndex] = activeTreeItem;

    if (activeContainer !== overContainer) {
      setDataSource((items) => {
        if (activeIndex < 0) return items;

        let newIndex: number;

        if (
          overIndex < 0 &&
          (overId + "").startsWith("section-") &&
          (overId + "").replaceAll("section-", "") in items
        ) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: buildTree(
            activeItems.filter(
              (item) => item[idField] !== activeId && item[KeyParent] !== activeId,
            ),
            idField,
          ),
          [overContainer]: buildTree(
            [
              ...overItems.slice(0, newIndex),
              activeTreeItem,
              ...activeItems.filter((item) => item[KeyParent] === activeId),
              ...overItems.slice(newIndex, overItems.length),
            ],
            idField,
          ),
        };
      });
    }
  };

  const onDragEnd = ({active, over}: DragEndEvent) => {
    resetState();

    const overId = over?.id || null;
    const activeId = active.id;

    if (!overId || !projected) {
      resetState();

      return;
    }
    const {depth} = projected;
    const parentId = projected[KeyParent];

    if (overId === parentId || activeId === parentId) {
      resetState();

      return;
    }

    const overContainer = findContainer(overId, idField, dataSource, containerKeys);
    const activeContainer = findContainer(activeId, idField, dataSource, containerKeys);

    if (!overContainer || !activeContainer) {
      resetState();

      return;
    }

    let clonedOverItems = _.clone(flattenTree(dataSource[overContainer] ?? []));
    let clonedActiveItems = _.clone(flattenTree(dataSource[activeContainer] ?? []));

    const overIndex = clonedOverItems.findIndex((item) => item[idField] === overId);
    const activeIndex = clonedActiveItems.findIndex((item) => item[idField] === activeId);
    const activeTreeItem = {
      ...clonedActiveItems[activeIndex],
      depth,
      [KeyParent]: parentId,
    };

    if (overContainer !== activeContainer) {
      clonedActiveItems[activeIndex] = activeTreeItem;
      setDataSource((items) => {
        if (activeIndex < 0) return items;

        let newIndex: number;

        if (
          (overId + "").startsWith("section-") &&
          (overId + "").replaceAll("section-", "") in items
        ) {
          newIndex = clonedOverItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : clonedOverItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: buildTree(
            clonedActiveItems.filter(
              (item) => item[idField] !== activeId && item[KeyParent] !== activeId,
            ),
            idField,
          ),
          [overContainer]: buildTree(
            [
              ...clonedOverItems.slice(0, newIndex),
              activeTreeItem,
              ...clonedActiveItems.filter((item) => item[KeyParent] === activeId),
              ...clonedOverItems.slice(newIndex, clonedOverItems.length),
            ],
            idField,
          ),
        };
      });

      // setDataSource((items) => ({
      //   ...items,
      //   [activeContainer]: clonedActiveItems,
      //   [overContainer]: clonedOverItems,
      // }));
    } else {
      clonedOverItems[activeIndex] = activeTreeItem;
      const sorterItems = arrayMove(clonedOverItems, activeIndex, overIndex);
      const newItems = buildTree(sorterItems, idField);

      setDataSource((items) => ({
        ...items,
        [overContainer]: newItems,
      }));
    }

    if (onDrop) {
      onDrop(activeTreeItem, overIndex, overContainer, parentId);
    }

    setActiveId(null);
  };

  const onDragMove = ({delta}: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  return {
    activeId,
    dataSource,
    idField,
    activeItem,
    columns,
    onDragStart,
    onDragCancel,
    onDragOver,
    onDragEnd,
    onDragMove,
  };
}
