import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const KanbanIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size || 20}
      viewBox="0 0 15 20"
      width={size || 20}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Group 118" transform="translate(-769 -314)">
        <g data-name="Rectangle 58" fill={color} transform="translate(769 314)">
          <rect height="12" stroke="none" width="3" />
          <rect fill="none" height="11" width="4" x="0.5" y="0.5" />
        </g>
        <g data-name="Rectangle 59" fill={color} transform="translate(774 314)">
          <rect height="20" stroke="none" width="3" />
          <rect fill="none" height="19" width="4" x="0.5" y="0.5" />
        </g>
        <g data-name="Rectangle 60" fill={color} transform="translate(779 314)">
          <rect height="15" stroke="none" width="3" />
          <rect fill="none" height="14" width="4" x="0.5" y="0.5" />
        </g>
      </g>
    </svg>
  )
}

export default KanbanIcon
