import type { FC } from 'react'

import { Input } from 'antd'
import AppButton from 'shared/components/common/AppButton'
import clsx from 'clsx'

export const Inbox: FC = () => {
  return (
    <div className="border border-slate-200 rounded-md mt-10 p-5">
      <div className="flex flex-col divide-y">
        <div className="pb-6 pt-3">
          <h3 className="font-bold text-lg">Bandeja de entrada</h3>
        </div>
        <div className="py-4">
          <div className="flex gap-10">
            {['Todos', 'Recibidos', 'Enviados', 'Borradores', 'Eliminados'].map((tab, i) => (
              <button
                key={`tab-${tab}`}
                className={clsx(
                  'text-gray-400 text-md font-semibold hover:text-blue-500 transition-all duration-200',
                  {
                    'text-blue-500': !i,
                  },
                )}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="my-10">
            <p className="text-md">No tienes ningún correo aún</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Email: FC = () => {
  return (
    <div>
      <div className="border border-t-0 border-slate-200 rounded-b-md p-5">
        <div className="flex flex-col border-t border-slate-200 divide-y">
          <div className="flex justify-between gap-3 items-center px-2 py-4">
            <div className="flex gap-3 items-center flex-1">
              <p className="font-bold text-lg">Destino:</p>
              <Input bordered={false} />
            </div>
            <div className="flex gap-3 items-center">
              <p className="text-md">Cc</p>
              <p className="text-md">Cco</p>
            </div>
          </div>

          <div className="flex justify-between items-center px-2 py-4">
            <p className="font-bold text-lg">Asunto:</p>
            <div className="flex gap-3 items-center flex-1">
              <Input bordered={false} />
            </div>
          </div>

          <div className="px-2 py-4">
            <Input.TextArea bordered={false} rows={6} />
            <div className="mt-5 flex justify-end gap-5">
              <AppButton $white $hovered={false} type="primary">
                Cancelar
              </AppButton>
              <AppButton $success $hovered={false} type="primary">
                Enviar
              </AppButton>
            </div>
          </div>
        </div>
      </div>

      <Inbox />
    </div>
  )
}
