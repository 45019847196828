import type { FC } from 'react'

import { FinanceMeetingList } from './FinanceMeetingList'

export const Finances: FC = () => {
  return (
    <div className="flex divide-x">
      <div className="w-[40%]">Empty</div>
      <div className="flex-1 p-10">
        <FinanceMeetingList />
      </div>
    </div>
  )
}
