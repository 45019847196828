import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const DocumentIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 15.805 20.399"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="copy" transform="translate(-44.392 -4.624)">
        <path
          d="M58.886,80H44.919a.92.92,0,0,0-.919.919V99.48a.92.92,0,0,0,.919.919H58.886a.92.92,0,0,0,.919-.919V80.919A.92.92,0,0,0,58.886,80ZM55.769,92.946H48.315a.785.785,0,0,1,0-1.569h7.453a.785.785,0,0,1,0,1.569Zm0-3.138H48.315a.785.785,0,0,1,0-1.569h7.453a.785.785,0,1,1,0,1.569Zm0-3.138H48.315a.785.785,0,0,1,0-1.569h7.453a.785.785,0,1,1,0,1.569Z"
          data-name="Path 14"
          fill={color}
          id="Path_14"
          transform="translate(0.392 -75.376)"
        />
      </g>
    </svg>
  )
}

export default DocumentIcon
