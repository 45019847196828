import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().required("Campo requerido"),
  start_date: yup.date().required("Campo requerido"),
  end_date: yup
    .date()
    .required("Campo requerido")
    .min(yup.ref("start_date"), "La fecha final no puede ser menor a la inicial."),
  project_board_id: yup.number().required("Campo requerido"),
  owner_user_id: yup.number().required("Campo requerido"),
  project_deals: yup.array(yup.number().required("Campo requerido")).required("Campo requerido"),
  person_id: yup.number().required("Campo requerido."),
  organization_id: yup.number().required("Campo requerido"),
  project_labels: yup.array(yup.number()),
  description: yup.string(),
});
