import type {
  Application,
  ClientService,
  FeathersService,
  Id,
  NullableId,
  Paginated,
  Params,
  Query,
} from '@feathersjs/feathers'

class Service<T = any> implements ClientService<T> {
  public service: FeathersService<T>

  constructor(private name: string, private app: Application) {
    this.service = this.app.service(this.name) as FeathersService<T>
  }

  find<K extends T>(params?: Params<Query> | undefined): Promise<Paginated<K>> {
    return this.service.find(params)
  }
  get<K extends T>(id: Id, params?: Params<Query> | undefined): Promise<K> {
    return this.service.get(id, params)
  }
  create<K extends T>(data: Partial<K>[], params?: Params<Query> | undefined): Promise<K[]>
  create<K extends T>(data: Partial<K>, params?: Params<Query> | undefined): Promise<K>
  create<K extends T>(data: unknown, params?: unknown): Promise<K> | Promise<K[]> {
    return this.service.create(data as any, params as any)
  }
  update<K extends T>(id: Id, data: Partial<K>, params?: Params<Query> | undefined): Promise<K>
  update<K extends T>(
    id: NullableId,
    data: Partial<K>,
    params?: Params<Query> | undefined,
  ): Promise<K | K[]>
  update<K extends T>(id: null, data: Partial<K>, params?: Params<Query> | undefined): Promise<K[]>
  update<K extends T>(
    id: unknown,
    data: unknown,
    params?: unknown,
  ): Promise<K> | Promise<K[]> | Promise<K | T[]> {
    return this.service.update(id as any, data as any, params as any)
  }
  patch<K extends T>(
    id: NullableId,
    data: Partial<K>,
    params?: Params<Query> | undefined,
  ): Promise<K | K[]>
  patch<K extends T>(id: Id, data: Partial<K>, params?: Params<Query> | undefined): Promise<K>
  patch<K extends T>(id: null, data: Partial<K>, params?: Params<Query> | undefined): Promise<K[]>
  patch<K extends T>(
    id: unknown,
    data: unknown,
    params?: unknown,
  ): Promise<K> | Promise<K[]> | Promise<K | T[]> {
    return this.service.patch(id as any, data as any, params as any)
  }
  remove<K extends T>(id: NullableId, params?: Params<Query> | undefined): Promise<K | K[]>
  remove<K extends T>(id: Id, params?: Params<Query> | undefined): Promise<K>
  remove<K extends T>(id: null, params?: Params<Query> | undefined): Promise<K[]>
  remove<K extends T>(id: unknown, params?: unknown): Promise<K> | Promise<K[]> | Promise<K | K[]> {
    return this.service.remove(id as any, params as any)
  }
}

export default Service
