import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useLocalStorage from "shared/hooks/useLocalStorage";

import {PlanViewKeys} from "../@types/PlanView.keys";

const usePlan = () => {
  const [currentView, setCurrentView] = useState<PlanViewKeys>();
  const [collapsedPane, setCollapsedPane] = useLocalStorage<boolean>("planCollapsedPane", false);
  const [hiddenContent, setHiddenContent] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleChangeView = (view: PlanViewKeys) => {
    const search = new URLSearchParams(location.search);

    search.set("tab-view", view);
    navigate({
      search: search.toString(),
    });
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const view = <PlanViewKeys | undefined>search.get("tab-view");

    if (!view) {
      search.set("tab-view", PlanViewKeys.KANBAN);
      navigate({
        search: search.toString(),
      });
    } else {
      setCurrentView(view);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (collapsedPane) {
      setTimeout(() => setHiddenContent(true), 200);
    } else {
      setTimeout(() => setHiddenContent(false), 200);
    }
  }, [collapsedPane]);

  return {
    currentView,
    collapsedPane,
    hiddenContent,
    togglePane: () => setCollapsedPane(!collapsedPane),
    handleChangeView,
  };
};

export default usePlan;
