import type { PayloadAction } from '@reduxjs/toolkit'
import type { IUIState } from 'redux/@types/ui'

import * as actionTypes from '../action-types/ui-actions.types'
import * as globalActionTypes from '../action-types'

const INITIAL_STATE: IUIState = {
  submenuCollapsed: false,
  showSearchPopover: false,
  submenuItems: [],
  taskModal: {
    show: false,
    taskId: 0,
  },
}

export default function uiReducer(
  state: IUIState = INITIAL_STATE,
  action: PayloadAction<any>,
): IUIState {
  switch (action.type) {
    case actionTypes.UI_SET_SUBMENU_COLLAPSED:
      return {
        ...state,
        submenuCollapsed: action.payload,
      }
    case actionTypes.UI_SET_SUBMENU_ITEMS:
      return {
        ...state,
        submenuItems: action.payload,
      }
    case actionTypes.UI_SET_SHOW_SEARCH_POPOVER:
      return {
        ...state,
        showSearchPopover: action.payload,
      }
    case actionTypes.UI_SET_SHOW_TASK_MODAL:
      return {
        ...state,
        taskModal: {
          show: true,
          taskId: action.payload,
        },
      }
    case actionTypes.UI_SET_HIDE_TASK_MODAL:
      return {
        ...state,
        taskModal: {
          show: false,
          taskId: 0,
        },
      }
    case globalActionTypes.SET_RESET_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return {
        ...state,
      }
  }
}
