import moment from 'moment'
import { useEffect, useState } from 'react'

const useCountDown = (timeEnd: number) => {
  const [finished, setFinished] = useState(false)
  const [timeLeftStr, setTimeLeftStr] = useState('')

  const start = () => {
    const now = moment()
    const end = moment.unix(timeEnd)

    if (now.isBefore(end)) {
      const duration = moment.duration(end.diff(now))
      const minutes = duration.get('minutes')
      const seconds = duration.get('seconds')

      setTimeLeftStr(`Solicitar nuevo código en ${minutes}:${seconds}`)
    } else {
      setFinished(true)
      setTimeLeftStr('')
    }
  }

  useEffect(() => {
    setFinished(false)
    const interval = setInterval(start, 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEnd])

  return {
    finished,
    timeLeftStr,
  }
}

export default useCountDown
