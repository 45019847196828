import type {FC} from "react";

import {useForm} from "react-hook-form";
import React from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  Kanban as AppKanban,
} from "shared/components/common/Kanban";
import {TaskCard} from "shared/components/common/TaskCard";
import {ITask, ITaskList} from "types/dto/ITask";
import styled from "styled-components";
import {AppTextArea} from "shared/components/common/Input";
import {tasksService} from "shared/services";
import {useProject} from "modules/projects/ui/components/Project/context";
import {useNotification} from "shared/contexts/Notifications";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import useTaskKanban from "../lib/useTaskKanban";

import {KanbanHeader} from "./KanbanHeader";

const CardContent = styled.div`
  background: white;
  z-index: 0;
  border-radius: 8px;
`;

const CardForm: FC<{keyFieldValue: string | number; confirm: () => void; cancel: () => void}> = ({
  keyFieldValue,
  confirm,
  cancel,
}) => {
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ITask>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Campo requerido"),
      }),
    ),
  });

  const project = useProject();

  const openNotification = useNotification();

  const handleSubmit = onSubmit(async (data) => {
    await tasksService
      .create({
        ...data,
        type: "projects",
        project_id: project.id,
        task_status_id: Number(keyFieldValue),
        project_phase_id: project.project_phase_id,
      })
      .then(() => {
        confirm();
        reset({});
      })
      .catch((err) => {
        openNotification("error", {
          message: err?.message || "No se ha podido realizar la acción",
        });
      });
  });

  return (
    <CardContent>
      <AppTextArea
        autoFocus
        className="min-h-min"
        control={control}
        name="name"
        placeholder="Task Description..."
        onBlur={() => {
          cancel();
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter") {
            e.preventDefault();
            handleSubmit(e);
          } else if (e.code === "Escape") {
            cancel();
          }
        }}
      />
    </CardContent>
  );
};

const KanbanComponent: FC<{tasks: ITaskList[]}> = ({tasks}) => {
  const {columns, handleDrop} = useTaskKanban();

  return (
    <AppKanban
      cardSettings={{
        render: (record) => {
          return <TaskCard data={record} />;
        },
      }}
      dataSource={tasks}
      keyField="task_status_id"
      onDrop={handleDrop}
    >
      <ColumnsDirective>
        {columns.map((column) => (
          <ColumnDirective
            key={`task-kanban-col-${column.id}`}
            allowAdd
            keyField={column.id + ""}
            renderAddForm={(props) => {
              return <CardForm {...props} />;
            }}
            renderHeader={KanbanHeader}
            title={column.name}
          />
        ))}
      </ColumnsDirective>
    </AppKanban>
  );
};

export const Kanban = React.memo(KanbanComponent);
