import type { FC } from 'react'
import type { ICountDownProps } from '../@types/ICountDown'

import useCountDown from '../lib/useCountDown'

export const CountDown: FC<ICountDownProps> = ({ renderNodeOnFinish, timeEnd }) => {
  const { finished, timeLeftStr } = useCountDown(timeEnd)

  return (
    <div className="flex flex-col items-center">
      {finished && renderNodeOnFinish}
      {!finished && <span className="text-sm block text-center mt-3">{timeLeftStr}</span>}
    </div>
  )
}
