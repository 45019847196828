import type {IAppRoute} from "./type";
import type {FC, ReactNode} from "react";

import {Route, Routes, useLocation} from "react-router-dom";
import {Navigate} from "react-router-dom";
import AuthLayout from "shared/components/layouts/authLayout/ui";
import {useAuth} from "shared/contexts/Auth";

import {projectsRoutes} from "./projects.routes";
import {contactsRoutes} from "./contacts.routes";
import {loginRoutes} from "./login.routes";

const rootRouter: IAppRoute[] = [
  {
    path: "/",
    element: <Navigate to="/projects" />,
    requireAuth: true,
  },
  ...projectsRoutes,
  ...contactsRoutes,
  ...loginRoutes,
];

const ProtectedRouter: FC<{children: ReactNode}> = ({children}) => {
  const {isAuth, loading} = useAuth();

  const location = useLocation();

  if (!isAuth && !loading)
    return (
      <Navigate
        to={{
          pathname: "/login",
          search: `_redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
        }}
      />
    );

  return <>{children}</>;
};

export const AppRoutes = () => {
  const protectedRoutes = rootRouter.filter(({requireAuth}) => requireAuth);
  const publicRoutes = rootRouter.filter(({requireAuth}) => !requireAuth);

  return (
    <Routes>
      <>
        {publicRoutes.map(({path, element}, i) => (
          <Route key={`public-route-${i}`} {...{path, element}} />
        ))}
      </>

      {protectedRoutes.map(({path, element}, i) => (
        <Route
          key={`protected-route-${i}`}
          {...{
            path,
            element: (
              <ProtectedRouter>
                <AuthLayout>{element}</AuthLayout>
              </ProtectedRouter>
            ),
          }}
        />
      ))}
    </Routes>
  );
};
