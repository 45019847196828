import clsx from "clsx";
import {useId} from "react";

import {type IGridTable} from "../@types/GridTable";
import useCollapse from "../lib/useCollapse";

export function GridTable<T = any>({
  columns,
  dataSource,
  headerClassName,
  bodyClassName,
  collapsible = false,
}: IGridTable<T>): JSX.Element {
  const componentId = useId();

  const {collapsed, toggleCollapse} = useCollapse(collapsible);

  return (
    <div className="overflow-x-auto beautifull-scrollbar">
      <table className="w-max min-w-[100%] border-collapse border border-gray-100 text-md">
        <thead>
          <tr>
            {!!columns.length &&
              columns.map((column, i) => (
                <th
                  {...(i === 0 ? {onClick: toggleCollapse} : {})}
                  key={`grid-head-${i}-${componentId}`}
                  className={clsx("p-2 border", headerClassName, {
                    "sticky left-0": column.fixed,
                  })}
                  style={{
                    width: column?.width ?? "auto",
                  }}
                >
                  {column.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody
          className={clsx(
            "transition-[max-height] duration-200 beautifull-scrollbar",
            bodyClassName,
            {
              "max-h-0 overflow-hidden": collapsed,
              "max-h-[100%]": !collapsed,
            },
          )}
        >
          {dataSource.map((record, i) => (
            <tr key={`grid-row-${i}-${componentId}`}>
              {columns.map(({dataIndex, className, render}, j) => (
                <td
                  key={`grid-row-col-${j}-${componentId}`}
                  className={clsx("p-2 border", className)}
                >
                  {typeof dataIndex === "string" &&
                    typeof render === "undefined" &&
                    (record[dataIndex as keyof typeof record] as any)}

                  {typeof dataIndex === "string" &&
                    typeof render === "function" &&
                    render(record[dataIndex as keyof typeof record], record, i, dataSource)}

                  {typeof dataIndex === "undefined" &&
                    typeof render === "function" &&
                    render(record, record, innerWidth, dataSource)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
