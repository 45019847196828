import styled from 'styled-components'

export const DropzoneWrapper = styled.div<{
  borderColor: string
}>`
  background-image: repeating-linear-gradient(
      0deg,
      ${({ borderColor }) => borderColor},
      ${({ borderColor }) => borderColor} 10px,
      transparent 10px,
      transparent 20px,
      ${({ borderColor }) => borderColor} 20px
    ),
    repeating-linear-gradient(
      90deg,
      ${({ borderColor }) => borderColor},
      ${({ borderColor }) => borderColor} 10px,
      transparent 10px,
      transparent 20px,
      ${({ borderColor }) => borderColor} 20px
    ),
    repeating-linear-gradient(
      180deg,
      ${({ borderColor }) => borderColor},
      ${({ borderColor }) => borderColor} 10px,
      transparent 10px,
      transparent 20px,
      ${({ borderColor }) => borderColor} 20px
    ),
    repeating-linear-gradient(
      270deg,
      ${({ borderColor }) => borderColor},
      ${({ borderColor }) => borderColor} 10px,
      transparent 10px,
      transparent 20px,
      ${({ borderColor }) => borderColor} 20px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  padding: 1px;
  transition: all 0.2s linear;
`

export const DropzoneContent = styled.div`
  background: #f7f7f7;
  border-radius: 4px;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
