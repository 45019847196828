import { IUIState } from 'redux/@types/ui'

import * as actionsTypes from '../action-types/ui-actions.types'

export const uiSetSubmenuCollapsed = (status: boolean): actionsTypes.ISetUiSubmenuCollapsed => ({
  type: actionsTypes.UI_SET_SUBMENU_COLLAPSED,
  payload: status,
})

export const uiSetSubmenuItems = (
  submenu: IUIState['submenuItems'],
): actionsTypes.ISetUiSubmenuItems => ({
  type: actionsTypes.UI_SET_SUBMENU_ITEMS,
  payload: submenu,
})

export const uiSetShowSearchPopover = (show: boolean): actionsTypes.ISetUiShowSearchPopover => ({
  type: actionsTypes.UI_SET_SHOW_SEARCH_POPOVER,
  payload: show,
})

export const uiSetShowTaskModal = (taskId: number): actionsTypes.ISetShowTaskModal => ({
  type: actionsTypes.UI_SET_SHOW_TASK_MODAL,
  payload: taskId,
})

export const uiSetHideTaskModal = (): actionsTypes.ISetHideTaskModal => ({
  type: actionsTypes.UI_SET_HIDE_TASK_MODAL,
})
