import type { FC } from 'react'

import moment from 'moment'
// eslint-disable-next-line import/order
import { momentLocalizer } from 'react-big-calendar'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import AppButton from 'shared/components/common/AppButton'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { IEvent } from '../@types/IEvent'
import useCalendar from '../lib/useCalendar'

import { HeaderWrapper, StyledCalendar } from './styles/styled'

const localizer = momentLocalizer(moment)

export const Calendar: FC = () => {
  const { date, events, handleChange, handlePrevDay, handleNextDay } = useCalendar()

  return (
    <div className="flex flex-col h-full flex-1 overflow-auto scrollbar-hide">
      <HeaderWrapper className="sticky top-0 z-10 bg-white">
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-lg capitalize">
            {moment(date).format('dddd DD MMMM, YYYY')}
          </h2>
          <div className="flex items-center">
            <AppButton size="small" type="ghost" onClick={handlePrevDay}>
              <LeftOutlined />
            </AppButton>
            <AppButton size="small" type="ghost" onClick={handleNextDay}>
              <RightOutlined />
            </AppButton>
          </div>
        </div>
      </HeaderWrapper>
      <div className="flex-1">
        <StyledCalendar
          resizable
          allDayAccessor={(event) => (event as IEvent).all_day}
          components={{
            timeGutterHeader: () => <div>&nbsp;</div>,
          }}
          date={date}
          defaultView="day"
          events={events}
          formats={{
            timeGutterFormat: 'h A',
            eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
              return (
                localizer!.format(start, 'h:mm A', culture) +
                ' - ' +
                localizer!.format(end, 'h:mm A', culture)
              )
            },
          }}
          localizer={localizer}
          scrollToTime={date}
          step={15}
          timeslots={4}
          toolbar={false}
          views={{
            agenda: false,
            day: true,
            month: false,
            week: false,
            work_week: false,
          }}
          onEventDrop={handleChange}
          onEventResize={handleChange}
        />
      </div>
    </div>
  )
}
