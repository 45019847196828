import styled from 'styled-components'
import { Card as AntCard } from 'antd'

export const PhasesWrapper = styled.div`
  height: 100%;
`

export const HeaderColumns = styled.div`
  display: flex;
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  position: sticky;
  top: 119px;
  background: white;
  z-index: 8;
  padding: 0 14px;
`

export const HeadColumn = styled.div`
  padding: 5px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:not(:last-child) {
    border-right: 1px solid #f7f7f7;
  }
`

export const CustomCard = styled(AntCard)`
  margin-bottom: 12px;
  box-shadow: 0px 0px 3px #f0f0f0;
  z-index: 0;
  padding: 0;

  & a:hover {
    text-decoration: none !important;
  }

  & .ant-card-body {
    padding: 0;
  }
`

export const CardSection = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    border-right: 1px solid #f0f0f0;
  }
`

export const CardSectionContent = styled.div`
  padding: 8px 12px;
`

export const TextIconWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey};
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin: 5px 0;
`
