import styled from 'styled-components'
import { Checkbox } from 'antd'

const AppCheckBox = styled(Checkbox)`
  & .ant-checkbox-inner::after {
    display: block;
    border-color: white;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.success};
  }
`

export default AppCheckBox
