import type { FC } from 'react'

import { ProgressCircle } from 'shared/components/common/Progress'
import clsx from 'clsx'
import { useTheme } from 'styled-components'
import TextIcon from 'shared/assets/icons/text'
import moment from 'moment'

import { ProgressContainer, ProgressPopOver } from './styles/GridProgress.styled'

export const GridProgress: FC<{
  progress: number
  max: number
}> = ({ progress, max }) => {
  const theme = useTheme()

  return (
    <ProgressContainer>
      <ProgressPopOver>
        <div className="relative z-0 flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <ProgressCircle className="z-0" max={max} progress={progress} width={30} />
            <h6 className="hide-info font-bold">Tareas</h6>
          </div>
          <div className="hide-info">
            <div className="flex flex-col mt-1 mx-1 divide-y">
              {new Array(4).fill(0).map((_, index) => (
                <div
                  key={`popover-info-${index}`}
                  className="flex items-center gap-2 w-full flex-none px-1 py-2"
                >
                  <div className="flex flex-col items-center gap-1">
                    <span
                      className={clsx('block w-[15px] h-[15px] rounded-full shadow-inner border', {
                        'bg-green-light border-none': index % 2 === 0,
                      })}
                    />
                    <TextIcon color={theme.colors.grey} size={22} />
                  </div>
                  <div className="flex flex-col gap-1 flex-1 text-md">
                    <span>Hacer Nuevo Home</span>
                    <span
                      className={clsx({
                        'text-green-light': index % 2 === 0,
                        'text-blue-400': index % 2 !== 0,
                      })}
                    >
                      {index % 2 === 0
                        ? 'Completada'
                        : `${moment().add(8, 'days').format('D [de] MMM')} - En Progreso`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProgressPopOver>
    </ProgressContainer>
  )
}
