import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'

import { ITabButton } from '../../@types/TabButton'

export const TabButton: FC<ITabButton> = ({ label, active, onClick }) => {
  return (
    <AppButton
      $hovered={false}
      className="font-bold"
      size="large"
      width={120}
      onClick={onClick}
      {...(active
        ? {
            type: 'primary',
            $info: true,
          }
        : {
            type: 'text',
          })}
    >
      {label}
    </AppButton>
  )
}
