import styled from 'styled-components'
import { Button as AntButton, Layout, Menu as AntMenu } from 'antd'

export const SiderBase = styled(Layout.Sider)`
  overflow: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
`

export const SiderMain = styled(SiderBase)`
  height: 100vh;
  position: sticky !important;
  background: ${({ theme }) => theme.colors.primary} !important;
  z-index: 100;
`

export const SiderSubmenu = styled(SiderBase)`
  position: sticky !important;
  top: 64px;
  height: calc(100vh - 64px);
  background: ${({ theme }) => theme.colors['light']} !important;
  z-index: 100;
`

export const Header = styled(Layout.Header)`
  background: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px !important;
  position: fixed;
  top: 0;
  z-index: 100;
  width: calc(100% - 80px);
  border-bottom: 1px solid #f7f7f7;
`

export const Menu = styled(AntMenu)`
  background: transparent !important;
  margin-top: 5rem;
  padding: 0 6px;

  & > .ant-menu-item {
    display: flex;
    padding: 0;
    height: 50px;
    line-height: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    border-radius: 4px;
    position: relative;
    overflow: visible;

    & > .ant-menu-title-content {
      display: none !important;
    }

    &.count::before {
      display: inline-block;
      position: absolute;
      top: -6px;
      right: -6px;
      content: '1';
      width: 22px;
      height: 22px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      background: ${({ theme }) => theme.colors.info};
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }

    &.ant-menu-item-selected {
      background: ${({ theme }) => theme.colors['primary-light']};
    }
    & > .ant-menu-title-content {
      display: none;
    }
  }
`

export const SubMenu = styled(AntMenu)`
  background: transparent !important;
  padding: 0 6px;
  height: 100%;

  &.ant-menu-inline-collapsed > .ant-menu-item > .ant-menu-title-content {
    display: none;
  }
  &.ant-menu-inline-collapsed > .ant-menu-item {
    justify-content: center;
  }

  & > .ant-menu-item {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    margin-top: 15px;
    border-radius: 4px;
    height: 45px;
    font-size: 1.2em;
    font-weight: 600;

    & svg {
      stroke: #000;
      fill: none;
    }

    &.ant-menu-item-selected {
      background: ${({ theme }) => theme.colors['info-light']};
      color: ${({ theme }) => theme.colors.info};
      & > svg {
        stroke: transparent;
        fill: ${({ theme }) => theme.colors.info};
      }
    }

    & > .ant-menu-title-content {
      margin-left: 12px;
    }
  }
`

export const SubmenuTrigger = styled(AntButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors['grey']};
`
