import type { FC } from 'react'

import { TableGroupType } from '../lib/TableGroup.keys'

import TableGroup from './TableGroup'

export const Date: FC = () => {
  return (
    <div className="overflow-x-auto beautifull-scrollbar">
      <TableGroup type={TableGroupType.OVERDUE} />
      <TableGroup type={TableGroupType.TODAY} />
      <TableGroup type={TableGroupType.TOMORROW} />
      <TableGroup type={TableGroupType.FUTURE} />
    </div>
  )
}
