import { ChangeEvent, useCallback } from 'react'
import { useState, useRef, useEffect } from 'react'

import { IOtpBoxProps } from '../@types/OtpBox'

import { isNumber } from './utils'

const useOtpBox = (props?: Pick<IOtpBoxProps, 'onChange'>) => {
  const [value, setValue] = useState<string>('')
  const boxRef = useRef<HTMLInputElement>(null)

  const focusBox = (box: ChildNode) => {
    box.dispatchEvent(new Event('focused'))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value

    if (!isNumber(v)) return

    if (v && !value) {
      setValue(v)
      const nextBox = e.target?.nextSibling

      props?.onChange(v)

      if (nextBox) focusBox(nextBox)
    } else if (!v) {
      setValue('')
      props?.onChange('')
      const prevBox = e.target.previousSibling

      if (prevBox) focusBox(prevBox)
    }
  }

  const handleFocused = (e: Event) => {
    const target = e.target as HTMLInputElement

    target.focus()
    target.select()
  }

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const text = e.clipboardData?.getData('Text') ?? ''

    if (!isNumber(text)) return
    const [v, ...boxValues] = text.trim().split('')

    const dataTransfer = new DataTransfer()
    const nextBox = boxRef.current?.nextSibling

    dataTransfer.setData('Text', boxValues.join(''))

    if (nextBox) {
      nextBox.dispatchEvent(
        new ClipboardEvent('paste', {
          clipboardData: dataTransfer,
        }),
      )
      if (!nextBox?.nextSibling) focusBox(nextBox)
    }
    props?.onChange(v)
    setValue(v)
  }

  const addEvents = useCallback(() => {
    const input = boxRef.current

    if (!input) return

    input.addEventListener('focused', handleFocused)
    input.addEventListener('paste', handlePaste)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeEvents = () => {
    const input = boxRef.current

    if (!input) return

    input.removeEventListener('focused', handleFocused)
    input.removeEventListener('paste', handlePaste)
  }

  useEffect(() => {
    if (boxRef?.current) addEvents()

    return () => {
      removeEvents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxRef?.current])

  return {
    value,
    boxRef,
    handleChange,
  }
}

export default useOtpBox
