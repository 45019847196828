import type {FC} from "react";

import {
  DoubleRightOutlined,
  EllipsisOutlined,
  PaperClipOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {Button} from "antd";
import {PersonNameBadge} from "shared/components/common/PersonNameBadge";
import {AppInput} from "shared/components/common/Input";
import moment from "moment";
import {ITaskCommentExtends} from "types/dto/ITaskComment";
import React from "react";

import {useModalTaskContext} from "../context";
import useComments from "../lib/useComments";
import useAddComment from "../lib/useAddComment";

const TaskUpdateAt: FC = () => {
  const {task} = useModalTaskContext();

  return (
    <div className="flex divide-slate-200 divide-x py-3 px-2 items-center h-16">
      <div className="flex-1 pr-2">
        <div className="border rounded-full p-1 flex gap-1 items-center w-max">
          {task?.owner_user_id && (
            <PersonNameBadge bg="violet" className="flex-none">
              {task?.meta_owner_user_first_name?.charAt(0)?.toUpperCase() +
                task?.meta_owner_user_last_name?.charAt(0)?.toLowerCase()}
            </PersonNameBadge>
          )}
          {!!task?.updatedAt && (
            <p className="text-xs mr-2 flex-none">
              Actualizado hace <strong>{moment(task.updatedAt).fromNow()}</strong>
            </p>
          )}
        </div>
      </div>
      <div className="pl-2">
        <Button icon={<EllipsisOutlined />} type="text" />
      </div>
    </div>
  );
};

const Header: FC = () => {
  return (
    <div className="px-8 py-5">
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-lg">Comentarios</h2>
        <Button icon={<DoubleRightOutlined />} type="text" />
      </div>
    </div>
  );
};

const Comment: FC<{comment: ITaskCommentExtends}> = ({comment}) => {
  return (
    <div className="flex w-full mb-4">
      <div className="mr-1">
        <PersonNameBadge bg={comment.user.profile_color}>
          {comment.user.first_name.charAt(0).toUpperCase() +
            comment.user.last_name.charAt(0).toUpperCase()}
        </PersonNameBadge>
      </div>
      <div className="flex-1 bg-white border rounded-md">
        <div className="px-4 py-2 flex">
          <div className="flex-1">
            <div className="mt-1 w-full text-sm">
              <span className="mr-1 inline-block capitalize text-black font-bold">
                {comment.user.first_name} {comment.user.last_name}
              </span>
              <span className="text-gray-400">Ha comentado</span>
            </div>
          </div>
          <div className="text-gray-400 text-xs">{moment(comment.createdAt).fromNow()}</div>
        </div>
        <div className="px-6 pb-2 text-md">{comment.body}</div>
      </div>
    </div>
  );
};

const Comments: FC = () => {
  const {task} = useModalTaskContext();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const {comments} = useComments({
    taskId: task?.id,
    containerRef,
  });

  return (
    <div ref={containerRef} className="flex-1 overflow-auto beautifull-scrollbar">
      {comments.map((it) => (
        <Comment key={it.id} comment={it} />
      ))}
    </div>
  );
};

const AddComment: FC = () => {
  const {task} = useModalTaskContext();

  const {control, handleComment} = useAddComment(task?.id);

  return (
    <div>
      <div className="border rounded-md flex flex-col divide-y-2 divide-slate-200 bg-white">
        <div className="px-6 py-3">
          <AppInput
            bordered={false}
            control={control}
            name="body"
            placeholder="Añade un comentario..."
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                handleComment();
              }
            }}
          />
        </div>
        <div className="px-4 py-2 bg-[#DEEAF9] flex items-center justify-between">
          <div className="flex items-center gap-6">
            <Button
              className="text-gray-500 flex items-center justify-center"
              icon={<span className="font-semibold text-lg">@</span>}
              type="text"
            />
            <Button
              className="text-gray-500 flex items-center justify-center"
              icon={<PaperClipOutlined className="text-lg" />}
              type="text"
            />
          </div>
          <Button
            className="text-gray-400 flex items-center justify-center"
            icon={<SendOutlined className="text-lg" />}
            type="text"
          />
        </div>
      </div>
    </div>
  );
};

export const TaskComments: FC = () => {
  return (
    <div className="flex flex-col divide-y divide-slate-200 h-full">
      <TaskUpdateAt />
      <Header />
      <div className="py-4 px-6 flex-1 flex flex-col bg-gray-50 overflow-auto h-full">
        <Comments />
        <AddComment />
      </div>
    </div>
  );
};
