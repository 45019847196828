import type {IUseProjectSelectProps} from "../@types";
import type {FieldValues} from "react-hook-form";

import {useController} from "react-hook-form";

function useProjectSelectPhase<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  disabled = false,
}: IUseProjectSelectProps<TFieldValues>) {
  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
  });

  const handleChange = (phaseId: number) => {
    if (!disabled) {
      onChange(phaseId);
    }
  };

  return {
    value,
    handleChange,
  };
}

export default useProjectSelectPhase;
