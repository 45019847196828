import clsx from "clsx";

import useEditorJs from "../lib/useEditorJs";
import {EditorJsProps} from "../@types";

import {EditorWrapper} from "./styles";

export default function EditorJs(props: EditorJsProps) {
  const {cId} = useEditorJs(props);

  return (
    <div
      className={clsx(
        "border",
        "rounded-md",
        "shadow-sm",
        "border-slate-200",
        "shadow-slate-200",
        "px-5",
        "py-2",
      )}
    >
      <EditorWrapper id={cId} />
    </div>
  );
}
