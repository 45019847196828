import type {FC} from "react";
import type {IGridCardBacklog} from "../@types/GridCardBacklog";

import moment from "moment";
import AppButton from "shared/components/common/AppButton";
import {GridTable} from "shared/components/common/GridTable";
import {PersonNameBadgeGrouped} from "shared/components/common/PersonNameBadge";
import {TaskInterfaceKeys, type ITask} from "types/dto/ITask";
import {TaskStatusBadge} from "shared/components/common/TaskStatusBadge";
import {ModalNewGroup} from "modules/tasks/ui/components/Modals/CreateGroup";
import useAppDispatch from "shared/hooks/redux/useAppDispatch";
import {uiSetShowTaskModal} from "redux/actions/ui.actions";

import useModalNewGroup from "../lib/useModalNewGroup";

import {GridCardBase} from "./GridCard.base";

export const GridCardBacklog: FC<IGridCardBacklog> = ({title}) => {
  const {open, toggleModal} = useModalNewGroup();
  const dispatch = useAppDispatch();

  return (
    <>
      <ModalNewGroup open={open} onClose={() => toggleModal(false)} />
      <GridCardBase
        actions={
          <div className="flex justify-between items-center">
            <div className="flex justify-between w-1/2">
              <AppButton className="p-0" type="link" onClick={() => toggleModal(true)}>
                + Grupo
              </AppButton>
              <AppButton className="p-0" type="link">
                + Tarea
              </AppButton>
            </div>
          </div>
        }
        content={
          <GridTable<ITask>
            columns={[
              {
                title: "Titulo",
                dataIndex: "description",
                render: (v) => (
                  <button
                    className="hover:underline"
                    onClick={() => dispatch(uiSetShowTaskModal(1))}
                  >
                    {v}
                  </button>
                ),
              },
              // {
              //   title: "Miembro",
              //   dataIndex: TaskInterfaceKeys.ASSIGNED,
              //   render: (value) => <PersonNameBadgeGrouped align="center" persons={value} />,
              // },
              // {
              //   title: "Estado",
              //   dataIndex: TaskInterfaceKeys.STATUS,
              //   className: "p-0",
              //   render: (status) => (
              //     <TaskStatusBadge
              //       className="w-full h-full"
              //       rounded={false}
              //       size="small"
              //       status={status}
              //     />
              //   ),
              // },
              // {
              //   title: "Timeline",
              //   dataIndex: TaskInterfaceKeys.TIMELINE,
              //   render: (value) => moment(value).format("MMM DD"),
              // },
            ]}
            dataSource={[]}
          />
        }
        progress={{
          max: 4,
          progress: 1,
        }}
        title={title}
      />
    </>
  );
};
