import type { IWrapper } from '../@types/styled'

import styled, { css } from 'styled-components'

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  ${({ direction }) => {
    if (direction === 'horizontal') {
      return css`
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
      `
    } else {
      return css`
        flex-direction: column;
      `
    }
  }}
`
