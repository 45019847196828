import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const AlarmClock: FC<IBaseIconProps> = ({ color, size }) => {
  return (
    <svg height={size} viewBox="0 0 16.495 16.61" width={size} xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1.493)">
        <g data-name="Group 31" transform="translate(1.493)">
          <g data-name="Group 30" transform="translate(0)">
            <path
              d="M6.343,1.262,5.283,0,1.493,3.179l1.06,1.262Z"
              data-name="Path 17"
              fill={color}
              transform="translate(-1.493)"
            />
            <path
              d="M307.57,3.286,303.78.107l-1.06,1.262,3.79,3.179Z"
              data-name="Path 18"
              fill={color}
              transform="translate(-291.075 -0.103)"
            />
            <path
              d="M30.246,45.653a7.423,7.423,0,1,0,7.427,7.423A7.417,7.417,0,0,0,30.246,45.653Zm0,13.2a5.773,5.773,0,1,1,5.773-5.773A5.774,5.774,0,0,1,30.25,58.849Z"
              data-name="Path 19"
              fill={color}
              transform="translate(-22.002 -43.888)"
            />
            <path
              d="M194.73,130.987h-1.237v4.948l3.913,2.355.623-1.019-3.3-1.955Z"
              data-name="Path 20"
              fill={color}
              transform="translate(-186.07 -125.923)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AlarmClock
