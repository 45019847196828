import type {FieldValues, FieldPath} from "react-hook-form";
import type {ControllerPickerProps} from "../@types";

import {useController} from "react-hook-form";
import React from "react";

import Picker from "./Picker";

export default function ControllerPicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  type,
  control,
  name,
  shouldUnregister,
  rules,
  statuses,
  onChange,
}: ControllerPickerProps<TFieldValues, TName>) {
  const {field} = useController({
    control,
    name,
    shouldUnregister,
    rules,
  });

  const handleChange = (statusId: number) => {
    field.onChange(statusId);
    if (onChange) {
      onChange(statusId);
    }
  };

  return (
    <>
      <Picker statuses={statuses} type={type} value={field.value} onChange={handleChange} />
      {/* <button className="hidden group-hover:block text-blue-400 mr-3">Limpiar</button> */}
    </>
  );
}
