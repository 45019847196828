import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'
import { EllipsisOutlined, SettingOutlined } from '@ant-design/icons'
import { GridTable } from 'shared/components/common/GridTable'
import AppCheckBox from 'shared/components/common/AppCheckbox'
import { Tag } from 'antd'
// import moment from 'moment'
import { ILabelRelation } from 'types/dto/ILabelRelation'

import useOrganizations from '../lib/useOrganizations'

import { Navbar } from './styles/styled'
import { ModalOrganization } from './ModalOrganization'

export const Organizations: FC = () => {
  const { pagination, organizations, showModalOrganization, handleModalOrganization } =
    useOrganizations()

  return (
    <div>
      <ModalOrganization
        open={showModalOrganization}
        onCancel={() => handleModalOrganization(false)}
      />
      <Navbar>
        <div className="flex w-full items-center justify-between">
          <AppButton $success type="primary" onClick={() => handleModalOrganization(true)}>
            + Organización
          </AppButton>
          <div className="flex gap-2 items-center">
            <p className="text-sm">{pagination.total} Organizaciones</p>
            <div>Filter here</div>
            <AppButton className="ml-3" icon={<EllipsisOutlined />} type="text" />
          </div>
        </div>
      </Navbar>
      <GridTable
        columns={[
          {
            title: '',
            render() {
              return <AppCheckBox />
            },
          },
          { title: 'Nombre', dataIndex: 'name' },
          {
            title: 'Etiquetas',
            render(_, record) {
              return (
                <div className="flex gap-2 items-center">
                  {record.labels.map((tag: ILabelRelation) => (
                    <Tag
                      key={`tag-${tag.id}`}
                      color={tag.meta_label_color_background}
                      style={{ color: tag.meta_label_color_text }}
                    >
                      {tag.meta_label_name}
                    </Tag>
                  ))}
                </div>
              )
            },
          },
          { title: 'Dirección', render: () => 'PENDING' },
          {
            title: 'Personas',
            dataIndex: 'total_persons',
          },
          { title: 'Tratos Cerrados', dataIndex: 'total_closed_deals' },
          { title: 'Tratos Abiertos', dataIndex: 'total_opened_deals' },
          {
            title: 'Fecha de Próxima Actividad',
            render: () => 'PENDING',
          },
          {
            title: 'Propietario',
            render: (_, record) =>
              `${record.meta_owner_user_first_name} ${record.meta_owner_user_last_name}`,
          },
          { title: <SettingOutlined /> },
        ]}
        dataSource={organizations}
      />
    </div>
  )
}
