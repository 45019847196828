import type {FC} from "react";

import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import antdTheme from "shared/styles/antd.theme";
import "antd/dist/reset.css";
import AntSpanish from "antd/locale/es_ES";
import "shared/styles/app.styles.css";
import {ThemeProvider as StyledThemeProvider} from "styled-components";
import styledTheme from "shared/styles/styled-theme";
import {Provider} from "react-redux";
import store from "redux/redux-store";
import {StyledGlobal} from "shared/styles/global.styles";
import "./index.css";
import "moment/locale/es";
import {ModalTask} from "modules/projects/ui/components/Project/ui/components/Modals/ModalTask";
import {AuthProvider} from "shared/contexts/Auth";
import {AppRoutes} from "routes";
import {NotificationProvider} from "shared/contexts/Notifications";
// import { StyleProvider } from '@ant-design/cssinjs'

const App: FC = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <ConfigProvider locale={AntSpanish} theme={antdTheme}>
            <NotificationProvider>
              <StyledThemeProvider theme={styledTheme}>
                <StyledGlobal />
                <ModalTask />
                {/* <StyleProvider hashPriority="high"> */}
                <AppRoutes />
                {/* </StyleProvider> */}
              </StyledThemeProvider>
            </NotificationProvider>
          </ConfigProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
