import type { FC } from 'react'

import { TemplateTabsKeys } from '../lib/TemplateTabs.keys'
import useTemplateTabs from '../lib/useTemplateTabs'

import { TabButton } from './components/TabButton'
import { Plan } from './components/Plan'
import { TemplateTabsWrapper } from './styles/TemplateTabs.styled'
import { Documents } from './components/Documents'

export const PROJECT_TABS_VIEWS = {
  [TemplateTabsKeys.PLAN]: <Plan />,
  [TemplateTabsKeys.DOCUMENTS]: <Documents />,
}

export const TemplateTabs: FC = () => {
  const { currentTab, handleChangeTab } = useTemplateTabs()

  return (
    <div>
      <TemplateTabsWrapper className="sticky top-[131px] bg-white z-50">
        <TabButton
          active={currentTab === TemplateTabsKeys.PLAN}
          label="Plan"
          onClick={() => handleChangeTab(TemplateTabsKeys.PLAN)}
        />
        <TabButton
          active={currentTab === TemplateTabsKeys.DOCUMENTS}
          label="Documentos"
          onClick={() => handleChangeTab(TemplateTabsKeys.DOCUMENTS)}
        />
      </TemplateTabsWrapper>
      <div>{currentTab ? PROJECT_TABS_VIEWS[currentTab] : null}</div>
    </div>
  )
}
