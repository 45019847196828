import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const ClipIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 24.678 24.642"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.369,46.543a3.223,3.223,0,0,1-2.279-5.5l9.964-9.965a4.253,4.253,0,1,1,6.015,6.014l-7.844,7.843a.6.6,0,0,1-.852-.852l7.844-7.843a3.048,3.048,0,0,0-4.31-4.309l-9.964,9.965a2.021,2.021,0,0,0,0,2.854,2.067,2.067,0,0,0,2.854,0l6.691-6.691a.984.984,0,0,0,.286-.69.977.977,0,0,0-1.667-.691l-4.57,4.57a.6.6,0,1,1-.852-.852l4.569-4.57a2.182,2.182,0,1,1,3.086,3.085L15.648,45.6A3.2,3.2,0,0,1,13.369,46.543Z"
        data-name="Path 124"
        transform="matrix(0.719, -0.695, 0.695, 0.719, -28.021, -1.789)"
      />
    </svg>
  )
}

export default ClipIcon
