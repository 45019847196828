import type {IProjectSelectPhaseProps} from "../@types";
import type {FieldValues} from "react-hook-form";

import clsx from "clsx";
import {Spin} from "antd";

import useProjectSelectPhase from "../lib/useProjectSelectPhase";
import useProjectPhases from "../lib/useProjectPhases";

import PhaseItem from "./PhaseItem";

function ProjectSelectPhase<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  size = "small",
  className,
  disabled = false,
  label = "Fase",
  boardId,
}: IProjectSelectPhaseProps<TFieldValues>) {
  const {value, handleChange} = useProjectSelectPhase<TFieldValues>({
    control,
    name,
    disabled,
  });

  const {phases, isLoading} = useProjectPhases(boardId);

  return (
    <div>
      {!!phases.length && <label className="text-gray-400 font-bold">{label}</label>}
      <div
        className={clsx("flex rounded-lg overflow-hidden divide-white", className, {
          "divide-x-4": size === "small",
          "divide-x-8": size === "large",
        })}
      >
        {isLoading && (
          <div className="flex-1 flex items-center justify-center">
            <Spin />
          </div>
        )}
        {phases.map((phase, phaseIndex) => (
          <PhaseItem
            key={`project-phase-${phase.id}`}
            completed={
              (value && phase.id === value) ||
              (value && phaseIndex < phases.findIndex(({id}) => id === value))
            }
            disabled={disabled}
            label={phase.name}
            size={size}
            onClick={() => handleChange(phase.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProjectSelectPhase;
