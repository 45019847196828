import styled from 'styled-components'
import { Modal } from 'antd'

export const CustomModal = styled(Modal)`
  & .ant-modal-content {
    padding: 0 !important;
    overflow: hidden;
  }

  & .ant-modal-footer {
    background: #deeaf94c;
  }
`
