import type { FC } from 'react'
import type { IHeaderProps } from 'shared/components/common/Kanban/@types/IColumnDirective'

import { EllipsisOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'
import AppButton from 'shared/components/common/AppButton'

import { HeaderWrap } from './styles/styled'

export const KanbanHeader: FC<IHeaderProps> = ({ title, totalItems }) => {
  return (
    <HeaderWrap>
      <div>
        <Typography.Title className="mb-0" level={5}>
          {title}
        </Typography.Title>

        <Typography.Text type="secondary">
          {totalItems || 0} Tarea{(totalItems || 0) > 1 && 's'}
        </Typography.Text>
      </div>
      <div>
        <Popover
          destroyTooltipOnHide
          content={
            <AppButton size="small" type="ghost">
              + Nueva tarea
            </AppButton>
          }
          placement="bottom"
          showArrow={false}
          style={{ padding: 0 }}
          trigger="click"
        >
          <AppButton icon={<EllipsisOutlined />} type="text" />
        </Popover>
      </div>
    </HeaderWrap>
  )
}
