import type { IGroupWrapper, IPersonNameBadgeBase } from './types'

import styled, { css } from 'styled-components'

export const PersonNameBadgeBase = styled.span<IPersonNameBadgeBase>`
  display: inline-flex;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 100%;
  color: white;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: ${({ bg }) => bg};
  font-size: 11px;
  padding: 2px;
  flex: none;
  ${({ $grouped }) => {
    if ($grouped)
      return css`
        &:first-child {
          z-index: 0;
        }
        &:not(:first-child) {
          margin-left: -25px;
        }
      `
  }}
`

export const GroupWrapper = styled.div<IGroupWrapper>`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: ${({ align }) => align};
`
