import moment, {Moment} from "moment";
import {useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const useDeliveryDateFilter = () => {
  const [dates, setDates] = useState<{start?: Moment; end?: Moment}>({});
  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const handleChange = (key: string) => (date: Moment | null) => {
    setDates((prev) => ({
      ...prev,
      [key]: date ? moment(date) : undefined,
    }));
  };

  const handleConfirm = () => {
    const {start, end} = dates;

    if (start) search.set("dStart", start.format("YYYY-MM-DD"));
    else search.delete("dStart");

    if (end) search.set("dEnd", end.format("YYYY-MM-DD"));
    else search.delete("dEnd");

    navigate({
      search: search.toString(),
    });
  };

  const handleReset = () => {
    setDates({});
    search.delete("dStart");
    search.delete("dEnd");

    navigate({
      search: search.toString(),
    });
  };

  return {
    dates,
    handleChange,
    handleConfirm,
    handleReset,
  };
};

export default useDeliveryDateFilter;
