import type {FC} from "react";
import type {IOrganizationFilter} from "../@types/OrganizationFilter";

import AppSelect from "shared/components/common/AppSelect";

import useOrganizationFilter from "../lib/useOrganizationFilter";

export const OrganizationFilter: FC<IOrganizationFilter> = ({onChange = () => undefined}) => {
  const {loading, value, organizations, handleChange} = useOrganizationFilter({
    onChange,
  });

  return (
    <AppSelect
      allowClear
      bordered={false}
      loading={loading}
      options={organizations.map(({id, name}) => ({label: name, value: id}))}
      placeholder="Clientes"
      value={value}
      width={150}
      onChange={handleChange}
    />
  );
};
