import {UniqueIdentifier} from "@dnd-kit/core";
import {createContext, useContext} from "react";

import {type IKanban} from "../../@types/IKanban";

export const KanbanContext = createContext<
  Omit<IKanban<any>, "children" | "dataSource" | "idField"> & {
    setDataSource: (d: any) => void;
    dataSource: Record<UniqueIdentifier, any[]>;
    idField: string;
  }
>({
  cardSettings: {
    render: (_) => <></>,
  },
  setDataSource: (_) => {},
  dataSource: {},
  keyField: "",
  idField: "id",
});

export const useKanbanContext = () => useContext(KanbanContext);

export const KanbanProvider = KanbanContext.Provider;
