import {
  ColumnDirective,
  ColumnsDirective,
  Kanban as KanbanComponent,
} from "shared/components/common/Kanban";
import {Col, Row} from "antd";
import {useMemo} from "react";
import {ITask, TaskInterfaceKeys} from "types/dto/ITask";

import fakeTasksData from "../lib/mocks/fake-tasks";

import Card from "./card";
import ColumnHeader from "./columnHeader";

function Kanban(this: any) {
  // const data = useMemo(() => fakeTasksData(), []);

  return (
    <Row style={{padding: 14}}>
      <Col xs={24}>
        <KanbanComponent<ITask>
          cardSettings={{
            render: (record) => {
              return <Card data={record} />;
            },
          }}
          dataSource={[] as any}
          keyField="task_status_id"
        >
          <ColumnsDirective>
            <ColumnDirective
              keyField="todo"
              renderHeader={(props) => (
                <ColumnHeader count={props.totalItems} headerText={props.title} />
              )}
              title="To Do"
            />
            <ColumnDirective
              keyField="in-progress"
              renderHeader={(props) => (
                <ColumnHeader count={props.totalItems} headerText={props.title} />
              )}
              title="In Progress"
            />
            <ColumnDirective
              keyField="internal-review"
              renderHeader={(props) => (
                <ColumnHeader count={props.totalItems} headerText={props.title} />
              )}
              title="Internal Review"
            />
            <ColumnDirective
              keyField="client-review"
              renderHeader={(props) => (
                <ColumnHeader count={props.totalItems} headerText={props.title} />
              )}
              title="Client Review"
            />
            <ColumnDirective
              keyField="completed"
              renderHeader={(props) => (
                <ColumnHeader count={props.totalItems} headerText={props.title} />
              )}
              title="Completed"
            />
          </ColumnsDirective>
        </KanbanComponent>
      </Col>
    </Row>
  );
}
export default Kanban;
