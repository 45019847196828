import type {FC} from "react";
import type {IProjectProgress} from "../@types/ProjectProgress";

import {Progress, Typography} from "antd";
import TextIcon from "shared/assets/icons/text";
import UserGroupIcon from "shared/assets/icons/userGroup";

import {Wrapper} from "./styled";

const ProjectProgress: FC<IProjectProgress> = ({direction}) => {
  return (
    <Wrapper direction={direction || "vertical"}>
      <Progress
        percent={50}
        size="small"
        strokeColor="#3F90FC"
        strokeWidth={10}
        type="circle"
        width={30}
      />
      <div className="mt-2">
        <div className="flex gap-2 items-center justify-center flex-nowrap">
          <TextIcon className="stroke-[#707070]" color="none" size={14} />
          <Typography.Text className="text-sm">2/4</Typography.Text>
        </div>
        <div className="mt-2 flex gap-2 items-center justify-center flex-nowrap">
          <UserGroupIcon color="#707070" size={14} />
          <Typography.Text className="text-sm">2/4</Typography.Text>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProjectProgress;
