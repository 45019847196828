import styled from 'styled-components'

const PageHeadWrapper = styled.div`
  background: white;
  padding: 14px 10px;
  border-bottom: 1px solid #f7f7f7;
  position: sticky;
  top: 63px;
  z-index: 9;
`

export default PageHeadWrapper
