import type {ITask} from "types/dto/ITask";

import {Typography} from "antd";
import styled from "styled-components";
import FlagIcon from "shared/assets/icons/flag";
import {PersonNameBadgeGrouped} from "shared/components/common/PersonNameBadge";
import {TaskInterfaceKeys} from "modules/tasks/lib/tasks.keys";
import moment from "moment";
import AlarmClock from "shared/assets/icons/alarmClock";
import AppCheckBox from "shared/components/common/AppCheckbox";

const CardContent = styled.div`
  /* padding: 12px; */
  z-index: 2;
`;

const CardText = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.grey};
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

const getTrackedTime = (ms: number) => {
  return moment.utc(ms).format("HH[h] mm[m] ss[s]");
};

function Card({data: task}: {data: ITask}): JSX.Element {
  return (
    <CardContent className="shadow-sm border rounded-md overflow-hidden bg-white">
      {!!task.featured_image_path && (
        <div>
          <img alt="" className="w-full h-[150px] object-cover" src={task.featured_image_path} />
        </div>
      )}
      <div className="py-3 px-4">
        <Typography.Title className="mb-2" level={5}>
          {task.description}
        </Typography.Title>
        {/* <Typography.Text>{task[TaskInterfaceKeys.PROJECT_NAME]}</Typography.Text> */}
        <div>
          <CardText>
            <FlagIcon color="#00000073" size={15} />
            <Typography.Text>{moment(task.due_date).format("D [de] MMM")}</Typography.Text>
          </CardText>
          <CardText>
            <AlarmClock color="#00000073" size={15} />
            {/* <Typography.Text>
              {getTrackedTime(task[TaskInterfaceKeys.TIME_TRACKED])}
            </Typography.Text> */}
          </CardText>
        </div>

        {/* <div className="flex justify-between items-center">
          <CardText>
            <AppCheckBox
              disabled
              checked={
                task[TaskInterfaceKeys.COMPLETED_SUBTASKS] ===
                task[TaskInterfaceKeys.TOTAL_SUBTASKS]
              }
              type="success"
            />

            {task[TaskInterfaceKeys.COMPLETED_SUBTASKS]}
            {"/"}
            {task[TaskInterfaceKeys.TOTAL_SUBTASKS]}
          </CardText>
          <PersonNameBadgeGrouped persons={task[TaskInterfaceKeys.ASSIGNED]} />
        </div> */}
      </div>
    </CardContent>
  );
}

export default Card;
