import "./styles.css";

import {Col, Row} from "antd";
import {
  ColumnDirective,
  ColumnsDirective,
  Kanban as KanbanComponent,
} from "shared/components/common/Kanban";
import {useState} from "react";

import dataSource from "../lib/mocks/dataSource.json";

import Card from "./card";
import ColumnHeader from "./columnHeader";

function Kanban(this: any) {
  const [data] = useState(dataSource.cardData);

  return (
    <Row style={{padding: 14}}>
      <Col xs={24}>
        <KanbanComponent
          cardSettings={{
            render: (record) => {
              return <Card data={record} />;
            },
          }}
          dataSource={data}
          idField="Id"
          keyField="status"
        >
          <ColumnsDirective>
            <ColumnDirective
              keyField="kick-off"
              renderHeader={(props) => {
                return <ColumnHeader count={props.totalItems} headerText={props.title} />;
              }}
              title="Kick Off"
            />
            <ColumnDirective
              keyField="planning"
              renderHeader={(props) => {
                return <ColumnHeader count={props.totalItems} headerText={props.title} />;
              }}
              title="Planning"
            />
            <ColumnDirective
              keyField="implementation"
              renderHeader={(props) => {
                return <ColumnHeader count={props.totalItems} headerText={props.title} />;
              }}
              title="Implementation"
            />
            <ColumnDirective
              keyField="review"
              renderHeader={(props) => {
                return <ColumnHeader count={props.totalItems} headerText={props.title} />;
              }}
              title="Review"
            />
            <ColumnDirective
              keyField="closing"
              renderHeader={(props) => {
                return <ColumnHeader count={props.totalItems} headerText={props.title} />;
              }}
              title="Closing"
            />
          </ColumnsDirective>
        </KanbanComponent>
      </Col>
    </Row>
  );
}
export default Kanban;
