import {useEffect, useMemo, useRef, useState} from "react";
import {IOrganization} from "types/dto/IOrganization";
import {useAuth} from "shared/contexts/Auth";
import {useLocation, useNavigate} from "react-router-dom";

import api from "../api";
import {IUseOrganizationFilter} from "../@types/useOrganizationFilter";

const useOrganizationFilter = ({onChange}: IUseOrganizationFilter) => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  const {selectedCompany} = useAuth();

  const loading = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const value = useMemo(() => {
    const orgId = search.get("orgId");

    if (orgId) return +orgId;

    return null;
  }, [search]);

  const loadData = async () => {
    if (loading.current) return;

    loading.current = true;

    await api.getOrganizations(selectedCompany!.id).then((res) => {
      setOrganizations(res);
    });
  };

  const handleChange = (selectedValues: number) => {
    onChange([selectedValues]);

    if (selectedValues) {
      search.set("orgId", selectedValues + "");
    } else {
      search.delete("orgId");
    }
    navigate({
      search: search.toString(),
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    organizations,
    loading: loading.current,
    value,
    handleChange,
  };
};

export default useOrganizationFilter;
