import React from "react";

import {TreeTasksListContextProps} from "./@types/context";

export const TreeTasksListContext = React.createContext<TreeTasksListContextProps>(
  {} as TreeTasksListContextProps,
);

export const useTreeTasksListContext = () => React.useContext(TreeTasksListContext);

export const TreeTasksListProvider = TreeTasksListContext.Provider;
