import { useState } from 'react'
import { ITemplate } from 'types/dto/ITemplate'

const useTemplates = () => {
  const [showModalTemplate, setShowModalTemplate] = useState(false)
  const [templates, setTemplates] = useState<ITemplate[]>([])

  const handleModalTemplate = (status: boolean) => {
    setShowModalTemplate(status)
  }

  const handleCreateTemplate = (template: ITemplate) => {
    setTemplates((prev) => prev.concat(template))
    setShowModalTemplate(false)
  }

  return {
    showModalTemplate,
    templates,
    handleModalTemplate,
    handleCreateTemplate,
  }
}

export default useTemplates
