import {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {ViewsKeys} from "../ui/projects.keys";

const useProject = () => {
  const [currentView, setCurrentView] = useState<ViewsKeys>();
  const [openModalProject, setOpenModalProject] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const handleChangeView = (view: ViewsKeys) => {
    // const search = new URLSearchParams(location.search);

    search.set("view", view);
    navigate({
      search: search.toString(),
    });
  };

  const handleChangeModalProject = (status: boolean) => setOpenModalProject(status);

  const handleChangeFilter = (filterKey: string) => {
    return (value: number | number[] | string | string[]) => {
      search.set(filterKey, Array.isArray(value) ? value.join(",") : value + "");
      navigate({
        search: search.toString(),
      });
    };
  };

  useEffect(() => {
    // const search = new URLSearchParams(location.search);
    const view = <ViewsKeys | undefined>search.get("view");

    if (!view) {
      search.set("view", ViewsKeys.DATE);
      navigate({
        search: search.toString(),
      });
    } else {
      setCurrentView(view);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return {
    currentView,
    openModalProject,
    handleChangeView,
    handleChangeFilter,
    handleChangeModalProject,
  };
};

export default useProject;
