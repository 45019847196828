import type {FC} from "react";

import {ProjectTabsKeys} from "../lib/ProjectTabs.keys";
import useProjectTabs from "../lib/useProjectTabs";

import {TabButton} from "./components/TabButton";
import {Plan} from "./components/Plan";
import {ProjectTabsWrapper} from "./styles/ProjectTabs.styled";
import {Finances} from "./components/Finances";
import {Extra} from "./components/Extra";
import {Documents} from "./components/Documents";

export const PROJECT_TABS_VIEWS = {
  [ProjectTabsKeys.PLAN]: <Plan />,
  [ProjectTabsKeys.EXTRA]: <Extra />,
  [ProjectTabsKeys.DOCUMENTS]: <Documents />,
  [ProjectTabsKeys.FINANCES]: <Finances />,
};

export const ProjectTabs: FC = () => {
  const {currentTab, handleChangeTab} = useProjectTabs();

  return (
    <div>
      <ProjectTabsWrapper className="sticky top-[175px] bg-white z-50">
        <TabButton
          active={currentTab === ProjectTabsKeys.PLAN}
          label="Plan"
          onClick={() => handleChangeTab(ProjectTabsKeys.PLAN)}
        />
        <TabButton
          active={currentTab === ProjectTabsKeys.EXTRA}
          label="Extra"
          onClick={() => handleChangeTab(ProjectTabsKeys.EXTRA)}
        />
        <TabButton
          active={currentTab === ProjectTabsKeys.DOCUMENTS}
          label="Documentos"
          onClick={() => handleChangeTab(ProjectTabsKeys.DOCUMENTS)}
        />
        <TabButton
          active={currentTab === ProjectTabsKeys.FINANCES}
          label="Financiero"
          onClick={() => handleChangeTab(ProjectTabsKeys.FINANCES)}
        />
      </ProjectTabsWrapper>
      <div>{currentTab ? PROJECT_TABS_VIEWS[currentTab] : null}</div>
    </div>
  );
};
