import type {NotificationInstance} from "antd/es/notification/interface";
import type {INotificationProvider, IOpenNotification} from "./types";

import {FC, createContext, useContext} from "react";
import {notification} from "antd";

export const NotificationContext = createContext<IOpenNotification>(() => {});

export const NotificationProvider: FC<INotificationProvider> = ({children}) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification: IOpenNotification = (method, config) => {
    api[method as keyof NotificationInstance]({
      ...(config as any),
      placement: "bottomLeft",
    });
  };

  return (
    <NotificationContext.Provider value={openNotification}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
