import type {FieldValues} from "react-hook-form";
import type {DocEditorProps} from "../@types";

import EditorJs from "./EditorJs";
import ControllerEditor from "./ControllerEditor";

export default function DocEditor<TFieldValues extends FieldValues = FieldValues>(
  props: DocEditorProps<TFieldValues>,
) {
  return <div>{!props.control ? <EditorJs {...props} /> : <ControllerEditor {...props} />}</div>;
}
