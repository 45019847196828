import type { FC } from 'react'

import clsx from 'clsx'

import { IColorPickerProps, IPresetColorProps } from '../@types/ColorPicker'
import useColorPicker from '../lib/useColorPicker'

const PanelPicker = require('rc-color-picker')

import 'rc-color-picker/assets/index.css'

const PresetColor: FC<IPresetColorProps> = ({ color, selected, onClick }) => {
  return (
    <button
      className={clsx(
        'w-5',
        'h-5',
        'rounded-sm',
        'outline-none',
        'hover:shadow-md',
        'transition-all',
        'duration-200',
        {
          'ring ring-offset-1 ring-primary': selected,
        },
      )}
      style={{
        background: color,
      }}
      type="button"
      onClick={() => onClick(color)}
    />
  )
}

export const ColorPicker: FC<IColorPickerProps> = ({
  allowSelectCustom = true,
  control,
  name,
  presetColors = [],
  error,
  label,
}) => {
  const { value, handleClickPreset } = useColorPicker({
    name,
    control,
  })

  return (
    <div className="relative">
      {Boolean(label) && <label className="text-gray-400 font-bold text-md block">{label}</label>}
      <div className="flex gap-2 flex-wrap">
        {presetColors.map((color, i) => (
          <PresetColor
            key={`preset-color-${i}`}
            color={color}
            selected={value === color}
            onClick={handleClickPreset}
          />
        ))}
        {allowSelectCustom && (
          <PanelPicker
            color="#3e3e3e"
            placement="topLeft"
            style={{
              zIndex: 9999,
            }}
            onChange={({ color }: any) => {
              handleClickPreset(color)
            }}
          >
            <button
              className={clsx(
                'w-5',
                'h-5',
                'rounded-sm',
                'outline-none',
                'hover:shadow-md',
                'transition-all',
                'duration-200',
                'rc-color-picker-trigger',
              )}
              type="button"
            />
          </PanelPicker>
        )}
      </div>
      {Boolean(error) && (
        <span className="block text-red-500 animate-fade-in font-bold text-sm">{error}</span>
      )}
    </div>
  )
}
