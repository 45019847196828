import {UniqueIdentifier} from "@dnd-kit/core";
import {useEffect, useRef, useState} from "react";
import {taskStatusService, tasksService} from "shared/services";
import {ITask} from "types/dto/ITask";
import {ITaskStatus} from "types/dto/ITaskStatus";

const useTaskKanban = () => {
  const [columns, setColumns] = useState([] as ITaskStatus[]);

  const loading = useRef(false);

  const loadColumns = async () => {
    if (loading.current) return;

    loading.current = true;

    await taskStatusService
      .find({
        query: {
          type: "system",
          visible_in: "project_boards_all",
          $limit: 20,
          $sort: {
            position: 1,
          },
        },
      })
      .then(({data}) => {
        setColumns(data);
      })
      .finally(() => {
        loading.current = false;
      });
  };

  const handleDrop = async (task: ITask, columnId: UniqueIdentifier) => {
    await tasksService.patch(task.id, {
      task_status_id: +columnId,
    });
  };

  useEffect(() => {
    loadColumns();
  }, []);

  return {
    columns,
    loading: loading.current,
    handleDrop,
  };
};

export default useTaskKanban;
