import type { FC } from 'react'

import { Dropzone } from 'shared/components/common/Dropzone'

export const Files: FC = () => {
  return (
    <div className="border border-slate-200 rounded-b-md p-5">
      <div className="p-8">
        <Dropzone />
      </div>
    </div>
  )
}
