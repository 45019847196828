import type {SelectProps} from "antd";
import type {IUseBoardSelectProps} from "../@types/useBoardSelect";
import type {FieldValues} from "react-hook-form";

import {useController} from "react-hook-form";
import {useEffect, useState} from "react";
import {useAuth} from "shared/contexts/Auth";

import api from "../api";

import {serializeOptions} from "./utils";
import useBoardSearch from "./useBoardSearch";

function useBoardSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
}: IUseBoardSelectProps<TFieldValues>) {
  const [options, setOptions] = useState<SelectProps["options"]>([]);

  const {selectedCompany} = useAuth();

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const {boards, isFetching, handleSearch} = useBoardSearch();

  const handleChange = (value: number) => {
    onChange(value);
  };

  useEffect(() => {
    api.getFirstBoards(selectedCompany!.id).then((res) => {
      setOptions(serializeOptions(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOptions(serializeOptions(boards));
  }, [boards]);

  return {
    value,
    options,
    isFetching,
    handleChange,
    handleSearch,
  };
}

export default useBoardSelect;
