import type {IProjectExtended} from "types/dto/IProject";

import {FC, ReactNode, useContext, useEffect, useRef} from "react";
import {createContext, useState} from "react";
import {useParams} from "react-router-dom";
import {projectsService, tasksService} from "shared/services";
import {ITask} from "types/dto/ITask";

export const ProjectContext = createContext({} as IProjectExtended);

export const ProjectProvider: FC<{children: ReactNode}> = ({children}) => {
  const [project, setProject] = useState({} as IProjectExtended);

  const loading = useRef(false);

  const params = useParams();

  const loadProject = async () => {
    if (loading.current) return;

    const projectId = Number(params.id);

    if (!projectId) return;

    loading.current = true;

    await projectsService
      .get<IProjectExtended>(projectId, {query: {$client: {withDetail: "true"}}})
      .then((res) => {
        setProject(res);
      })
      .finally(() => {
        loading.current = false;
      });
  };

  const updateLocalProject = (updatedProject: IProjectExtended) => {
    if (updatedProject.id !== project.id) return;
    setProject({
      ...project,
      ...updatedProject,
    });
  };

  const updateProjectProgress = (task: ITask) => {
    if (task.project_id !== project.id) return;

    loadProject();
  };

  const subscribeEvents = () => {
    projectsService.service.on("patched", updateLocalProject);
    tasksService.service.on("created", updateProjectProgress);
    tasksService.service.on("patched", updateProjectProgress);
  };

  const unsubscribeEvents = () => {
    projectsService.service.removeListener("patched", updateLocalProject);
    tasksService.service.removeListener("created", updateProjectProgress);
    tasksService.service.removeListener("patched", updateProjectProgress);
  };

  useEffect(() => {
    loadProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    subscribeEvents();

    return () => {
      unsubscribeEvents();
    };
  });

  return <ProjectContext.Provider value={project}>{children}</ProjectContext.Provider>;
};

export const useProject = () => useContext(ProjectContext);
