import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
`

export const TableRowWrapper = styled.tr`
  & th,
  td {
    padding: 10px 14px;
    border: 1px solid #70707022;
    text-align: center;
  }
`

export const TableHeadWrapper = styled.th``

export const TableCol = styled.td``
