import { Popover, Typography } from 'antd'
import styled from 'styled-components'
import AppButton from 'shared/components/common/AppButton'
import { EllipsisOutlined } from '@ant-design/icons'

export interface IColumnHeader {
  allowToggle?: boolean
  count?: number
  headerText?: string
  isExpanded?: boolean
  keyField?: string
  maxCount?: number
  minCount?: number
  showItemCount?: boolean
}

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

function ColumnHeader({ headerText, count }: IColumnHeader): JSX.Element {
  return (
    <HeaderWrap>
      <div>
        <Typography.Title className="mb-0" level={5}>
          {headerText}
        </Typography.Title>

        <Typography.Text type="secondary">
          {count || 0} Tarea{(count || 0) > 1 && 's'}
        </Typography.Text>
      </div>
      <div>
        <Popover
          destroyTooltipOnHide
          content={
            <AppButton size="small" type="ghost">
              + Nueva Tarea
            </AppButton>
          }
          placement="bottom"
          showArrow={false}
          style={{ padding: 0 }}
          trigger="click"
        >
          <AppButton icon={<EllipsisOutlined />} type="text" />
        </Popover>
      </div>
    </HeaderWrap>
  )
}

export default ColumnHeader
