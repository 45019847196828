import type {ITaskDetail} from "types/dto/ITask";

import React, {FC, useEffect, useId, useState} from "react";
import {CheckCircleOutlined, PlayCircleFilled, UserOutlined} from "@ant-design/icons";
import TextIcon from "shared/assets/icons/text";
import {useForm} from "react-hook-form";
import FlagIcon from "shared/assets/icons/flag";
import {TaskStatusBadge} from "shared/components/common/TaskStatusBadge";
import EditorJS from "@editorjs/editorjs";
import {OwnerSelect} from "shared/components/common/OwnerSelect";
import moment from "moment";
import {tasksService} from "shared/services";
import {TimelinePicker} from "shared/components/common/TimelinePicker";
import {TaskStatusPicker} from "shared/components/common/TaskStatusPicker";
import {DocEditor} from "shared/components/common/DocEditor";
import {debounce} from "lodash";

import {useModalTaskContext} from "../context";

import {EditorComponent} from "./styles/TaskComments.styled";
import TitleInput from "./components/TitleInput";

const Header = require("@editorjs/header");
const List = require("@editorjs/list");

const Description: FC = () => {
  const [isMount, setIsMount] = useState(false);
  const [editorJs, setEditorJs] = useState<EditorJS | null>(null);

  const cId = useId();

  useEffect(() => {
    if (isMount && !editorJs) {
      setEditorJs(
        new EditorJS({
          holder: cId,
          minHeight: 0,
          tools: {
            header: Header,
            list: List,
          },
        }),
      );
    }

    return () => {
      if (editorJs) {
        editorJs.destroy();
        setEditorJs(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMount]);

  useEffect(() => {
    setIsMount(true);

    return () => {
      setIsMount(false);
    };
  }, []);

  return (
    <div className="border rounded-md shadow-sm border-slate-200 shadow-slate-200 px-5 py-2">
      <EditorComponent id={cId} />
    </div>
  );
};

export const TaskForm: FC = () => {
  const {control, reset, setValue, getValues} = useForm<ITaskDetail>();

  const {task} = useModalTaskContext();

  const handleSaveTaskName = async (taskName: string) => {
    if (taskName === task!.name) return;

    if (!taskName) {
      setValue("name", task!.name);

      return;
    }

    await tasksService.patch(task!.id, {
      name: taskName,
    });
  };

  const handleSaveTaskOwner = async (ownerId: number) => {
    if (task!.owner_user_id === ownerId || !ownerId) return;

    await tasksService.patch(task!.id, {
      owner_user_id: ownerId,
    });
  };

  const handleSaveTimeline = async () => {
    const startDate = getValues("start_date");
    const dueDate = getValues("due_date");

    await tasksService.patch(task!.id, {
      start_date: startDate || null,
      due_date: dueDate || null,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSaveDescription = React.useCallback(
    (taskId: number) =>
      debounce(async (data) => {
        await tasksService.patch(taskId, {
          description: typeof data === "object" ? JSON.stringify(data) : data,
        });
      }, 500),
    [],
  );

  const handleSaveStatus = async () => {
    const statusId = getValues("task_status_id");

    await tasksService.patch(task!.id, {
      task_status_id: statusId,
    });
  };

  React.useEffect(() => {
    if (task) {
      reset({...task, description: JSON.parse(task?.description || "{}")});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <div className="flex flex-col divide-y divide-slate-200 h-full">
      <div className="flex w-full divide-x divide-slate-200 h-16 items-center">
        <div className="pl-14 flex-1">
          <div className="flex items-center gap-4">
            {task?.labels?.map((label) => (
              <div
                key={`tag-${label.id}`}
                className="py-1 px-2 font-bold rounded-md text-xs"
                style={{
                  background: label.meta_label_color_background,
                  color: label.meta_label_color_text,
                }}
              >
                {label.meta_label_name}
              </div>
            ))}
            <div className="flex items-center gap-1 text-gray-500 text-xs">
              <TextIcon className="fill-gray-500" size={20} />
              <h3 className="font-bold capitalize">{task?.project?.name}</h3>
            </div>
          </div>
        </div>
        <div className="px-6 h-full flex items-center">
          <div>
            <h4 className="font-semibold text-xs">TIEMPO REGISTRADO</h4>
            <div className="flex gap-1 align-middle">
              <PlayCircleFilled className="text-green-500" />
              <span className="text-slate-300">00:00:00</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-1"
        style={{
          height: "calc(100% - 64px)",
        }}
      >
        <div className="px-14 py-4 h-full overflow-y-auto beautifull-scrollbar">
          <TitleInput control={control} name="name" saveValue={handleSaveTaskName} />
          <div className="flex flex-col gap-2 w-full">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 font-bold text-gray-500 w-1/4">
                <UserOutlined />
                <label className="mb-0">Responsable</label>
              </div>
              <div className="flex-1">
                <OwnerSelect
                  control={control}
                  defaultAuthOwner={false}
                  name="owner_user_id"
                  onChange={handleSaveTaskOwner}
                />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 font-bold text-gray-500 w-1/4">
                <UserOutlined />
                <label className="mb-0">Timeline</label>
              </div>
              <div className="flex-1">
                <TimelinePicker
                  shouldUnregister
                  control={control}
                  endDateFieldName="due_date"
                  startDateFieldName="start_date"
                  onBlur={handleSaveTimeline}
                  onChange={handleSaveTimeline}
                />
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 font-bold text-gray-500 w-1/4">
                <CheckCircleOutlined />
                <label className="mb-0">Estado</label>
              </div>
              <div className="flex-1">
                <TaskStatusPicker
                  control={control}
                  name="task_status_id"
                  onChange={handleSaveStatus}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            {!!task && (
              <DocEditor
                value={task?.description || "{}"}
                onChange={handleSaveDescription(task.id)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
