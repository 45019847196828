import type {FC} from "react";

import Card from "./Card";
import ColumnHeader from "./ColumnHeader";

const Phases: FC = () => {
  return (
    <div className="relative">
      <ColumnHeader />
      <div style={{padding: 14}}>
        {new Array(100).fill(1).map((_, index) => (
          <Card key={index} />
        ))}
      </div>
    </div>
  );
};

export default Phases;
