import type {FC} from "react";
import type {IModalOrganization} from "../@types/ModalOrganization";

import {AppSelectC} from "shared/components/common/AppSelect";
import {AppInput} from "shared/components/common/Input";
import {ModalForm} from "shared/components/common/ModalForm";
import {OrganizationVisibilities} from "types/dto/IOrganization";
import {LabelsInput} from "shared/components/common/LabelsInput";
import {LabelEntityTypes} from "types/dto/ILabel";
import {OwnerSelect} from "shared/components/common/OwnerSelect";

import useModalOrganization from "../lib/useModalOrganization";

export const ModalOrganization: FC<IModalOrganization> = ({open, onCancel}) => {
  const {errors, control, handleSubmit} = useModalOrganization({onCancel});

  return (
    <ModalForm
      centered
      footerProps={{
        submitText: "Crear",
        okButtonProps: {
          width: 120,
        },
      }}
      open={open}
      title="Añadir Organización"
      width={420}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-2">
        <AppInput
          control={control}
          error={errors?.name?.message}
          label="Nombre completo"
          name="name"
        />
        <LabelsInput
          control={control}
          entities={[LabelEntityTypes.ALL, LabelEntityTypes.ORGANIZATIONS]}
          error={errors?.organization_labels?.[0]?.message}
          name="organization_labels"
        />
        <OwnerSelect
          control={control}
          error={errors?.owner_user_id?.message}
          label="Propietario"
          name="owner_user_id"
        />
        <AppSelectC
          className="w-full"
          control={control}
          error={errors?.visible_to?.message}
          label="Visible para"
          name="visible_to"
          options={Object.entries(OrganizationVisibilities).map(([value, label]) => ({
            label,
            value,
          }))}
          separator={false}
        />
      </div>
    </ModalForm>
  );
};
