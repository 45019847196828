import type {ITask, ITaskList} from "types/dto/ITask";

import {useEffect, useRef, useState} from "react";
import {useNotification} from "shared/contexts/Notifications";
import {projectsService, tasksService} from "shared/services";
import {IProject} from "types/dto/IProject";

const useProjectTasks = (projectId: number) => {
  const [tasks, setTasks] = useState([] as ITaskList[]);

  const loading = useRef(false);

  const openNotification = useNotification();

  const loadTasks = async () => {
    if (loading.current) return;

    loading.current = true;

    await tasksService
      .find<ITaskList>({
        query: {
          project_id: projectId,
        },
      })
      .then(({data}) => {
        setTasks(data);
      })
      .finally(() => {
        loading.current = false;
      });
  };

  const appendTask = (newTask: ITask) => {
    if (newTask.project_id === projectId) {
      setTasks((prev) => prev.concat(newTask as ITaskList));
      openNotification("info", {
        message: (
          <>
            Se ha creado una nueva tarea <strong>#{newTask.id}</strong>
          </>
        ),
      });
    }
  };

  const updateTask = (task: ITask) => {
    if (task.project_id === projectId) {
      setTasks((prev) =>
        prev.map((it) => {
          if (it.id === task.id)
            return {
              ...it,
              ...task,
            };

          return it;
        }),
      );
      // openNotification("info", {
      //   message: (
      //     <>
      //       Se ha actualizado la tarea <strong>#{task.id}</strong>
      //     </>
      //   ),
      // });
    }
  };

  const updateProjectTask = (updatedProject: IProject) => {
    if (projectId !== updatedProject.id) return;

    setTasks((prev) => {
      return prev.map((task) => ({
        ...task,
        project: updatedProject,
      }));
    });
  };

  const subscribeEvents = () => {
    tasksService.service.on("created", appendTask);
    tasksService.service.on("patched", updateTask);
    projectsService.service.on("patched", updateProjectTask);
  };

  const unsubscribeEvents = () => {
    tasksService.service.removeListener("created", appendTask);
    tasksService.service.removeListener("patched", updateTask);
    projectsService.service.removeListener("patched", updateProjectTask);
  };

  useEffect(() => {
    if (projectId) {
      loadTasks();
    }

    subscribeEvents();

    return () => {
      unsubscribeEvents();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    tasks,
    loading: loading.current,
  };
};

export default useProjectTasks;
