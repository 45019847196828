import styled from 'styled-components'

export const ProgressPopOver = styled.div`
  position: initial;
  background: transparent;
  z-index: 20;
  transition: all 0.2s linear;
  padding: 7px 14px;

  & .hide-info {
    display: none;
  }
`

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: relative;
  cursor: default;

  &:hover > ${ProgressPopOver} {
    position: absolute;
    z-index: 99;
    left: -14px;
    top: -7px;
    width: 270px;
    min-height: 300px;
    background: white;
    border-radius: 0.5rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);

    & .hide-info {
      display: initial;
    }
  }
`
