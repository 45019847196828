import { ItemType } from 'antd/es/menu/hooks/useItems'
import { IUIState } from 'redux/@types/ui'

import menuIcons, { MenuIconKeys, SubmenuIconKeys } from './menu-icons'

export enum MainMenuKeys {
  PROJECTS = '/projects',
  CONTACTS = '/contacts',
}

export enum SubmenuKeys {
  PROJECTS = '/projects',
  PROJECTS_TASK_AND_ACTIVITIES = '/projects/task-and-activities',
  PROJECTS_TEMPLATES = '/projects/templates',
  PROJECTS_WORKLOAD = '/projects/workload',
  CONTACTS_COMPANIES = '/contacts/organizations',
  CONTACTS_PERSONS = '/contacts/persons',
}

const MENU: Record<string, ItemType & { submenu?: IUIState['submenuItems']; title: string }> = {
  [MainMenuKeys.PROJECTS]: {
    key: MainMenuKeys.PROJECTS,
    className: 'count',
    title: 'Projects',
    icon: menuIcons.main[MenuIconKeys.PROJECTS],
    submenu: [
      { key: SubmenuKeys.PROJECTS, label: 'Projects', icon: SubmenuIconKeys.PROJECTS },
      {
        key: SubmenuKeys.PROJECTS_TASK_AND_ACTIVITIES,
        label: 'Task & Activities',
        icon: SubmenuIconKeys.PROJECTS_TASK_AND_ACTIVITIES,
      },
      {
        key: SubmenuKeys.PROJECTS_TEMPLATES,
        label: 'Templates',
        icon: SubmenuIconKeys.PROJECTS_TEMPLATES,
      },
      {
        key: SubmenuKeys.PROJECTS_WORKLOAD,
        label: 'Workload',
        icon: SubmenuIconKeys.PROJECTS_WORKLOAD,
      },
    ],
  },
  [MainMenuKeys.CONTACTS]: {
    key: MainMenuKeys.CONTACTS,
    title: 'Contactos',
    icon: menuIcons.main[MenuIconKeys.CONTACTS],
    submenu: [
      {
        key: SubmenuKeys.CONTACTS_COMPANIES,
        label: 'Organizaciones',
        icon: SubmenuIconKeys.CONTACTS_COMPANIES,
      },
      {
        key: SubmenuKeys.CONTACTS_PERSONS,
        label: 'Personas',
        icon: SubmenuIconKeys.CONTACTS_PERSONS,
      },
    ],
  },
}

export default MENU
