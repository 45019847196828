import type {FC} from "react";

import AppSelect from "shared/components/common/AppSelect";

import useBoardFilter from "../lib/useBoardFilter";

export const BoardFilter: FC = () => {
  const {value, boards, loading, handleChange} = useBoardFilter();

  return (
    <AppSelect
      allowClear
      bordered={false}
      loading={loading}
      options={boards.map(({id, name}) => ({label: name, value: id}))}
      placeholder="Tipo de proyecto"
      value={value}
      width={150}
      onChange={handleChange}
    />
  );
};
