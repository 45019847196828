import type {FieldValues, FieldPath} from "react-hook-form";
import type {ControllerEditorProps} from "../@types";

import {useController} from "react-hook-form";

import EditorJs from "./EditorJs";

export default function ControllerEditor<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({control, name, shouldUnregister, rules}: ControllerEditorProps<TFieldValues, TName>) {
  const {field} = useController({
    control,
    name,
    shouldUnregister,
    rules,
  });

  return (
    <>
      <EditorJs value={field.value} onChange={field.onChange} />
    </>
  );
}
