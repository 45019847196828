import type { FC } from 'react'

import { TemplateHeader } from './ui/TemplateHeader'
import { TemplateTabs } from './ui/TemplateTabs'

export const Template: FC = () => {
  return (
    <div className="relative">
      <TemplateHeader />
      <TemplateTabs />
    </div>
  )
}
