import { IBase } from './IBase'

export enum DeliverableKeys {
  title = 'title',
  dateEnd = 'dateEnd',
  progress = 'progress',
}

export enum DeliverableProgressKeys {
  completed = 'completed',
  total = 'total',
}

export interface IDeliverableProgress {
  [DeliverableProgressKeys.completed]: number
  [DeliverableProgressKeys.total]: number
}

export interface IDeliverable extends Partial<IBase> {
  [DeliverableKeys.title]: string
  [DeliverableKeys.dateEnd]: string
  [DeliverableKeys.progress]: IDeliverableProgress
}
