import type { FC } from 'react'
import type {
  IGroupTemplateProps,
  ITemplateItemProps,
  ITemplatesListProps,
} from '../@types/TemplatesList'

import { MoreOutlined, RightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const TemplateItem: FC<ITemplateItemProps> = ({ template }) => {
  return (
    <div className="border shadow-md rounded-md overflow-hidden max-w-[252px]">
      <div className="border-l-[12px] border-l-green-400">
        <Link to={`/projects/templates/${template.id}`}>
          <div className="pl-6 pr-5 py-3 flex items-center w-full gap-2">
            <h3 className="font-bold text-md">{template.title}</h3>
            <div className="flex items-center">
              <RightOutlined className="text-gray-400 w-3" />
              <MoreOutlined className="text-gray-400 w-3" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

const GroupTemplateList: FC<IGroupTemplateProps> = ({ name, templates }) => {
  return (
    <div>
      <h3 className="font-bold text-lg">
        {name} ({templates.length})
      </h3>
      <div className="px-4 pt-6 flex flex-wrap gap-3">
        {templates.map((template, i) => (
          <TemplateItem key={`template-${i}`} template={template} />
        ))}
      </div>
    </div>
  )
}

export const TemplatesList: FC<ITemplatesListProps> = ({ templates }) => {
  return (
    <div className="flex-1 overflow-auto beautifull-scrollbar px-6 py-7">
      <GroupTemplateList name="Redes Sociales" templates={templates} />
    </div>
  )
}
