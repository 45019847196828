import "styled-components";

const styledTheme = {
  colors: {
    info: "#367ddf",
    "info-light": "#deeaf9",
    error: "#ff00008a",
    primary: "#220648",
    "primary-light": "#54387a",
    blue: "#423cf6",
    light: "#f7f7f7",
    grey: "#878787",
    success: "#318549",
    "green-light": "#3DFD57",
  },
  breakpoints: {
    xs: {
      min: "0px",
      max: "575px",
    },
    sm: {
      min: "576px",
      max: "767px",
    },
    md: {
      min: "768px",
      max: "991px",
    },
    lg: {
      min: "992px",
      max: "1199px",
    },
    xl: {
      min: "1200px",
      max: "1599px",
    },
    xxl: {
      min: "1600px",
      max: "2024px",
    },
  },
};

export type TStyleTeme = typeof styledTheme;

declare module "styled-components" {
  export interface DefaultTheme extends TStyleTeme {
    // TODO
  }
}

export default styledTheme;
