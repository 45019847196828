import type {FC} from "react";
import type {IProjectExtended} from "types/dto/IProject";

import {Typography} from "antd";
import TextWithIcon from "shared/components/common/TextWithIcon";
import CustomerIcon from "shared/assets/icons/customer";
import {PersonNameBadgeGrouped} from "shared/components/common/PersonNameBadge";
import TaskDates from "shared/components/common/TaskDates";
import {TaskTimeGrouped} from "shared/components/common/TaskTime";
import ProjectProgress from "shared/components/common/ProjectProgress";
import ProjectStatus from "shared/components/common/ProjectStatus";
import {Link} from "react-router-dom";
import moment from "moment";

import {TableRowWrapper, TableCol} from "./styled";

const TableRow: FC<{data: IProjectExtended}> = ({data}) => {
  return (
    <TableRowWrapper>
      <TableCol>
        <Link to={`/projects/${data.id}`}>
          <Typography.Text>{data.name}</Typography.Text>
        </Link>
      </TableCol>
      <TableCol>
        <TextWithIcon
          align="center"
          icon={<CustomerIcon color="#00000073" size={15} />}
          text={data.organization.name}
        />
      </TableCol>
      <TableCol>
        <PersonNameBadgeGrouped
          align="center"
          persons={[
            {name: `${data.owner_user.first_name} ${data.owner_user.last_name}`, color: "#B142EB"},
          ]}
        />
      </TableCol>
      <TableCol>{/* <TaskDates end_at={data!.end_date} start_at={data!.start_date} /> */}</TableCol>
      <TableCol>
        <TaskTimeGrouped
          estimatedTime={moment.duration(moment(data.end_date).diff(moment(data.start_date)))}
          trackedTime={moment.duration(data.tracked_time_in_seconds, "seconds")}
        />
      </TableCol>
      <TableCol>
        <ProjectProgress direction="horizontal" />
      </TableCol>
      <TableCol>
        <ProjectStatus status={data.status} />
      </TableCol>
    </TableRowWrapper>
  );
};

export default TableRow;
