import type { IAppRoute } from './type'

import { Navigate } from 'react-router-dom'
import { Organizations, Persons } from 'modules/Contacts'

export const contactsRoutes: IAppRoute[] = [
  {
    path: '/contacts',
    element: <Navigate to="/contacts/organizations" />,
    requireAuth: true,
  },
  {
    path: '/contacts/organizations',
    element: <Organizations />,
    requireAuth: true,
  },
  {
    path: '/contacts/persons',
    element: <Persons />,
    requireAuth: true,
  },
]
