import { type IBase } from './IBase'

export enum ActivityKeys {
  title = 'title',
  member = 'member',
  timeline = 'timeline',
}

export interface IActivity extends Partial<IBase> {
  [ActivityKeys.title]: string
  [ActivityKeys.member]: string
  [ActivityKeys.timeline]: string
}

export interface ICreateActivity {
  icon: string
  title: string
  start_at: string | Date
  end_at: string | Date
  description: string
  notes: string
}
