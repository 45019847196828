import type {FieldValues} from "react-hook-form";

import {Select, Spin} from "antd";

import {IOwnerSelectProps} from "../@types/OwnerSelect";
import useUserFetcher from "../lib/useUserFetcher";

export function OwnerSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  error,
  label,
  defaultAuthOwner,
  onChange,
}: IOwnerSelectProps<TFieldValues>) {
  const {value, options, isFetching, handleChange, debounceFetcher} = useUserFetcher<TFieldValues>({
    control,
    name,
    defaultAuthOwner,
    onChange,
  });

  return (
    <div>
      {Boolean(label) && <label className="text-gray-400 text-md font-bold">{label}</label>}
      <Select
        showSearch
        className="block"
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={
          isFetching ? (
            <div className="flex justify-center">
              <Spin size="small" />
            </div>
          ) : null
        }
        options={options}
        placeholder="Busca un usuario"
        showArrow={false}
        value={value}
        onChange={handleChange}
        onSearch={debounceFetcher}
      />
      {Boolean(error) && (
        <span className="text-red-500 text-sm block animate-fade-in">{error}</span>
      )}
    </div>
  );
}
