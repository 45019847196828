import { useState } from 'react'

const useModalNewGroup = () => {
  const [open, setOpen] = useState(false)

  const toggleModal = (status: boolean) => {
    setOpen(status)
  }

  return {
    open,
    toggleModal,
  }
}

export default useModalNewGroup
