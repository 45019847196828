import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'
import { EllipsisOutlined, SettingOutlined } from '@ant-design/icons'
import { GridTable } from 'shared/components/common/GridTable'
import AppCheckBox from 'shared/components/common/AppCheckbox'
// import { Tag } from 'antd'
import moment from 'moment'
import { ILabelRelation } from 'types/dto/ILabelRelation'
import { Tag } from 'antd'

import usePersons from '../lib/usePersons'

import { Navbar } from './styles/styled'
import { ModalPerson } from './ModalPerson'

export const Persons: FC = () => {
  const { persons, pagination, showModalPerson, handleModalPerson } = usePersons()

  return (
    <div>
      <ModalPerson open={showModalPerson} onCancel={() => handleModalPerson(false)} />
      <Navbar>
        <div className="flex w-full items-center justify-between">
          <AppButton $success type="primary" onClick={() => handleModalPerson(true)}>
            + Persona
          </AppButton>
          <div className="flex gap-2 items-center">
            <p className="text-sm">{pagination.total} Personas</p>
            <div>Filter here</div>
            <AppButton className="ml-3" icon={<EllipsisOutlined />} type="text" />
          </div>
        </div>
      </Navbar>
      <GridTable
        columns={[
          {
            title: '',
            fixed: true,
            width: '40px',
            render() {
              return <AppCheckBox />
            },
          },
          {
            title: 'Nombre',
            width: '220px',
            render: (_, record) => `${record.first_name} ${record.last_name}`,
          },
          {
            title: 'Etiquetas',
            width: '130px',
            render(_, record) {
              return (
                <div className="flex gap-2 items-center">
                  {record.labels.map((tag: ILabelRelation) => (
                    <Tag
                      key={`tag-${tag.id}`}
                      color={tag.meta_label_color_background}
                      style={{ color: tag.meta_label_color_text }}
                    >
                      {tag.meta_label_name}
                    </Tag>
                  ))}
                </div>
              )
            },
          },
          {
            title: 'Organización',
            width: '200px',
            render: (_, record) =>
              record.organizations.map((it) => it.meta_organization_name).join(', '),
          },
          { title: 'Correo Electrónico', dataIndex: 'primary_email', width: '220px' },
          {
            title: 'Teléfono',
            width: '130px',
            render: (_, record) =>
              `+${record.primary_phone_country_code ?? '57'} ${record.primary_phone}`,
          },
          { title: 'Tratos Cerrados', width: '100px', dataIndex: 'total_closed_deals' },
          { title: 'Tratos Abiertos', width: '100px', dataIndex: 'total_opened_deals' },
          {
            title: 'Fecha de Próxima Actividad',

            width: '220px',
            render(date) {
              return 'PENDING' ?? moment(date).format('DD-MM-YYYY hh:mm a')
            },
          },
          {
            title: 'Propietario',
            width: '200px',
            render: (_, record) =>
              `${record.meta_owner_user_first_name} ${record.meta_owner_user_last_name}`,
          },
          { title: <SettingOutlined />, fixed: true, width: '40px' },
        ]}
        dataSource={persons}
      />
    </div>
  )
}
