import type { FC } from 'react'

import { Divider, Typography } from 'antd'

import { ExtraTabs } from './ExtraTabs'
import { Schedule } from './Schedule'
import { History } from './History'

export const Extra: FC = () => {
  return (
    <div className="px-40 mx-auto my-6">
      <div className="px-10">
        <ExtraTabs />
      </div>
      <Divider>
        <Typography.Text className="font-semibold text-lg">Agenda</Typography.Text>
      </Divider>
      <Schedule />
      <Divider>
        <Typography.Text className="font-semibold text-lg">Historial</Typography.Text>
      </Divider>
      <History />
    </div>
  )
}
