import type {FC} from "react";
import type {ITaskTime} from "../@types/TaskTime";
import type {Duration} from "moment";

import {Typography} from "antd";
import AlarmClock from "shared/assets/icons/alarmClock";
import moment from "moment";

function formatTime(time: Duration) {
  const days = time.asDays();
  const hours = time.asHours() % 24;
  const minutes = time.asMinutes() % 60;

  return (
    (days ? days + "d " : "") + (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "") ||
    "0 m"
  );
}

export const TaskTime: FC<ITaskTime> = ({type, timeString}) => {
  const colors = {
    estimated: "#FF4E4E",
    tracked: "#318549",
  };

  return (
    <div className="flex gap-2 items-center">
      <AlarmClock color={colors[type]} size={15} />
      <Typography.Text>{timeString ?? "0 m"}</Typography.Text>
    </div>
  );
};

const TaskTimeGrouped: FC<{estimatedTime?: Duration; trackedTime?: Duration}> = ({
  estimatedTime = moment.duration(moment().diff(moment())),
  trackedTime = moment.duration(moment().diff(moment())),
}) => {
  return (
    <div className="py-2 w-full h-full flex divide-x text-start">
      <div className="flex-1 px-4">
        <Typography.Text strong>Registrado</Typography.Text>
        <TaskTime timeString={formatTime(trackedTime)} type="tracked" />
      </div>
      <div className="flex-1 px-4">
        <Typography.Text strong>Estimado</Typography.Text>
        <TaskTime timeString={formatTime(estimatedTime)} type="estimated" />
      </div>
    </div>
  );
};

export default TaskTimeGrouped;
