import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'
import { CloseOutlined } from '@ant-design/icons'

import { ActivityFormProvider } from '../lib/context/ActivityForm.context'
import { IActivityFormProps } from '../@types/IActivityForm'

import { HeaderWrapper } from './styles/styled'
import { Form } from './Form'
import { Calendar } from './Calendar'

export const ActivityForm: FC<IActivityFormProps> = ({ closable = true, onClose }) => {
  return (
    <ActivityFormProvider>
      <div className="flex divide-x h-full">
        <div className="flex-1 bg-[#FCFCFC] min-h-[100%] max-h-[100%]">
          <div className="flex flex-col min-h-[100%] max-h-[100%]">
            <HeaderWrapper className="bg-[#FCFCFC]">
              <div className="flex justify-between items-center">
                <h2 className="font-bold text-lg">Nueva Actividad</h2>
                {closable && (
                  <AppButton size="small" type="ghost" onClick={() => onClose && onClose()}>
                    <CloseOutlined />
                  </AppButton>
                )}
              </div>
            </HeaderWrapper>
            <Form onCancel={() => onClose && onClose()} />
          </div>
        </div>
        <div className="flex-1 min-h-[100%] max-h-[100%] overflow-hidden">
          <Calendar />
        </div>
      </div>
    </ActivityFormProvider>
  )
}
