import type { FC } from 'react'
import type { ITableHead } from '../@types'

import { Typography } from 'antd'
import { CaretDownFilled as CaretDownIcon, CaretUpFilled as CaretUpIcon } from '@ant-design/icons'

import { useTableGroupContext } from '../lib/context/TableGroup.context'
import { TableGroupType } from '../lib/TableGroup.keys'

import { TableHeadWrapper, TableRowWrapper } from './styled'

const HEAD_TYPE_CLASSES = {
  [TableGroupType.OVERDUE]: 'text-red-500',
  [TableGroupType.TODAY]: 'text-green-500',
  [TableGroupType.TOMORROW]: 'text-blue-400',
  [TableGroupType.FUTURE]: 'text-gray-400',
}

const TableHead: FC<ITableHead> = ({ name }) => {
  const { collapsed, type, toggleCollapse } = useTableGroupContext()

  return (
    <TableRowWrapper>
      <TableHeadWrapper>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <button onClick={toggleCollapse}>
              {collapsed ? <CaretDownIcon /> : <CaretUpIcon />}
            </button>
            <Typography.Text className={HEAD_TYPE_CLASSES[type]}>{name}</Typography.Text>
          </div>
          <Typography.Text>Proyectos</Typography.Text>
        </div>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Cliente</Typography.Text>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Encargados</Typography.Text>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Fechas</Typography.Text>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Tiempos</Typography.Text>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Progreso</Typography.Text>
      </TableHeadWrapper>
      <TableHeadWrapper>
        <Typography.Text>Estado</Typography.Text>
      </TableHeadWrapper>
    </TableRowWrapper>
  )
}

export default TableHead
