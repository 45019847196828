import { useEffect, useState, useId } from 'react'
import EditorJS from '@editorjs/editorjs'

const Header = require('@editorjs/header')
const List = require('@editorjs/list')

const useNotes = () => {
  const [editor, setEditor] = useState<EditorJS>()
  const [isMount, setIsMount] = useState(false)

  const componentId = useId()

  useEffect(() => {
    if (componentId && !editor && isMount) {
      setEditor(
        new EditorJS({
          holder: componentId,
          autofocus: true,
          minHeight: 200,
          tools: {
            header: {
              class: Header,
              config: {
                placeholder: 'Enter a title',
              },
            },
            list: List,
          },
        }),
      )
    }

    return () => {
      if (editor) {
        editor.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, isMount])

  useEffect(() => {
    setIsMount(true)

    return () => {
      setIsMount(false)
    }
  }, [])

  return {
    editor,
    componentId,
  }
}

export default useNotes
