import type { FC } from 'react'

import AppButton from 'shared/components/common/AppButton'
import { EllipsisOutlined } from '@ant-design/icons'

import { HeadResume, TemplateInfo, TemplateHeaderWrapper } from './styles/TemplateHeader.styled'

export const TemplateHeader: FC = () => {
  return (
    <HeadResume className="sticky top-[62px] z-50 bg-white">
      <TemplateInfo>
        <h2 className="mb-0 text-xl font-bold">Plantilla Redes Sociales</h2>
      </TemplateInfo>
      <TemplateHeaderWrapper>
        <div className="flex justify-end items-center">
          <AppButton className="font-semibold" type="text">
            <EllipsisOutlined />
          </AppButton>
        </div>
      </TemplateHeaderWrapper>
    </HeadResume>
  )
}
