import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const WorkloadIcon: FC<IBaseIconProps> = ({ size, color }) => {
  return (
    <svg
      fill={color}
      height={size || 30}
      viewBox="0 0 26.357 26.348"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.669,27.563a1.159,1.159,0,0,1,.468.1l2.046-2.046a.422.422,0,0,1,.6.6l-2.046,2.046a1.164,1.164,0,1,1-1.065-.7Zm0,8.529a7.366,7.366,0,0,1-7.354-6.943H16v1.292l2.1.808a10.813,10.813,0,0,0,1.313,3.167L18.5,36.473,20.923,38.9l2.057-.914A10.812,10.812,0,0,0,26.146,39.3l.808,2.1h3.429l.808-2.1a10.812,10.812,0,0,0,3.167-1.313l2.057.913,2.425-2.425-.914-2.057a10.812,10.812,0,0,0,1.313-3.166l2.1-.808V29.149H36.023a7.366,7.366,0,0,1-7.354,6.943Zm6.58-10.678a7.323,7.323,0,0,1,.773,2.89h5.308a12.749,12.749,0,0,0-.115-1.339,12.583,12.583,0,0,0-1.369-4.206ZM21.315,28.3a7.324,7.324,0,0,1,.773-2.89l-4.6-2.655a12.582,12.582,0,0,0-1.369,4.206,12.749,12.749,0,0,0-.115,1.339Zm1.2-3.62-4.6-2.655q.3-.479.639-.929a12.744,12.744,0,0,1,2.491-2.491q.45-.339.929-.639l2.655,4.6A7.406,7.406,0,0,0,22.51,24.685Zm2.846-2.538a7.323,7.323,0,0,1,2.89-.773V16.065a12.746,12.746,0,0,0-1.339.115A12.582,12.582,0,0,0,22.7,17.549Zm3.735-6.082v5.308a7.324,7.324,0,0,1,2.89.773l2.655-4.6A12.582,12.582,0,0,0,30.43,16.18a12.746,12.746,0,0,0-1.339-.115Zm3.62,6.5,2.655-4.6q.479.3.929.639A12.744,12.744,0,0,1,38.786,21.1q.339.45.639.929l-4.6,2.655A7.407,7.407,0,0,0,32.711,22.569Z"
        data-name="optimization (1)"
        fillRule="evenodd"
        strokeWidth="1"
        transform="translate(-15.49 -15.548)"
      />
    </svg>
  )
}

export default WorkloadIcon
