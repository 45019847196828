import type { FC } from 'react'

import clsx from 'clsx'

import useOtpBox from '../lib/useOtpBox'
import { IOtpBoxProps } from '../@types/OtpBox'

export const OtpBox: FC<IOtpBoxProps> = ({ onChange }) => {
  const { value, boxRef, handleChange } = useOtpBox({
    onChange,
  })

  return (
    <input
      ref={boxRef}
      className={clsx(
        'border rounded-md w-16 h-16 p-2 outline-none focus:border-primary focus:border-2 hover:border-primary hover:border-2 transition-all duration-200 text-3xl text-center text-primary font-semibold',
        {
          'border-primary border-2': Boolean(value),
        },
      )}
      value={value}
      onChange={handleChange}
    />
  )
}
