import type { IBarItem } from './types'

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  height: 22px;
`

export const BarItem = styled.div<IBarItem>`
  padding: 1px 2px;
  color: white;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  background: ${({ bg }) => bg};
  flex: ${({ flex }) => flex};
  text-shadow: 0px 3px 6px #00000040;
`
