import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const CustomerIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      data-name="Group 198"
      fill={color}
      height={size || 30}
      viewBox="0 0 18 20"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.333,18V0h-14V18h-2v2h18V18Zm-8-16h2V4h-2Zm0,4h2V8h-2Zm0,4h2v2h-2Zm-2,2h-2V10h2Zm0-4h-2V6h2Zm0-4h-2V2h2Zm5,14h-4V16a2,2,0,1,1,4,0Zm3-6h-2V10h2Zm0-4h-2V6h2Zm0-4h-2V2h2Z"
        data-name="Path 10"
        transform="translate(-21.333)"
      />
    </svg>
  )
}

export default CustomerIcon
