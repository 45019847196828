import type {FieldValues} from "react-hook-form";
import type {ITextAreaProps} from "../@types";

import {Input} from "antd";
import {useController} from "react-hook-form";
import clsx from "clsx";

export function AppTextArea<TFieldValues extends FieldValues = FieldValues>({
  label,
  id,
  labelClassName,
  control,
  name,
  error,
  ...props
}: ITextAreaProps<TFieldValues>) {
  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
  });

  return (
    <div className="w-full">
      {label && (
        <label className={clsx(labelClassName, "font-bold", "text-gray-400")} htmlFor={id}>
          {label}
        </label>
      )}
      <Input.TextArea
        id={id}
        status={error ? "error" : undefined}
        value={value}
        {...props}
        onChange={onChange}
      />
      {Boolean(error) && (
        <span className="block text-red-500 text-sm animate-fade-in">{error}</span>
      )}
    </div>
  );
}
