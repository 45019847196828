import type { UniqueIdentifier } from '@dnd-kit/core'
import type { IFlattenedItem, ITreeItem } from '../@types/TreeFiles'

import { arrayMove } from '@dnd-kit/sortable'

export function flatten(
  items: ITreeItem[],
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): IFlattenedItem[] {
  return items.reduce<IFlattenedItem[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.children, item.id, depth + 1),
    ]
  }, [])
}

export function flattenTree(items: ITreeItem[]): IFlattenedItem[] {
  return flatten(items)
}

export function removeChildrenOf(items: IFlattenedItem[], ids: UniqueIdentifier[]) {
  const excludeParentIds = [...ids]

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id)
      }

      return false
    }

    return true
  })
}

export function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth)
}

export function getMaxDepth({ previousItem }: { previousItem: IFlattenedItem }) {
  if (previousItem) {
    return previousItem.depth + 1
  }

  return 0
}

export function getMinDepth({ nextItem }: { nextItem: IFlattenedItem }) {
  if (nextItem) {
    return nextItem.depth
  }

  return 0
}

export function getProjection(
  items: IFlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId)
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = arrayMove(items, activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex - 1]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  const maxDepth = getMaxDepth({
    previousItem,
  })
  const minDepth = getMinDepth({
    nextItem,
  })
  let depth = projectedDepth

  if (projectedDepth >= maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  function getParentId() {
    if (depth === 0 || !previousItem) return null

    if (depth === previousItem.depth) return previousItem.parentId
    if (depth > previousItem.depth) return previousItem.id

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId

    return newParent ?? null
  }

  return {
    depth,
    maxDepth,
    minDepth,
    parentId: getParentId(),
  }
}

export function setProperty<T extends keyof ITreeItem>(
  items: ITreeItem[],
  id: UniqueIdentifier,
  property: T,
  setter: (value: ITreeItem[T]) => ITreeItem[T],
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property])
      continue
    }

    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter)
    }
  }

  return [...items]
}

export function findItemDeep(items: ITreeItem[], itemId: UniqueIdentifier): ITreeItem | undefined {
  for (const item of items) {
    const { id, children } = item

    if (id === itemId) return item

    if (children.length) {
      const child = findItemDeep(children, itemId)

      if (child) return child
    }
  }

  return undefined
}

export function countChildren(items: ITreeItem[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1)
    }

    return acc + 1
  }, count)
}

export function getChildCount(items: ITreeItem[], id: UniqueIdentifier) {
  const item = findItemDeep(items, id)

  return item ? countChildren(item.children) : 0
}

export function findItem(items: ITreeItem[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId)
}

export function buildTree(flattenedItems: IFlattenedItem[]): ITreeItem[] {
  const root: ITreeItem = { id: 0, type: 'folder', name: 'root', children: [] }
  const nodes: Record<string, ITreeItem> = { [root.id]: root }
  const items = flattenedItems.map((item) => ({ ...item, children: [] }))

  for (const item of items) {
    const { id, children, ...rest } = item
    const parentId = item.parentId ?? root.id
    const parent = nodes[parentId] ?? findItem(items, parentId)

    nodes[id] = { id, children, ...rest }
    parent.children.push(item)
  }

  return root.children
}
