import type { ICreatePerson } from 'modules/Contacts/@types/ModalPerson'

export function createPersonSerialize(formData: ICreatePerson): ICreatePerson {
  return {
    ...formData,
    primary_phone_country_code: formData.primary_phone.substring(0, 2),
    primary_phone: formData.primary_phone.substring(2),
    person_extra_phones: (formData?.person_extra_phones ?? []).map((phone) => ({
      ...phone,
      country_phone: phone.phone.substring(0, 2),
      phone: phone.phone.substring(2),
    })),
  }
}
