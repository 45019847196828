import type {UniqueIdentifier} from "@dnd-kit/core";
import type {IFlattenedItem, TreeData, TreeTasksListColumn} from "../@types";
import type {ReactNode} from "react";

import {arrayMove} from "@dnd-kit/sortable";

export const KeyParent = Symbol("parentId");

export function flatten<TTreeData extends TreeData = TreeData>(
  items: TTreeData[],
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): IFlattenedItem[] {
  if (!items) return [];

  return items?.reduce<IFlattenedItem[]>((acc, item, index) => {
    return [
      ...acc,
      {...item, [KeyParent]: parentId, depth, index},
      ...flatten(item?.children || [], item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree<TTreeData extends TreeData = TreeData>(items: TTreeData[]) {
  return flatten<TTreeData>(items);
}

export function removeChildrenOf<TTreeData extends TreeData = TreeData>(
  items: TTreeData[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((treeItem) => {
    if (
      treeItem[KeyParent as unknown as string] &&
      excludeParentIds.includes(treeItem[KeyParent as unknown as string])
    ) {
      if (treeItem?.children?.length) {
        excludeParentIds.push(treeItem.id);
      }

      return false;
    }

    return true;
  });
}

export function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getMaxDepth({previousItem}: {previousItem: IFlattenedItem}) {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
}

export function getMinDepth({nextItem}: {nextItem: IFlattenedItem}) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

export function getProjection(
  items: Record<UniqueIdentifier, TreeData[]>,
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
  idField: string,
  overContainer: string,
  activeContainer: string,
) {
  if (!overContainer || !activeContainer) return null;

  let overItems = flattenTree(items[overContainer]);
  let activeItems = flattenTree(items[activeContainer]);

  const overItemIndex = overItems?.findIndex((item) => item[idField] === overId);
  const activeItemIndex = activeItems?.findIndex((item) => item[idField] === activeId);
  const activeItem = activeItems[activeItemIndex];

  // let newItems = {} as Record<UniqueIdentifier, TreeData[]>;

  // if (overContainer !== activeContainer) {
  //   let newIndex: number;

  //   if ((overId + "").startsWith("section-") && (overId + "").replaceAll("section-", "") in items) {
  //     newIndex = items[overContainer].length + 1;
  //   } else {
  //     newIndex = overItemIndex >= 0 ? overItemIndex + 1 : items[overContainer].length + 1;
  //   }

  //   newItems = {
  //     ...items,
  //     [activeContainer]: items[activeContainer].filter((item) => item[idField] !== activeId),
  //     [overContainer]: [
  //       ...items[overContainer].slice(0, newIndex),
  //       activeItem,
  //       ...items[overContainer].slice(newIndex, items[overContainer].length),
  //     ],
  //   };
  // } else {
  //   newItems = {
  //     ...items,
  //     [overContainer]: arrayMove(overItems, activeItemIndex, overItemIndex),
  //   };
  // }

  // overItems = flatten(newItems[overContainer]);
  // activeItems = flatten(newItems[activeContainer]);

  const previousItem = overItems[overItemIndex - 1] as IFlattenedItem;
  const nextItem = overItems[overItemIndex + 1] as IFlattenedItem;

  const dragDepth = getDragDepth(dragOffset, indentationWidth);

  const projectedDepth = (activeItem?.depth || 0) + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({
    nextItem,
  });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  function getParentId() {
    if (depth === 0 || !previousItem) return null;

    if (depth === previousItem.depth) return previousItem[KeyParent as unknown as string];
    if (depth > previousItem.depth) return previousItem[idField];

    const newParent = overItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.[KeyParent as unknown as string];

    return newParent ?? null;
  }

  return {
    depth,
    maxDepth,
    minDepth,
    [KeyParent]: getParentId(),
  };
}

export function setProperty<
  TTreeData extends TreeData = TreeData,
  T extends keyof TTreeData = keyof TTreeData,
>(
  items: TTreeData[],
  id: UniqueIdentifier,
  property: T,
  setter: (value: TTreeData[T]) => TTreeData[T],
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item?.children?.length) {
      (item as any).children = setProperty<TTreeData, T>(
        item?.children as TTreeData[],
        id,
        property,
        setter,
      );
    }
  }

  return [...items];
}

export function findItemDeep<TTreeData extends TreeData = TreeData>(
  items: TTreeData[],
  itemId: UniqueIdentifier,
): TTreeData | undefined {
  for (const item of items) {
    const {id, children} = item;

    if (id === itemId) return item;

    if (children?.length) {
      const child = findItemDeep<TTreeData>(children as TTreeData[], itemId);

      if (child) return child;
    }
  }

  return undefined;
}

export function countChildren<TTreeData extends TreeData = TreeData>(
  items: TTreeData[],
  count = 0,
): number {
  return items.reduce((acc, {children}) => {
    if (children?.length) {
      return countChildren<TTreeData>(children as TTreeData[], acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount<TTreeData extends TreeData = TreeData>(
  items: TTreeData[],
  id: UniqueIdentifier,
) {
  const item = findItemDeep<TTreeData>(items, id);

  return item ? countChildren<TTreeData>(item?.children as TTreeData[]) : 0;
}

export function findItem(items: TreeData[], itemId: UniqueIdentifier, idField: string) {
  return items.find((item) => item[idField] === itemId);
}

export function buildTree<TTreeData extends TreeData = TreeData>(
  flattenedItems: IFlattenedItem[],
  idField: string,
): TTreeData[] {
  const root = {[idField]: 0, children: []} as unknown as TTreeData;
  const nodes: Record<string, TTreeData> = {[root[idField]]: root};
  const items = flattenedItems.map((item) => ({...item, children: []})) as IFlattenedItem[];

  for (const item of items) {
    const {children, ...rest} = item;
    const id = item[idField as keyof typeof item];
    const parentId = item[KeyParent as unknown as keyof typeof item] ?? root[idField];
    const parent = nodes[parentId] ?? findItem(items, parentId, idField);

    nodes[id as string] = {...rest, [idField]: id, children} as unknown as TTreeData;
    parent?.children?.push(item);
  }

  return (root?.children || []) as TTreeData[];
}

export function findContainer(
  itemId: UniqueIdentifier,
  idField: string,
  data: Record<UniqueIdentifier, TreeData[]>,
  sectionsKeys: string[],
) {
  if (
    (itemId + "").startsWith("section-") &&
    sectionsKeys.includes((itemId + "").replaceAll("section-", ""))
  ) {
    return (itemId + "").replaceAll("section-", "");
  }

  const flattenedData = Object.fromEntries(
    Object.entries(data).map(([key, data]) => [key, flatten(data)]),
  );

  return Object.keys(flattenedData).find(
    (key) => !!flattenedData[key].find((item) => item[idField] === itemId),
  );
}

export function renderColValue<TTreeData extends TreeData = TreeData>(
  column: TreeTasksListColumn<TTreeData>,
  data: TreeData,
) {
  if (column?.dataIndex && !column.render) {
    return data[column.dataIndex];
  } else if (column?.dataIndex && column?.render) {
    return (column.render as (value: any, record: TreeData) => ReactNode)(
      data[column.dataIndex],
      data,
    );
  } else if (!column?.dataIndex && column?.render) {
    return (column.render as (record: TreeData) => ReactNode)(data);
  } else {
    return null;
  }
}

export const getDataItem = (
  itemId: UniqueIdentifier,
  idField: string,
  data: Record<UniqueIdentifier, TreeData[]>,
) => {
  return flatten(Object.values(data).flat()).find((item) => item[idField] === itemId);
};
