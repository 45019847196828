import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TemplateTabsKeys } from './TemplateTabs.keys'

const useTemplateTabs = () => {
  const [currentTab, setCurrentTab] = useState<TemplateTabsKeys>()

  const navigate = useNavigate()
  const location = useLocation()

  const handleChangeTab = (tab: TemplateTabsKeys) => {
    if (tab === currentTab) return
    const search = new URLSearchParams()

    search.set('tab', tab)

    navigate({
      search: search.toString(),
    })
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const tab = <TemplateTabsKeys | undefined>search.get('tab')

    if (!tab) {
      search.set('tab', TemplateTabsKeys.PLAN)
      navigate({
        search: search.toString(),
      })
    } else {
      setCurrentTab(tab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return {
    currentTab,
    handleChangeTab,
  }
}

export default useTemplateTabs
