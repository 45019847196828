import type { FC } from 'react'

import { Button } from 'antd'
import AddTemplateIcon from 'shared/assets/icons/AddTemplate'

import { ITemplatesEmptyProps } from '../@types/TemplatesEmpty'

export const TemplatesEmpty: FC<ITemplatesEmptyProps> = ({ handleAddTemplate }) => {
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="text-center w-2/5">
        <div className="flex w-full justify-center">
          <AddTemplateIcon className="w-28 h-28" color="#367ddf" />
        </div>
        <h3 className="font-bold text-xl">Aún no se han agregado plantillas de proyectos</h3>
        <p className="mt-2">
          Agregue plantillas para proyectos similares para delinear el trabajo relacionado con el
          proyecto y ahorre tiempo en las tareas repetitivas
        </p>
        <div className="mt-4">
          <Button type="text" onClick={handleAddTemplate}>
            + Agregar Plantilla
          </Button>
        </div>
      </div>
    </div>
  )
}
