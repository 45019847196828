import type { FC } from 'react'

import { Typography } from 'antd'
import { PersonNameBadgeGrouped } from 'shared/components/common/PersonNameBadge'
import TaskDates from 'shared/components/common/TaskDates'
import { TaskTime } from 'shared/components/common/TaskTime'
import ProjectStatus from 'shared/components/common/ProjectStatus'
import { Link } from 'react-router-dom'

import { TableRowWrapper, TableCol } from './styled'

const TableRow: FC = () => {
  return (
    <TableRowWrapper>
      <TableCol>
        <Link to="/projects/nueva-scp">
          <Typography.Text>Realizar nuevo Diseño</Typography.Text>
        </Link>
      </TableCol>

      <TableCol>
        <PersonNameBadgeGrouped
          align="center"
          persons={[
            { name: 'Kin Sandoval', color: '#B142EB' },
            { name: 'Hugo Gutierrez', color: '#07C300' },
            { name: 'John Doe' },
            { name: 'Albert Einstein' },
          ]}
        />
      </TableCol>
      <TableCol>
        <TaskDates />
      </TableCol>
      <TableCol>
        <TaskTime type="estimated" />
      </TableCol>
      <TableCol>
        <TaskTime type="tracked" />
      </TableCol>
      <TableCol>
        <ProjectStatus />
      </TableCol>
    </TableRowWrapper>
  )
}

export default TableRow
