import type {FC} from "react";
import type {IProgressBar} from "../@types/ProgressBar";

import {useTheme} from "styled-components";
import {Typography} from "antd";

import {CustomProgress} from "./styles/progressBar.styled";

export const ProgressBar: FC<IProgressBar> = ({max, progress}) => {
  const theme = useTheme();

  return (
    <CustomProgress
      format={(percent) => {
        return (
          <Typography.Text className="text-base">
            ({`${Math.round(((percent || 0) * max) / 100)}/${max}`})
          </Typography.Text>
        );
      }}
      percent={(progress / max) * 100}
      strokeColor={{from: theme.colors["green-light"], to: "white"}}
      strokeWidth={14}
    />
  );
};
