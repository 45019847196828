import type {FC} from "react";
import type {IModalProjectProps} from "../@types/ModalProject";

import {Alert} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

import {CustomModal} from "./styles/ModalProject.styled";
import {ProjectForm} from "./ProjectForm";

export const ModalProject: FC<IModalProjectProps> = ({open, onClose}) => {
  return (
    <CustomModal
      destroyOnClose
      bodyStyle={{padding: 0}}
      footer={false}
      open={open}
      title={<h3 className="font-bold text-lg">Nuevo Proyecto</h3>}
      width={450}
      wrapClassName="p-0"
      onCancel={() => onClose && onClose()}
    >
      <Alert
        banner
        icon={<InfoCircleOutlined />}
        message={
          <p className="text-xs">
            Puede personalizar los campos visibles en esta vista en{" "}
            <a className="text-blue-400 underline font-bold" href="#">
              Configuración
            </a>
          </p>
        }
        type="info"
      />
      <ProjectForm onCancel={onClose} />
    </CustomModal>
  );
};
