import type {FC} from "react";

import {Avatar, Button, Popover} from "antd";
import BellIcon from "shared/assets/icons/bell";
import {LogoutOutlined} from "@ant-design/icons";
import {useAuth} from "shared/contexts/Auth";

import {ActionWrapper} from "./styled";

const UserActions: FC = () => {
  const {user, logout} = useAuth();

  return (
    <ActionWrapper>
      <Button icon={<BellIcon color="inherit" size={28} />} size="large" type="text" />
      <Popover
        content={
          <div>
            <Button
              className="flex items-center"
              icon={<LogoutOutlined />}
              type="text"
              onClick={logout}
            >
              Cerrar sesión
            </Button>
          </div>
        }
        trigger="click"
      >
        {/* {user?.avatar_path ? (
          <Avatar shape="circle" size="large" src={user?.avatar_path} />
        ) : ( */}
        <Avatar shape="circle" size="large">
          {(user?.first_name?.charAt(0)?.toUpperCase() || "") +
            (user?.last_name?.charAt(0)?.toUpperCase() || "")}
        </Avatar>
        {/* )} */}
      </Popover>
    </ActionWrapper>
  );
};

export default UserActions;
