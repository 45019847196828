import type { FC } from 'react'
import type { IColumnItem } from './types'

import { Popover, Typography } from 'antd'
import AppButton from 'shared/components/common/AppButton'
import { EllipsisOutlined } from '@ant-design/icons'

import { HeadColumn, HeaderColumns } from './styled'

const ColumnItem: FC<IColumnItem> = ({ text, showActions, actions, flex = 1 }) => {
  return (
    <HeadColumn style={{ flex }}>
      <Typography.Text strong>{text}</Typography.Text>
      {showActions && (
        <Popover
          destroyTooltipOnHide
          content={<div>{actions?.map((action) => action)}</div>}
          placement="bottom"
          showArrow={false}
          style={{ padding: 0 }}
          trigger="click"
        >
          <AppButton icon={<EllipsisOutlined />} type="text" />
        </Popover>
      )}
    </HeadColumn>
  )
}

const ColumnHeader: FC = () => {
  return (
    <HeaderColumns>
      <ColumnItem text="Proyectos" />
      <ColumnItem flex={2} text="Progreso" />
      <ColumnItem
        showActions
        actions={[
          <AppButton key={`key-phases-columns-1`} size="small" type="ghost">
            + Nuevo Proyecto
          </AppButton>,
        ]}
        text="Kick Off"
      />
      <ColumnItem
        showActions
        actions={[
          <AppButton key={`key-phases-columns-1`} size="small" type="ghost">
            + Nuevo Proyecto
          </AppButton>,
        ]}
        text="UX / UI"
      />
      <ColumnItem
        showActions
        actions={[
          <AppButton key={`key-phases-columns-1`} size="small" type="ghost">
            + Nuevo Proyecto
          </AppButton>,
        ]}
        text="Montaje"
      />
      <ColumnItem
        showActions
        actions={[
          <AppButton key={`key-phases-columns-1`} size="small" type="ghost">
            + Nuevo Proyecto
          </AppButton>,
        ]}
        text="Revisión"
      />
      <ColumnItem
        showActions
        actions={[
          <AppButton key={`key-phases-columns-1`} size="small" type="ghost">
            + Nuevo Proyecto
          </AppButton>,
        ]}
        text="Closing"
      />
    </HeaderColumns>
  )
}

export default ColumnHeader
