import type { FC } from 'react'

import { SearchOutlined as SearchIcon } from '@ant-design/icons'

import { useSearchInput } from '../../../lib'

import { Input } from './styled'

const SearchInput: FC<any> = (parentProps) => {
  const props = useSearchInput()

  return (
    <div>
      <Input
        {...props}
        {...parentProps}
        placeholder="Search in Experience System"
        prefix={<SearchIcon />}
        size="large"
      />
    </div>
  )
}

export default SearchInput
