import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const ListIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size || 30}
      viewBox="0 0 21.332 13.15"
      width={size || 30}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        data-name="Rectangle 66"
        fill={color}
        height="21.332"
        rx="1"
        transform="translate(0 6.05) rotate(-90)"
        width="2.5"
      />
      <rect
        data-name="Rectangle 68"
        fill={color}
        height="21.332"
        id="Rectangle_68"
        rx="1"
        transform="translate(0 13.15) rotate(-90)"
        width="2.5"
      />
      <rect
        data-name="Rectangle 63"
        fill={color}
        height="21.332"
        id="Rectangle_63"
        rx="1"
        transform="translate(0 2.5) rotate(-90)"
        width="2.5"
      />
      <rect
        data-name="Rectangle 65"
        fill={color}
        height="21.332"
        id="Rectangle_65"
        rx="1"
        transform="translate(0 9.6) rotate(-90)"
        width="2.5"
      />
    </svg>
  )
}

export default ListIcon
