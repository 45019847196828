import type {IPerson} from "types/dto/IPerson";

import {debounce} from "lodash";
import {useMemo, useRef, useState} from "react";
import {useAuth} from "shared/contexts/Auth";

import api from "../api";

const DEBOUNCE_TIMEOUT = 800;

const usePersonsSearch = (): {
  persons: IPerson[];
  isFetching: boolean;
  handleSearch: (searchValue: string) => void;
} => {
  const [persons, setPersons] = useState<IPerson[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchRef = useRef(0);

  const {selectedCompany} = useAuth();

  const debounceSearch = useMemo(() => {
    return debounce((searchValue: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) return;

      setPersons([]);
      setIsFetching(true);
      api
        .searchPersons(selectedCompany!.id)(searchValue)
        .then((res) => {
          setPersons(res);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }, DEBOUNCE_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    persons,
    isFetching,
    handleSearch: debounceSearch,
  };
};

export default usePersonsSearch;
