import type {FC} from "react";

import {TableGroupType} from "../lib/TableGroup.keys";

import TableGroup from "./TableGroup";

const Date: FC = () => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <TableGroup type={TableGroupType.OVERDUE} />
        <TableGroup type={TableGroupType.TODAY} />
        <TableGroup type={TableGroupType.TOMORROW} />
        <TableGroup type={TableGroupType.FUTURE} />
      </table>
    </div>
  );
};

export default Date;
