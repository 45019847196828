import styled from 'styled-components'

export const HeadResume = styled.div`
  padding: 8px 12px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.light};
`

export const TemplateInfo = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
`

export const TemplateHeaderWrapper = styled.div`
  flex: 1;
  padding: 10px 12px;
`
