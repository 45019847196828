import { FC, useState } from 'react'
import { useController } from 'react-hook-form'

import { IOtpInputProps } from '../@types/OtpInput'

import { OtpBox } from './OtpBox'

export const OtpInput: FC<IOtpInputProps> = ({ length = 4, label, control, name, error }) => {
  const [formatValue, setFormatValue] = useState(() => new Array(length).fill('-').join(''))
  const {
    field: { onChange },
  } = useController({
    control,
    name,
  })

  const handleChange = (v: string, index: number) => {
    const digits: string[] = formatValue.split('')

    digits[index] = !!v ? v : '-'
    onChange(digits.join('').replaceAll('-', ''))
    setFormatValue(digits.join(''))
  }

  return (
    <div className="w-full">
      {label && <label className="font-bold text-gray-400 mb-3 text-center block">{label}</label>}
      <div className="w-full flex gap-3 items-center justify-center">
        {new Array(length).fill(1).map((_, i) => (
          <OtpBox key={`otp-box-key-${i}`} onChange={(v) => handleChange(v, i)} />
        ))}
      </div>
      {Boolean(error) && (
        <div className="mt-3 animate-fade-in">
          <span className="block text-center text-red-500">{error}</span>
        </div>
      )}
    </div>
  )
}
