import type {SelectProps} from "antd";
import type {FieldValues} from "react-hook-form";
import type {IUsePersonsSelectProps} from "../@types";

import {useController} from "react-hook-form";
import {useEffect, useState} from "react";
import {useAuth} from "shared/contexts/Auth";

import api from "../api";

import {serializeOptions} from "./utils";
import usePersonsSearch from "./usePersonsSearch";

function usePersonsSelect<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
}: IUsePersonsSelectProps<TFieldValues>) {
  const [options, setOptions] = useState<SelectProps["options"]>([]);

  const {selectedCompany} = useAuth();

  const {
    field: {value, onChange},
  } = useController<TFieldValues>({
    control,
    name,
    shouldUnregister: true,
  });

  const {persons, isFetching, handleSearch} = usePersonsSearch();

  const handleChange = (value: number) => {
    onChange(value);
  };

  useEffect(() => {
    api.getFirstPersons(selectedCompany!.id).then((res) => {
      setOptions(serializeOptions(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOptions(serializeOptions(persons));
  }, [persons]);

  return {
    value,
    options,
    isFetching,
    handleChange,
    handleSearch,
  };
}

export default usePersonsSelect;
