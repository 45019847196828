import type { FC } from 'react'

import { Button, Popover } from 'antd'
import { LabelEntities, LabelEntityTypes } from 'types/dto/ILabel'

import useLabelForm from '../lib/useLabelForm'
import { AppInput } from '../../Input'
import { AppSelectC } from '../../AppSelect'
import { IAddNewLabelProps, ILabelFormProps } from '../@types/AddNewLabel'
import AppButton from '../../AppButton'
import { ColorPicker } from '../../ColorPicker'
import useAddNewLabel from '../lib/useAddNewLabel'

const LabelForm: FC<ILabelFormProps> = ({ entities, onCreate }) => {
  const { errors, control, isSubmitting, handleSubmit } = useLabelForm({ onCreate })

  return (
    <div className="px-4 py-2">
      <form
        className="gap-3 flex flex-col"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleSubmit(e)
        }}
      >
        <AppInput control={control} error={errors?.name?.message} label="Nombre" name="name" />
        <AppSelectC
          control={control}
          label="Entidad"
          name="entity"
          options={Object.entries(LabelEntities)
            .filter(([key]) =>
              entities.length ? entities.includes(key as LabelEntityTypes) : true,
            )
            .map(([value, label]) => ({ label, value }))}
          separator={false}
        />

        <ColorPicker
          control={control}
          error={errors?.color_background?.message}
          label="Color de fondo"
          name="color_background"
          presetColors={['#c72929', '#008000', '#ffff00', '#0000ff', '#800080']}
        />
        <ColorPicker
          control={control}
          error={errors?.label_color_text?.message}
          label="Color de texto"
          name="label_color_text"
          presetColors={['#c72929', '#008000', '#ffff00', '#0000ff', '#800080']}
        />

        <AppButton className="block" htmlType="submit" loading={isSubmitting} type="primary">
          Agregar
        </AppButton>
      </form>
    </div>
  )
}

export const AddNewLabel: FC<IAddNewLabelProps> = ({ entities, onCreateLabel }) => {
  const { open, handleOpen } = useAddNewLabel()

  return (
    <div className="mt-1">
      <Popover
        content={
          <>
            <LabelForm
              entities={entities}
              onCreate={(label) => {
                onCreateLabel(label)
                handleOpen(false)
              }}
            />
            <Button className="w-full" type="text" onClick={() => handleOpen(false)}>
              Cerrar
            </Button>
          </>
        }
        open={open}
        placement="left"
        trigger={['click']}
      >
        <Button className="text-blue-500" size="small" type="text" onClick={() => handleOpen(true)}>
          + Etiqueta
        </Button>
      </Popover>
    </div>
  )
}
