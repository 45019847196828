import type {FC, ReactNode} from "react";

import {memo} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

const ColumnWrapperComponent: FC<{children: ReactNode; record: any; idField: string}> = ({
  children,
  record,
  idField,
}) => {
  const {attributes, listeners, transform, transition, isDragging, setNodeRef} = useSortable({
    id: record[idField],
    data: record,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

export const ColumnWrapper = memo(ColumnWrapperComponent);
