import type { FC } from 'react'
import type { IBaseIconProps } from './types'

const DragHandlerIcon: FC<IBaseIconProps> = ({ color, size, className }) => {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 24 14"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        color={color}
        fill={color}
        fontFamily="Montserrat-Regular, Montserrat"
        fontSize="16"
        transform="translate(10 14) rotate(-90)"
      >
        <tspan x="0" y="0">
          ….
        </tspan>
        <tspan x="0" y="5">
          ….
        </tspan>
      </text>
    </svg>
  )
}

export default DragHandlerIcon
