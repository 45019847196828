import { useState } from 'react'

import { IActivityContextData } from '../@types/IActivityContext'

const useActivityForm = () => {
  const [state, setState] = useState<IActivityContextData>({
    start_at: null,
    end_at: null,
    title: null,
  })

  return {
    ...state,
    setState,
  }
}

export default useActivityForm
