import { DragEvent, useId, useState } from 'react'

const useDropzone = () => {
  const [dragging, setDragging] = useState(false)

  const componentId = useId()

  const onEvent =
    (eventHandler?: (e: DragEvent<HTMLDivElement>) => void, draggingStatus?: boolean) =>
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault()

      if (typeof draggingStatus !== 'undefined') setDragging(draggingStatus)
      eventHandler && eventHandler(e)
    }

  const onDrop = onEvent((e) => {
    const files = e.dataTransfer.files

    // eslint-disable-next-line no-console
    console.log('Files to load: ', files)
    // TODO: upload files to service
  }, false)

  const handleClickUpload = () => {
    document.getElementById(`input-file-${componentId}`)?.click()
  }

  return {
    dragging,
    componentId,
    handleClickUpload,
    onDragEnter: onEvent(() => undefined, true),
    onDragOver: onEvent(() => undefined, true),
    onDragLeave: onEvent(() => undefined, false),
    onDrop,
  }
}

export default useDropzone
