import type {FC} from "react";

// eslint-disable-next-line import/order
import {useId} from "react";

import "react-phone-input-2/lib/style.css";
import {useController} from "react-hook-form";

import {IInputPhoneProps} from "../@types/InputPhone";

import {PhoneInput} from "./styled/InputPhone.styled";

export const InputPhone: FC<IInputPhoneProps> = ({control, name, label, error}) => {
  const componentId = useId();

  const {
    field: {value, onChange},
  } = useController({
    name,
    control,
    defaultValue: "57",
  });

  return (
    <div className="w-ful">
      {label && (
        <label className="text-gray-400 font-bold" htmlFor={`${componentId}-${name}`}>
          {label}
        </label>
      )}
      <PhoneInput
        error={Boolean(error)}
        onlyCountries={["co"]}
        placeholder="57 301 358 7711"
        value={value}
        onChange={(phone) => {
          onChange(phone);
        }}
      />
      {error && <span className="block text-red-500 animate-fade-in text-sm">{error}</span>}
    </div>
  );
};
