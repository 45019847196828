import type {FieldValues} from "react-hook-form";
import type {TaskStatusPickerProps} from "../@types";

import clsx from "clsx";

import useTaskStatuses from "../lib/useTaskStatuses";

import Picker from "./Picker";
import ControllerPicker from "./ControllerPicker";

export default function TaskStatusPicker<TFieldValues extends FieldValues = FieldValues>({
  type = "full",
  bordered = true,
  value,
  ...props
}: TaskStatusPickerProps<TFieldValues>) {
  const {statuses} = useTaskStatuses();

  return (
    <div
      className={clsx(
        "group",
        "rounded-lg",
        "transition-all",
        "duration-200",
        "flex",
        "items-center",
        "p-1",
        {
          border: bordered,
          "justify-between": type === "full",
          "justify-center hover:border": type === "compact",
        },
      )}
    >
      {!props.control ? (
        <Picker statuses={statuses} type={type} value={value} onChange={props.onChange} />
      ) : (
        <ControllerPicker {...props} statuses={statuses} type={type} />
      )}
    </div>
  );
}
