import type { FC } from 'react'
import type { IModalNewGroupProps } from '../@types/ModalNewGroup'

import AppButton from 'shared/components/common/AppButton'
import { AppInput } from 'shared/components/common/Input/ui/Input'
import { useForm } from 'react-hook-form'

import { CustomModal } from './styles/styled'

export const ModalNewGroup: FC<IModalNewGroupProps> = ({ open, onClose, onCreate }) => {
  const { control } = useForm()

  return (
    <CustomModal
      centered
      destroyOnClose
      bodyStyle={{
        padding: 0,
      }}
      footer={
        <div className="flex w-full justify-end items-center gap-4 px-8 py-4 border-t">
          <AppButton $white type="primary" onClick={() => onClose && onClose()}>
            Cancelar
          </AppButton>
          <AppButton
            $success
            type="primary"
            width={130}
            onClick={() =>
              onCreate &&
              onCreate({
                name: 'New Group',
                id: Math.floor(Math.random() * 100),
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toDateString(),
              })
            }
          >
            Crear
          </AppButton>
        </div>
      }
      open={open}
      title={
        <div className="border-b px-8 py-6">
          <h3 className="font-bold">Nuevo Grupo</h3>
        </div>
      }
      width={400}
      onCancel={() => onClose && onClose()}
    >
      <div className="px-10 py-4">
        <AppInput control={control} label="Nombre del grupo" name="name" />
      </div>
    </CustomModal>
  )
}
