import moment from 'moment'
import { useState, useEffect } from 'react'
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop'

import { IEvent } from '../@types/IEvent'

import { useActivityContext } from './context/ActivityForm.context'

const useCalendar = () => {
  const [date, setDate] = useState(moment().toDate())
  const [events, setEvents] = useState<IEvent[]>([
    {
      id: 1,
      end: moment().add(1, 'hours').toDate(),
      start: moment().toDate(),
      title: 'Some Title',
      all_day: false,
    },
  ])

  const { setState, ...contextData } = useActivityContext()

  const handleChange = (data: EventInteractionArgs<object>) => {
    const {
      event: { id },
      start,
      end,
      isAllDay,
    } = data as EventInteractionArgs<IEvent>

    setEvents((prev) => {
      const index = prev.findIndex((it) => it.id === id)
      const state = [...prev]

      const dates = {
        start: !isAllDay ? moment(start).toDate() : moment(start).startOf('day').toDate(),
        end: !isAllDay ? moment(end).toDate() : moment(end).endOf('day').toDate(),
      }

      state.splice(index, 1, {
        ...prev[index],
        ...dates,
        all_day: isAllDay,
      })

      setState((prevState) => ({
        ...prevState,
        ...dates,
      }))

      return state
    })
  }

  const handlePrevDay = () => setDate(moment(date).subtract(1, 'days').toDate())

  const handleNextDay = () => setDate(moment(date).add(1, 'days').toDate())

  useEffect(() => {
    setEvents((prev) => {
      const state = [...prev]

      state.splice(0, 1, {
        ...prev[0],
        title: contextData.title!,
      })

      return state
    })
  }, [contextData.title])

  useEffect(() => {
    const { start_at, end_at } = contextData

    if (start_at) {
      const newDate = moment(start_at)

      setDate(newDate.toDate())

      if (end_at) {
        setEvents((prev) => {
          const state = [...prev]

          state.splice(0, 1, {
            ...prev[0],
            start: newDate.toDate(),
            end: moment(newDate)
              .add(moment(end_at).diff(moment(start_at), 'minutes'), 'minutes')
              .toDate(),
          })

          return state
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextData.end_at, contextData.start_at])

  return {
    date,
    events,
    handleChange,
    handlePrevDay,
    handleNextDay,
  }
}

export default useCalendar
