import type {FC} from "react";
import type {ITaskList} from "types/dto/ITask";

import React from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  Kanban as AppKanban,
} from "shared/components/common/Kanban";
import {TaskCard} from "shared/components/common/TaskCard";

import {KanbanHeader} from "./KanbanHeader";

const KanbanComponent: FC<{tasks: ITaskList[]}> = ({tasks}) => {
  return (
    <AppKanban
      cardSettings={{
        render: (record) => {
          return <TaskCard data={record} />;
        },
      }}
      dataSource={tasks}
      keyField="task_status_id"
    >
      <ColumnsDirective>
        <ColumnDirective keyField="todo" renderHeader={KanbanHeader} title="To Do" />
        <ColumnDirective keyField="in-progress" renderHeader={KanbanHeader} title="In Progress" />
        <ColumnDirective
          keyField="internal-review"
          renderHeader={KanbanHeader}
          title="Internal Review"
        />
        <ColumnDirective
          keyField="client-review"
          renderHeader={KanbanHeader}
          title="Client Review"
        />
        <ColumnDirective keyField="completed" renderHeader={KanbanHeader} title="Completed" />
      </ColumnsDirective>
    </AppKanban>
  );
};

export const Kanban = React.memo(KanbanComponent);
