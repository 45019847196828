import type {FieldValues} from "react-hook-form";
import type {TimelinePickerProps} from "../@types";

import clsx from "clsx";
import FlagIcon from "shared/assets/icons/flag";

import Picker from "./Picker";
import ControllerPicker from "./ControllerPicker";

export default function TimelinePicker<TFieldValues extends FieldValues = FieldValues>(
  props: TimelinePickerProps<TFieldValues>,
) {
  return (
    <div className={clsx("group", "rounded-lg", "border", "px-2", "py-1", "overflow-hidden")}>
      <div className="flex gap-2 items-center w-full">
        <FlagIcon className="w-4 h-4 fill-gray-400" />

        {!props.control ? (
          <Picker />
        ) : (
          <ControllerPicker
            control={props.control}
            endDateFieldName={props.endDateFieldName}
            rules={props.rules}
            shouldUnregister={props.shouldUnregister}
            startDateFieldName={props.startDateFieldName}
            onBlur={props.onBlur}
            onChange={props.onChange}
          />
        )}
      </div>
    </div>
  );
}
