import type {EditorJsProps} from "../@types";

import React from "react";
import EditorJs, {OutputData} from "@editorjs/editorjs";
import _ from "lodash";

const Header = require("@editorjs/header");
const List = require("@editorjs/list");

export default function useEditorJs({value, onChange}: EditorJsProps) {
  const isMount = React.useRef(false);
  const [editorJs, setEditorJs] = React.useState<EditorJs | null>(null);

  const cId = React.useId();

  React.useEffect(() => {
    if (isMount.current && !editorJs) {
      setEditorJs(
        new EditorJs({
          holder: cId,
          minHeight: 0,
          data: (typeof value === "string" ? JSON.parse(value || "{}") : value) as OutputData,

          onChange(api) {
            if (onChange) {
              api.saver.save().then((data) => {
                if (
                  !_.isEqual(typeof value === "object" ? JSON.parse(value || "{}") : value, data)
                ) {
                  onChange(JSON.stringify(data));
                }
              });
            }
          },
          tools: {
            header: Header,
            list: List,
          },
        }),
      );
    }

    // return () => {
    //   if (editorJs) {
    //     editorJs.destroy();
    //     setEditorJs(null);
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMount.current, value]);

  React.useEffect(() => {
    isMount.current = true;

    return () => {
      isMount.current = false;
    };
  }, []);

  return {
    cId,
  };
}
