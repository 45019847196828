import type {FieldValues, FieldPath} from "react-hook-form";
import type {ControllerPickerProps} from "../@types";
import type {Moment} from "moment";

import {useController} from "react-hook-form";
import React from "react";
import moment from "moment";

import Picker from "./Picker";

export default function ControllerPicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  endDateFieldName,
  startDateFieldName,
  shouldUnregister,
  rules,
  ...pickerProps
}: ControllerPickerProps<TFieldValues, TName>) {
  const {field: fieldStartDate} = useController({
    control,
    shouldUnregister,
    name: startDateFieldName,
    rules,
  });
  const {field: fieldEndDate} = useController({
    control,
    shouldUnregister,
    name: endDateFieldName,
    rules,
  });

  const value = React.useMemo(() => {
    return [
      fieldStartDate.value ? moment(fieldStartDate.value) : null,
      fieldEndDate.value ? moment(fieldEndDate.value) : null,
    ] as [Moment | null, Moment | null];
  }, [fieldStartDate.value, fieldEndDate.value]);

  return (
    <>
      <Picker
        {...pickerProps}
        allowClear
        allowEmpty={[true, true]}
        className="flex-1 p-0"
        clearIcon={<button className="text-blue-400">Limpiar</button>}
        value={value}
        onChange={(values, stringDates) => {
          const start = values?.[0];
          const end = values?.[1];

          fieldStartDate.onChange(start?.toISOString());
          fieldEndDate.onChange(end?.toISOString());

          if (pickerProps.onChange) {
            pickerProps.onChange(values, stringDates);
          }
        }}
      />
    </>
  );
}
