import type {FC} from "react";
import type {IProjectFormProps} from "../@types/ProjectForm";

import {AppInput, AppTextArea} from "shared/components/common/Input";
import DatePicker from "shared/components/common/DatePicker";
import AppButton from "shared/components/common/AppButton";
import BoardSelect from "shared/components/common/BoardSelect/ui/BoardSelect";
import ProjectSelectPhase from "shared/components/common/ProjectSelectPhase/ui/ProjectSelectPhase";
import {LabelsInput} from "shared/components/common/LabelsInput";
import {OrganizationSelect} from "shared/components/common/OrganizationSelect";
import PersonsSelect from "shared/components/common/PersonsSelect/ui/PersonsSelect";
import {OwnerSelect} from "shared/components/common/OwnerSelect";
import DealsSelect from "shared/components/common/DealsSelect/ui/DealsSelect";

import useProjectForm from "../lib/useProjectForm";

export const ProjectForm: FC<IProjectFormProps> = ({onCancel}) => {
  const {control, selectedBoardId, errors, isSubmitting, personId, handleSubmit} = useProjectForm({
    onCancel,
  });

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
      <div className="px-[24px] py-5">
        {/* <AppSelectC
          className="w-full"
          control={control}
          label="Seleccione plantilla"
          name="template_id"
          options={[
            {
              label: "Ninguna",
              value: 0,
            },
          ]}
          separator={false}
        /> */}
        <AppInput control={control} error={errors?.name?.message} label="Título" name="name" />
        <DatePicker
          className="w-full"
          control={control}
          error={errors?.start_date?.message}
          label="Fecha inicio"
          name="start_date"
        />
        <DatePicker
          className="w-full"
          control={control}
          error={errors?.end_date?.message}
          label="Fecha final"
          name="end_date"
        />
        <BoardSelect
          control={control}
          error={errors?.project_board_id?.message}
          label="Tablero"
          name="project_board_id"
        />
        <ProjectSelectPhase
          boardId={selectedBoardId}
          control={control}
          name="project_phase_id"
          size="large"
        />
        <OwnerSelect
          control={control}
          error={errors?.owner_user_id?.message}
          label="Responsable"
          name="owner_user_id"
        />
        <DealsSelect
          control={control}
          error={errors?.project_deals?.message}
          label="Trato"
          name="project_deals"
        />
        <PersonsSelect
          control={control}
          error={errors?.person_id?.message}
          label="Contacto responsable"
          name="person_id"
        />
        <OrganizationSelect
          byPersonId={personId}
          control={control}
          error={errors?.organization_id?.message}
          label="Organización"
          name="organization_id"
        />
        <LabelsInput
          control={control}
          error={errors?.project_labels?.message}
          label="Etiquetas"
          name="project_labels"
        />
        <AppTextArea
          control={control}
          error={errors?.description?.message}
          label="Descripción"
          name="description"
        />
      </div>
      <div className="py-4 px-8 flex justify-end bg-[#F4F7F9] items-center gap-3 border-t">
        <AppButton $white type="primary" onClick={() => onCancel && onCancel()}>
          Cancelar
        </AppButton>
        <AppButton $success htmlType="submit" loading={isSubmitting} type="primary">
          Guardar
        </AppButton>
      </div>
    </form>
  );
};
