import type {IBase} from "./IBase";

export enum LabelEntityTypes {
  ALL = "all",
  DEALS = "deals",
  PERSONS = "persons",
  ORGANIZATIONS = "organizations",
  PRODUCTS = "products",
  PROJECTS = "projects",
  PROJECT_BOARD_SPECIFIC = "project_board_specific",
  PROJECT_BOARD_ALL = "project_board_all",
  TASK_IN_SPECIFIC_PROJECT_BOARD = "task_in_specific_project_board",
  TASK_ALL = "task_all",
}

export const LabelEntities = {
  [LabelEntityTypes.ALL]: "Todo",
  [LabelEntityTypes.DEALS]: "Tratos",
  [LabelEntityTypes.PERSONS]: "Personas",
  [LabelEntityTypes.ORGANIZATIONS]: "Organizaciones",
  [LabelEntityTypes.PRODUCTS]: "Productos",
  [LabelEntityTypes.PROJECTS]: "Proyectos",
  [LabelEntityTypes.PROJECT_BOARD_SPECIFIC]: "Proyecto en tablero especifico",
  [LabelEntityTypes.PROJECT_BOARD_ALL]: "Proyecto en todos los tableros",
  [LabelEntityTypes.TASK_IN_SPECIFIC_PROJECT_BOARD]: "Tarea en tablero de proyecto especifico",
  [LabelEntityTypes.TASK_ALL]: "Todas las tareas",
};

export interface ILabel extends IBase {
  company_id: number;
  entity: LabelEntityTypes;
  project_board_id: number;
  position: number;
  name: string;
  color_background: string;
  label_color_text: string;
}

export type ICreateLabel = Omit<ILabel, "id" | "createdAt" | "updatedAt">;
